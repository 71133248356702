import { Content, FlexDiv, Heading, LinkPanel, Panel, VerticalSpace } from '@cegal/ds-components'
import { HomePageLinkPanel } from 'components/styled'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const data = [
  {
    link: '/start-here',
    title: 'Start here',
    description:
      'Begin here with simple instructions on how to quickly bootstrap a project with Cegal design system'
  },
  {
    link: '/changes',
    title: 'Changes',
    description:
      'Keep track of changes on Cegal design system components and resources, as well as ' +
      'other associated projects'
  },
  {
    link: '/tips',
    title: 'Tips',
    description:
      'Our internal "stack overflow", where we list proven solutions we can copy/paste, good coding practices, etc'
  },
  {
    link: '/accessibility',
    title: 'Accessibility',
    description:
      'Tips and recommendations to make our applications accessible to everyone, in every device type'
  },
  {
    link: '/resources',
    title: 'Resources',
    description: 'Figma links, icon search, logos, colours/themes, fonts/typography, grids/spacing rules'
  },
  {
    link: '/components',
    title: 'Components',
    description: 'Design system component library for React'
  },
  {
    link: '/contribute',
    title: 'Contribute',
    description: 'We want your feedback! Links to workplace rooms about Cegal design systems.'
  }
]

const Home = () => {
  const navigate = useNavigate()
  return (
    <Content>
      <Panel>
        <Heading size='large' spacing>
          Home
        </Heading>
      </Panel>
      <FlexDiv style={{ flexWrap: 'wrap' }}>
        {data.map((item, i) => (
          <HomePageLinkPanel
            href='javascript:;' // eslint-disable-line
            key={item.link}
            onClick={() => navigate(item.link)}
            style={{ animationDelay: i * 0.05 + 's' }}
            variant='secondary'
          >
            <div className='content'>
              <LinkPanel.Title>{item.title}</LinkPanel.Title>
              <LinkPanel.Description>{item.description}</LinkPanel.Description>
            </div>
          </HomePageLinkPanel>
        ))}
      </FlexDiv>
      <VerticalSpace size='3' />
    </Content>
  )
}

export default Home
