import {
  BodyShort,
  Detail,
  Table,
  Heading,
  Ingress,
  Label,
  VerticalSpace,
  BodyLong
} from '@cegal/ds-components'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React from 'react'

const ComponentsTypography = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='typography' level='1' spacing>
        Typography
      </Heading>
      <VerticalSpace />
      <Heading className='toc' size='medium' id='typography-heading' level='2' spacing>
        Heading
      </Heading>
      <VerticalSpace />
      <BodyLong>
        Note that the heading levels (<code>h1</code> to <code>h6</code>) are not tied to the heading style /
        size. You have so set the heading size and optionally set the level.
      </BodyLong>
      <BodyLong>
        Use a good structure for your heading levels, as in:
        <ul>
          <li>
            Save <code>h1</code> for the page tile
          </li>
          <li>
            Use <code>h2</code> for the page sections
          </li>
        </ul>
      </BodyLong>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { Heading } from '@cegal/ds-components'

const Component = () => {
  return (
     <Heading>Sales strategy</Heading>
     <Heading size='small' level='2'>Introduction</Heading>
  )
}`}
      >
        <Heading spacing>Sales strategy</Heading>
        <Heading size='small' level='2'>
          Introduction
        </Heading>
      </DemoableDiv>

      <VerticalSpace />
      <Heading className='toc' size='medium' id='typography-heading-props' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>"xlarge" | "large" | "medium" | "small" | "xsmall"</code>
              </Table.DataCell>
              <Table.DataCell>Set heading sizes to 40, 32, 24, 20 and 18px</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>level</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>1-6</code>
              </Table.DataCell>
              <Table.DataCell>Sets heading level</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>1</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>level</code>
              </Table.DataCell>
              <Table.DataCell>Sets a bottom margin</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace />

      {/* <!-- INGRESS --> */}

      <Heading className='toc' size='medium' id='typography-ingress' level='2' spacing>
        Ingress
      </Heading>
      <VerticalSpace />
      <BodyLong>
        This is used as a preamble between the section header and the section content. Good to use for some
        extra description needed for the page.
      </BodyLong>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { Ingress } from '@cegal/ds-components'

const Component = () => {
  return (
     <Ingress>Sales report for the last quarter of the current year</Ingress>
  )
}`}
      >
        <Ingress>Sales report for the last quarter of the current year</Ingress>
      </DemoableDiv>
      <VerticalSpace />
      <Heading className='toc' size='medium' id='typography-ingress-props' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>level</code>
              </Table.DataCell>
              <Table.DataCell>Sets a bottom margin</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace />

      {/* <!-- BODY LONG --> */}

      <Heading className='toc' size='medium' id='typography-bodylong' level='2' spacing>
        BodyLong
      </Heading>
      <VerticalSpace />
      <BodyLong>
        This is for paragraphs with long content that may span multiple lines. It has proper spacing between
        lines so it's easier to read.
      </BodyLong>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { BodyLong } from '@cegal/ds-components'

const Component = () => {
  return (
     <BodyLong>
       The quarter sales for the current branches show a steady 
       growth that keeps the trend seen on the previous quarter.
       We feel confident that this trend will improve for the next 
       quarter, as the next quarter is typically the strongest
       quarter of the year
      </BodyLong>
  )
}`}
      >
        <BodyLong>
          The quarter sales for the current branches show a steady growth that keeps the trend seen on the
          previous quarter. We feel confident that this trend will improve for the next quarter, as the next
          quarter is typically the strongest quarter of the year
        </BodyLong>
      </DemoableDiv>
      <VerticalSpace />
      <Heading className='toc' size='medium' id='typography-bodylong-props' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>"medium" | "small"</code>
              </Table.DataCell>
              <Table.DataCell>Set body long size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>level</code>
              </Table.DataCell>
              <Table.DataCell>Sets a bottom margin</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace />

      {/* <!-- BODY SHORT --> */}

      <Heading className='toc' size='small' id='typography-bodyshort' level='2' spacing>
        BodyShort
      </Heading>
      <VerticalSpace />
      <BodyLong>This is for paragraphs with more condensed text.</BodyLong>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { BodyShort } from '@cegal/ds-components'

const Component = () => {
  return (
     <BodyShort>
       The quarter sales for the current branches show a steady 
       growth that keeps the trend seen on the previous quarter.
       We feel confident that this trend will improve for the next 
       quarter, as the next quarter is typically the strongest
       quarter of the year
      </BodyShort>
  )
}`}
      >
        <BodyShort>
          The quarter sales for the current branches show a steady growth that keeps the trend seen on the
          previous quarter. We feel confident that this trend will improve for the next quarter, as the next
          quarter is typically the strongest quarter of the year
        </BodyShort>
      </DemoableDiv>

      <VerticalSpace />
      <Heading className='toc' size='medium' id='typography-bodyshort-props' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>"medium" | "small"</code>
              </Table.DataCell>
              <Table.DataCell>Set body short size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>level</code>
              </Table.DataCell>
              <Table.DataCell>Sets a bottom margin</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace />

      {/* <!-- LABEL --> */}

      <Heading className='toc' size='medium' id='typography-label' level='2' spacing>
        Label
      </Heading>
      <VerticalSpace />
      <BodyLong>
        Label is similar to BodyShort, but has more emphasis and spacing that is better suited, among others,
        for labelling form components
      </BodyLong>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { Label } from '@cegal/ds-components'

const Component = () => {
  return (
     <Label>Enter your e-mail address</Label>
  )
}`}
      >
        <Label>Enter your e-mail address</Label>
      </DemoableDiv>

      <VerticalSpace />
      <Heading className='toc' size='medium' id='typography-label-props' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>"medium" | "small"</code>
              </Table.DataCell>
              <Table.DataCell>Set body short size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>level</code>
              </Table.DataCell>
              <Table.DataCell>Sets a bottom margin</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace />

      {/* <!-- DETAIL --> */}

      <Heading className='toc' size='medium' id='typography-label' level='2' spacing>
        Detail
      </Heading>
      <VerticalSpace />
      <BodyLong>Detail is suitable for small details that are accessory text, like footnotes</BodyLong>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { Detail } from '@cegal/ds-components'

const Component = () => {
  return (
    <Detail>Please enter data in DD/MM/YYYY format</Detail>
  )
}`}
      >
        <Detail>Please enter data in DD/MM/YYYY format</Detail>
      </DemoableDiv>

      <VerticalSpace />
      <Heading className='toc' size='medium' id='typography-detail-props' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>"medium" | "small"</code>
              </Table.DataCell>
              <Table.DataCell>Set body short size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>level</code>
              </Table.DataCell>
              <Table.DataCell>Sets a bottom margin</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace />
    </>
  )
}

export default ComponentsTypography
