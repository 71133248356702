import {
  Accordion,
  BodyLong,
  BodyShort,
  Button,
  FlexDiv,
  Heading,
  Link,
  Panel,
  PileCenterDiv,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'
import reactElementToJSXString from 'react-element-to-jsx-string'

const SampleAccordion = ({
  size = 'medium',
  variant = 'primary',
  border = false,
  arrow = true,
  open
}: any) => (
  <Accordion border={border} size={size} variant={variant} arrow={arrow}>
    <Accordion.Item open={open}>
      <Accordion.Header>Recipe ingredients</Accordion.Header>
      <Accordion.Content>
        <span>You need these ingredients:</span>
        <ul>
          <li>Flour</li>
          <li>Milk</li>
          <li>Eggs</li>
          <li>Sugar</li>
          <li>Butter</li>
        </ul>
      </Accordion.Content>
    </Accordion.Item>
    <Accordion.Item open={open}>
      <Accordion.Header>Preparation</Accordion.Header>
      <Accordion.Content>
        <ul>
          <li>Add everything in a bowl</li>
          <li>Mix it for 10 min</li>
          <li>
            Put in a oven for 20 min, 200<sup>o</sup> C
          </li>
        </ul>
      </Accordion.Content>
    </Accordion.Item>
  </Accordion>
)

const ComponentsAccordion = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [open2, setOpen2] = useState<number>(1)

  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='Accordion' level='1' spacing>
        Accordion
      </Heading>

      <VerticalSpace />
      <BodyLong spacing>Accordions organize your content into expendable sections.</BodyLong>

      <BodyLong spacing>
        Be sure you use the exported Accordion sub-components <code>Accordion.Item</code>,{' '}
        <code>Accordion.Header</code> and <code>Accordion.Content</code> as shown in the examples.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Accordion-default' level='2' spacing>
        Default component
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The default component renders with medium size, primary variant, no border and with arrow.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Accordion } from '@cegal/ds-components'
                    
const Component = () => {
  return ${reactElementToJSXString(SampleAccordion({}), {
    showDefaultProps: false,
    filterProps: ['arrow', 'border', 'size', 'variant']
  })}
}`}
      >
        <SampleAccordion />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Accordion-variant' level='2' spacing>
        Variants
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>variant</code> prop can be one of the following 3 values:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type AccordionVariant = 'primary' | 'secondary' | 'tertiary'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>AccordionVariant</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Accordion } from '@cegal/ds-components'
                    
const Component = () => {
  return (
    <FlexDiv>
      <PileCenterDiv>
        <BodyShort spacing>Primary</BodyShort>
          ${reactElementToJSXString(SampleAccordion({ variant: 'primary' }), {
            showDefaultProps: false,
            filterProps: ['arrow', 'border', 'size'],
            maxInlineAttributesLineLength: 2
          })}
      </PileCenterDiv>
      <PileCenterDiv>
        <BodyShort spacing>Secondary</BodyShort>
          ${reactElementToJSXString(SampleAccordion({ variant: 'secondary' }), {
            showDefaultProps: false,
            filterProps: ['arrow', 'border', 'size'],
            maxInlineAttributesLineLength: 2
          })}
           </PileCenterDiv>
      <PileCenterDiv>
        <BodyShort spacing>Tertiary</BodyShort>
          ${reactElementToJSXString(SampleAccordion({ variant: 'tertiary' }), {
            showDefaultProps: false,
            filterProps: ['arrow', 'border', 'size'],
            maxInlineAttributesLineLength: 2
          })}
      </PileCenterDiv>
    </FlexDiv>
  )
}`}
      >
        <FlexDiv style={{ justifyContent: 'space-evenly', width: '100%' }}>
          <PileCenterDiv>
            <BodyShort spacing>Primary</BodyShort>
            <SampleAccordion variant='primary' />
          </PileCenterDiv>
          <PileCenterDiv>
            <BodyShort spacing>Secondary</BodyShort>
            <SampleAccordion variant='secondary' />
          </PileCenterDiv>
          <PileCenterDiv>
            <BodyShort spacing>Tertiary</BodyShort>
            <SampleAccordion variant='tertiary' />
          </PileCenterDiv>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Accordion-arrow' level='2' spacing>
        Hide arrow
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>arrow</code> prop adds a expand / collapse icon places on the right side of the accordion
        header. It is true by default, but if you want to hide it, set it to <code>false</code>.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { Accordion } from '@cegal/ds-components'
                    
const Component = () => {
  return (
    <FlexDiv>
      <PileCenterDiv>
        <BodyShort spacing>Primary</BodyShort>
          ${reactElementToJSXString(SampleAccordion({ arrow: true }), {
            showDefaultProps: false,
            filterProps: ['border', 'size', 'variant'],
            maxInlineAttributesLineLength: 2
          })}
      </PileCenterDiv>
      <PileCenterDiv>
        <BodyShort spacing>Secondary</BodyShort>
          ${reactElementToJSXString(SampleAccordion({ arrow: false }), {
            showDefaultProps: false,
            filterProps: ['border', 'size', 'variant'],
            maxInlineAttributesLineLength: 2
          })}
       </PileCenterDiv>
  
    </FlexDiv>
  )
}`}
      >
        <FlexDiv style={{ justifyContent: 'space-evenly', width: '100%' }}>
          <PileCenterDiv>
            <BodyShort spacing>with arrow</BodyShort>
            <SampleAccordion arrow />
          </PileCenterDiv>
          <PileCenterDiv>
            <BodyShort spacing>without arrow</BodyShort>
            <SampleAccordion arrow={false} />
          </PileCenterDiv>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Accordion-border' level='2' spacing>
        Border
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>border</code> prop adds a border through the accordion's header and content.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { Accordion } from '@cegal/ds-components'
                    
const Component = () => {
  return (
    <FlexDiv>
      <PileCenterDiv>
        <BodyShort spacing>Primary</BodyShort>
          ${reactElementToJSXString(SampleAccordion({ variant: 'primary', border: true }), {
            showDefaultProps: false,
            filterProps: ['arrow', 'size', 'variant'],
            maxInlineAttributesLineLength: 2
          })}
      </PileCenterDiv>
      <PileCenterDiv>
        <BodyShort spacing>Secondary</BodyShort>
          ${reactElementToJSXString(SampleAccordion({ variant: 'secondary', border: true }), {
            showDefaultProps: false,
            filterProps: ['arrow', 'size', 'variant'],
            maxInlineAttributesLineLength: 2
          })}
           </PileCenterDiv>
      <PileCenterDiv>
        <BodyShort spacing>Tertiary</BodyShort>
          ${reactElementToJSXString(SampleAccordion({ variant: 'tertiary', border: true }), {
            showDefaultProps: false,
            filterProps: ['arrow', 'size', 'variant'],
            maxInlineAttributesLineLength: 2
          })}
      </PileCenterDiv>
    </FlexDiv>
  )
}`}
      >
        <FlexDiv style={{ justifyContent: 'space-evenly', width: '100%' }}>
          <PileCenterDiv>
            <BodyShort spacing>Primary</BodyShort>
            <SampleAccordion variant='primary' border />
          </PileCenterDiv>
          <PileCenterDiv>
            <BodyShort spacing>Secondary</BodyShort>
            <SampleAccordion variant='secondary' border />
          </PileCenterDiv>
          <PileCenterDiv>
            <BodyShort spacing>Tertiary</BodyShort>
            <SampleAccordion variant='tertiary' border />
          </PileCenterDiv>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Accordion-sizes' level='2' spacing>
        Sizes
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 3 values:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type AccordionSize = 'medium' | 'small' | 'xsmall'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>AccordionSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Accordion } from '@cegal/ds-components'
                    
const Component = () => {
  return (
    <FlexDiv style={{justifyContent: 'space-evenly', width: '100%'}}>
      <PileCenterDiv>
        <BodyShort spacing>Medium</BodyShort>
      ${reactElementToJSXString(SampleAccordion({ size: 'medium' }), {
        showDefaultProps: false,
        filterProps: ['variant']
      })}
      </PileCenterDiv>
      <PileCenterDiv>
        <BodyShort spacing>Small</BodyShort>
      ${reactElementToJSXString(SampleAccordion({ variant: 'small' }), {
        showDefaultProps: false,
        filterProps: ['variant']
      })}
       </PileCenterDiv>
      <PileCenterDiv>
        <BodyShort spacing>Xsmall</BodyShort>
        ${reactElementToJSXString(SampleAccordion({ variant: 'xsmall' }), {
          showDefaultProps: false,
          filterProps: ['variant']
        })}
    </PileCenterDiv>
   </FlexDiv>
  )
}`}
      >
        <FlexDiv style={{ justifyContent: 'space-evenly', width: '100%' }}>
          <PileCenterDiv>
            <BodyShort spacing>Medium</BodyShort>
            <SampleAccordion size='medium' />
          </PileCenterDiv>
          <PileCenterDiv>
            <BodyShort spacing>Small</BodyShort>
            <SampleAccordion size='small' />
          </PileCenterDiv>
          <PileCenterDiv>
            <BodyShort spacing>Xsmall</BodyShort>
            <SampleAccordion size='xsmall' />
          </PileCenterDiv>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Accordion-controlled' level='2' spacing>
        Controlling the open state
      </Heading>

      <VerticalSpace />
      <BodyLong spacing>
        You can control the expand/collapse state of accordion item(s) from the outside, as follows:
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Accordion } from '@cegal/ds-components'
                    
const Component = () => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <>
      <Panel>
       <Button onClick={() => setOpen(!open)}>Toggle</Button>
        <VerticalSpace/>  
         ${reactElementToJSXString(SampleAccordion({ open }), {
           showDefaultProps: false,
           filterProps: ['size'],
           maxInlineAttributesLineLength: 2
         })}
      </Panel>
    )`}
      >
        <Panel>
          <Button onClick={() => setOpen(!open)}>Toggle</Button>
          <VerticalSpace />
          <SampleAccordion open={open} />
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Accordion-one-open' level='2' spacing>
        One section open at a time
      </Heading>

      <VerticalSpace />
      <BodyLong spacing>
        If you want to have the accordion with maximum of one section open at the same time, do this for
        example:
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Accordion } from '@cegal/ds-components'
                    
const Component = () => {
  const [open, setOpen] = useState<number>(1)
  return (
    <Accordion>
      <Accordion.Item open={open === 1}>
        <Accordion.Header onClick={() => setOpen(1)}>
          Recipe ingredients
        </Accordion.Header>
        <Accordion.Content>
          <span>You need these ingredients:</span>
          <ul>
           <li>Flour</li>
           <li>Milk</li>
           <li>Eggs</li>
           <li>Sugar</li>
           <li>Butter</li>
         </ul>
        </Accordion.Content>
      </Accordion.Item>
      <Accordion.Item open={open === 2}>
        <Accordion.Header onClick={() => setOpen(2)}>
          Preparation
        </Accordion.Header>
        <Accordion.Content>
          <ul>
            <li>Add everything in a bowl</li>
            <li>Mix it for 10 min</li>
            <li>Put in a oven for 20 min, 200<sup>o</sup> C</li>
          </ul>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
    )`}
      >
        <Accordion>
          <Accordion.Item open={open2 === 1}>
            <Accordion.Header onClick={() => setOpen2(1)}>Recipe ingredients</Accordion.Header>
            <Accordion.Content>
              <span>You need these ingredients:</span>
              <ul>
                <li>Flour</li>
                <li>Milk</li>
                <li>Eggs</li>
                <li>Sugar</li>
                <li>Butter</li>
              </ul>
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item open={open2 === 2}>
            <Accordion.Header onClick={() => setOpen2(2)}>Preparation</Accordion.Header>
            <Accordion.Content>
              <ul>
                <li>Add everything in a bowl</li>
                <li>Mix it for 10 min</li>
                <li>
                  Put in a oven for 20 min, 200<sup>o</sup> C
                </li>
              </ul>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props tables
      </Heading>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Accordion-properties' level='3' spacing>
        Accordion props
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>AccordionProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLDivElement&gt;</code>, as the top level uses a <code>div</code> tag.
        As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLDivElement'>HTMLDivElement</Link>{' '}
        props.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>arrow</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets accordion arrow</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>border</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets accordion border</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small' | 'xsmall'</code>
              </Table.DataCell>
              <Table.DataCell>Sets accordion size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'primary' | 'secondary' | 'tertiary'</code>
              </Table.DataCell>
              <Table.DataCell>Sets accordion variant</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>primary</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Accordion-item-properties' level='3' spacing>
        Accordion.Item props
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>AccordionItemProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLDivElement&gt;</code>, as the top level uses a <code>div</code> tag.
        As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLDivElement'>HTMLDivElement</Link>{' '}
        props.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>defaultOpen</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Defaults the accordion to open if not controlled</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>open</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>
                Sets accordion to open state. Use this if you want to remove automatic control of open state
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small' | 'xsmall'</code>
              </Table.DataCell>
              <Table.DataCell>Sets accordion item size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Accordion-header-properties' level='3' spacing>
        Accordion.Header props
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>AccordionHeaderProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLButtonElement&gt;</code>, as the top level uses a{' '}
        <code>button</code> tag. As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLDButtonElement'>
          HTMLButtonElement
        </Link>{' '}
        props and <Link href='https://developer.mozilla.org/en-US/docs/Web/API/UIEvent'>UI events</Link> such
        as <code>onClick</code>.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Accordion-content-properties' level='3' spacing>
        Accordion.Content props
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>AccordionContentProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLDivElement&gt;</code>, as the top level uses a <code>div</code> tag.
        As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLDivElement'>HTMLDivElement</Link>{' '}
        props.
      </BodyLong>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsAccordion
