import { BodyLong, Heading, VerticalSpace, Table, Carousel } from '@cegal/ds-components'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React from 'react'

const ComponentsCarousel = () => {
  return (
    <>
      <VerticalSpace size='3' />
      <Heading size='large' id='Carousel' level='1' spacing>
        Carousel
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        The <code>Carousel</code> component generates a sliding panel
      </BodyLong>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Dropdown-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        This example illustrates how all the <code>Carousel</code> components work.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Carousel } from '@cegal/ds-components'

const Component = () => {
  return (
    <Carousel border>
      <Carousel.Item> Eggs </Carousel.Item>
      <Carousel.Item> Milk </Carousel.Item>
      <Carousel.Item> Bread </Carousel.Item>
    </Carousel>
  )
}`}
      >
        <Carousel border>
          <Carousel.Item> Eggs </Carousel.Item>
          <Carousel.Item> Milk </Carousel.Item>
          <Carousel.Item> Bread </Carousel.Item>
        </Carousel>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Dropdown-indicators' level='2' spacing>
        Indicators
      </Heading>
      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Carousel } from '@cegal/ds-components'

const Component = () => {
  return (
    <Carousel border showIndicators>
      <Carousel.Item> Eggs </Carousel.Item>
      <Carousel.Item> Milk </Carousel.Item>
      <Carousel.Item> Bread </Carousel.Item>
    </Carousel>
  )
}`}
      >
        <Carousel border showIndicators>
          <Carousel.Item> Eggs </Carousel.Item>
          <Carousel.Item> Milk </Carousel.Item>
          <Carousel.Item> Bread </Carousel.Item>
        </Carousel>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>activeIndex</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Current active slide</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>border</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Defines whether carousel has a border around it</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(value: number) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback function when next/previous item button is clicked</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>showIndicators</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Display the slide number indicator dots</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
    </>
  )
}

export default ComponentsCarousel
