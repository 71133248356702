import {
  Avatar,
  FlexDiv,
  FlexEndSpacedDiv,
  FlexEndDiv,
  BodyLong,
  Button,
  Heading,
  HorizontalSpace,
  Link,
  PileCenterDiv,
  VerticalSpace,
  Table
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsAccordion = () => {
  const [src, setSrc] = useState<string | undefined>(undefined)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='Avatar' level='1' spacing>
        Avatar
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        Avatars render an image with a fixed width/height ratio of 1:1. The picture will always be centered,
        regardless if the picture is in portrait or landscape mode.
      </BodyLong>

      <PileCenterDiv>
        <FlexEndDiv>
          <PileCenterDiv>
            <img alt='test avatar' style={{ width: '96px' }} src='/static/images/avatar2_non_square.jpeg' />
            Original portrait image
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar variant='square' border size='large' src='/static/images/avatar2_non_square.jpeg' />
            picture in Avatar
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <img alt='test avatar' style={{ height: '96px' }} src='/static/images/avatar3_non_square.jpeg' />
            Original landscape image
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar variant='square' border size='large' src='/static/images/avatar3_non_square.jpeg' />
            picture in Avatar
          </PileCenterDiv>
        </FlexEndDiv>
      </PileCenterDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Avatar-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>The default component uses a round mask, no border and medium size.</BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Avatar } from '@cegal/ds-components'
                    
const Component = () => {
  return <Avatar src='/static/images/avatar1.png'/>
}`}
      >
        <Avatar src='/static/images/avatar1.png' />
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Avatar-sizes' level='2' spacing>
        Sizes
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>The avatar size prop can be one of the following 7 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type AvatarSize = '2xlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | '2xsmall'
      </CodeDiv>

      <BodyLong spacing>The absolute sizes depend on the theme, but their relative sizes are these:</BodyLong>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeader>Size</Table.ColumnHeader>
            <Table.ColumnHeader>CSS Variable</Table.ColumnHeader>
            <Table.ColumnHeader>rem/px size in Cegal theme</Table.ColumnHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.DataCell>
              <code>2xlarge</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>var(--cds-size-48)</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>12rem (192px)</code>
            </Table.DataCell>
          </Table.Row>
          <Table.Row>
            <Table.DataCell>
              <code>xlarge</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>var(--cds-size-32)</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>8rem (128px)</code>
            </Table.DataCell>
          </Table.Row>
          <Table.Row>
            <Table.DataCell>
              <code>large</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>var(--cds-size-24)</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>6rem (96px)</code>
            </Table.DataCell>
          </Table.Row>
          <Table.Row>
            <Table.DataCell>
              <code>medium</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>var(--cds-size-16)</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>4rem (64px)</code>
            </Table.DataCell>
          </Table.Row>
          <Table.Row>
            <Table.DataCell>
              <code>small</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>var(--cds-size-12)</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>3rem (48px)</code>
            </Table.DataCell>
          </Table.Row>
          <Table.Row>
            <Table.DataCell>
              <code>xsmall</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>var(--cds-size-8)</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>2rem (32px)</code>
            </Table.DataCell>
          </Table.Row>
          <Table.Row>
            <Table.DataCell>
              <code>2xsmall</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>var(--cds-size-6)</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>1rem (24px)</code>
            </Table.DataCell>
          </Table.Row>
        </Table.Body>
      </Table>

      <VerticalSpace size='2' />
      <BodyLong spacing>
        You can use the exported type <code>AvatarSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Avatar } from '@cegal/ds-components'

const Component = () => {
  return (
     <>
      <Avatar size='2xlarge' src='/static/images/avatar1.png'/>
      <Avatar size='xlarge' src='/static/images/avatar1.png'/>
      <Avatar size='large' src='/static/images/avatar1.png'/>
      <Avatar size='medium' src='/static/images/avatar1.png'/>
      <Avatar size='small' src='/static/images/avatar1.png'/>
      <Avatar size='xsmall' src='/static/images/avatar1.png'/>
      <Avatar size='2xsmall' src='/static/images/avatar1.png'/>
    </>
  )
}`}
      >
        <FlexEndSpacedDiv>
          <PileCenterDiv>
            <Avatar size='2xlarge' src='/static/images/avatar1.png' />
            2xlarge
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar size='xlarge' src='/static/images/avatar1.png' />
            xlarge
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar size='large' src='/static/images/avatar1.png' />
            large
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar size='medium' src='/static/images/avatar1.png' />
            medium
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar size='small' src='/static/images/avatar1.png' />
            small
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar size='xsmall' src='/static/images/avatar1.png' />
            xsmall
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar size='2xsmall' src='/static/images/avatar1.png' />
            2xsmall
          </PileCenterDiv>
        </FlexEndSpacedDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Avatar-variants' level='2' spacing>
        Variants
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>variant</code> prop can be one of the following 3 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type AvatarVariant = 'circle' | 'square' | 'square-round'
      </CodeDiv>

      <VerticalSpace size='2' />
      <BodyLong spacing>
        You can use the exported type <code>AvatarVariant</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Avatar } from '@cegal/ds-components'

const Component = () => {
  return (
     <>
      <Avatar variant='circle' src='/static/images/avatar1.png'/>
      <Avatar variant='square' src='/static/images/avatar1.png'/>
      <Avatar variant='square-round' src='/static/images/avatar1.png'/>
    </>
  )
}`}
      >
        <FlexEndSpacedDiv>
          <PileCenterDiv>
            <Avatar variant='circle' src='/static/images/avatar1.png' />
            circle
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar variant='square' src='/static/images/avatar1.png' />
            square
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar variant='square-round' src='/static/images/avatar1.png' />
            square-round
          </PileCenterDiv>
        </FlexEndSpacedDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Avatar-border' level='2' spacing>
        Border
      </Heading>
      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { Accordion } from '@cegal/ds-components'

const Component = () => {
  return (
     <>
      <Avatar border size='2xlarge' src='/static/images/avatar1.png'/>
      <Avatar border size='xlarge' src='/static/images/avatar1.png'/>
      <Avatar border size='large' src='/static/images/avatar1.png'/>
      <Avatar border size='medium' src='/static/images/avatar1.png'/>
      <Avatar border size='small' src='/static/images/avatar1.png'/>
      <Avatar border size='xsmall' src='/static/images/avatar1.png'/>
      <Avatar border size='2xsmall' src='/static/images/avatar1.png'/>
    </>
  )
}`}
      >
        <FlexEndSpacedDiv>
          <PileCenterDiv>
            <Avatar border size='2xlarge' src='/static/images/avatar1.png' />
            2xlarge
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar border size='xlarge' src='/static/images/avatar1.png' />
            xlarge
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar border size='large' src='/static/images/avatar1.png' />
            large
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar border size='medium' src='/static/images/avatar1.png' />
            medium
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar border size='small' src='/static/images/avatar1.png' />
            small
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Avatar border size='xsmall' src='/static/images/avatar1.png' />
            xsmall
          </PileCenterDiv>
          <PileCenterDiv>
            <Avatar border size='2xsmall' src='/static/images/avatar1.png' />
            2xsmall
          </PileCenterDiv>
        </FlexEndSpacedDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Avatar-null' level='2' spacing>
        No valid src
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        if you do not pass a <code>src</code> value, or it is invalid, the Avatar component will default to a
        placeholder image, until you update the prop.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Avatar } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <FlexEndDiv>
        <Avatar size='2xlarge'/>
        <Avatar size='xlarge'/>
        <Avatar size='large'/>
        <Avatar size='medium'/>
        <Avatar size='small'/>
        <Avatar size='xsmall'/>
        <Avatar size='2xsmall'/>
      </FlexEndDiv>
      <FlexEndDiv>
        <Avatar variant='square' size='2xlarge'/>
        <Avatar variant='square' size='xlarge'/>
        <Avatar variant='square' size='large'/>
        <Avatar variant='square' size='medium'/>
        <Avatar variant='square' size='small'/>
        <Avatar variant='square' size='xsmall'/>
        <Avatar variant='square' size='2xsmall'/>
      </FlexEndDiv> 
    </>
  )
}`}
      >
        <FlexEndDiv>
          <Avatar size='2xlarge' />
          <HorizontalSpace />
          <Avatar size='xlarge' />
          <HorizontalSpace />
          <Avatar size='large' />
          <HorizontalSpace />
          <Avatar size='medium' />
          <HorizontalSpace />
          <Avatar size='small' />
          <HorizontalSpace />
          <Avatar size='xsmall' />
          <HorizontalSpace />
          <Avatar size='2xsmall' />
        </FlexEndDiv>
        <VerticalSpace />
        <FlexEndDiv>
          <Avatar variant='square' size='2xlarge' />
          <HorizontalSpace />
          <Avatar variant='square' size='xlarge' />
          <HorizontalSpace />
          <Avatar variant='square' size='large' />
          <HorizontalSpace />
          <Avatar variant='square' size='medium' />
          <HorizontalSpace />
          <Avatar variant='square' size='small' />
          <HorizontalSpace />
          <Avatar variant='square' size='xsmall' />
          <HorizontalSpace />
          <Avatar variant='square' size='2xsmall' />
        </FlexEndDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Avatar-null' level='2' spacing>
        Set a default image
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can pass a <code>defaultSrc</code> value, or it is invalid, the Avatar component will default to a
        placeholder image, until you update the prop.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Avatar } from '@cegal/ds-components'

const Component = () => {
  const [src, setSrc] = useState(undefined)
  return (
     <FlexDiv>
      <Button 
        onClick={() => 
          setSrc(src === undefined 
            ? '/static/images/avatar1.png'
            : undefined
          )}>Toggle</Button>
      <HorizontalSpace/>
      <Avatar 
        defaultSrc='/static/images/avatar2_non_square.jpeg' 
        src={src}
      />
    </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <Button onClick={() => setSrc(src === undefined ? '/static/images/avatar1.png' : undefined)}>
            Toggle
          </Button>
          <HorizontalSpace />
          <Avatar defaultSrc='/static/images/avatar2_non_square.jpeg' src={src} />
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Avatar-loading' level='2' spacing>
        Loading
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can use <code>loading</code> prop to trigger a loading animation
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Avatar } from '@cegal/ds-components'

const Component = () => {
  return (
     <FlexDiv>
      <Avatar loading/>
      <Avatar variant='square' loading/>
      <Avatar loading src='/static/images/avatar1.png'/>
      <Avatar loading variant='square' src='/static/images/avatar1.png'/>
    </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <Avatar loading />
          <HorizontalSpace />
          <Avatar variant='square' loading />
          <HorizontalSpace />
          <Avatar loading src='/static/images/avatar1.png' />
          <HorizontalSpace />
          <Avatar loading variant='square' src='/static/images/avatar1.png' />
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>AvatarProps</code> interface extends <code>React.HTMLAttributes&lt;HTMLDivElement&gt;</code>
        , as the top level uses a <code>div</code> tag. As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLDivElement'>HTMLDivElement</Link>{' '}
        props.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>alt</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets avatar's img alt prop</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>'avatar'</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>border</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets avatar border</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>defaultSrc</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets avatar's src while it does not have the final one</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>SVG logos for each variant</Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>loading</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets avatar loading animation</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code> '2xlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | '2xsmall'</code>
              </Table.DataCell>
              <Table.DataCell>Sets avatar size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds a margin bottom</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'circle' | 'square' | 'square-round'</code>
              </Table.DataCell>
              <Table.DataCell>Sets avatar variant</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>circle</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsAccordion
