import {
  Alert,
  BodyLong,
  Column,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Link,
  Panel,
  Row,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import * as Icons from '@cegal/ds-icons'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  100% {
    transform:rotate(360deg);
  }
`
const SpinningShoppingCart = styled(Icons.AddShoppingCart)`
  animation: ${spin} 4s linear infinite;
`

const ResourcesIcons = () => {
  const [filterText, setFilterText] = useState<string>('')

  return (
    <>
      <VerticalSpace />
      <Heading size='large'>Icons</Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        The <code>@cegal/ds-icons</code> package contains all icons from the Cegal brandpad identity, which
        are <Link href='https://mui.com/material-ui/material-icons/'>Material Icons</Link> in the sharp
        version.
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' level='2' id='usage' spacing>
        Using icons in your project
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>You can import all icons as a module.</BodyLong>
      <CodeDiv spacing expand={false}>
        {`import * as Icons from '@cegal/ds-icons'

const EmailIcon = () => (<Icons.Email size="2rem" title="Email" />)`}
      </CodeDiv>

      <BodyLong spacing>You can just import the icon modules you need.</BodyLong>

      <CodeDiv spacing expand={false}>
        {`import { Email } from '@cegal/ds-icons'

const EmailIcon = () => (<Email size="2rem" title="Email" />)`}
      </CodeDiv>

      <BodyLong spacing>You can rename the imported icon module.</BodyLong>

      <CodeDiv spacing expand={false}>
        {`import { Email as EmailIcon } from '@cegal/ds-icons'

const Email = () => (<EmailIcon size="2rem" title="Email" />)`}
      </CodeDiv>

      <VerticalSpace />

      <Alert variant='warning'>
        You <strong>have to set the size of the icon</strong>, preferably using rem values. If you don't, the
        icon will likely not show with the dimensions you want.
      </Alert>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' level='2' id='usage' spacing>
        Customising icons
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        Color, size and rotation can be customized. Be aware that, by setting the color in the style, you are
        setting it for any themes. This is OK if your application will only use one theme, but if it supports
        light/dark modes, it is preferable that you set the color as a CSS variable. Either declare them in a
        CSS file, or reuse any of the component variables set, like the example below shows.
      </BodyLong>

      <DemoableDiv
        code={`
<AddShoppingCart size='2rem'/>
<AddShoppingCart size='3rem' color='red'/>
<AddShoppingCart size='4rem' style={{
    transform: 'perspective(70px) rotateX(15deg) rotateY(45deg) rotateZ(-15deg)'
}}/>
<AddShoppingCart size='2rem' style={{
    backgroundColor: 'red'}}/>
<AddShoppingCart size='2rem' style={{ 
    backgroundColor: '--var(--cds-button-color-background-active)' }} />
<SpinningShoppingCart size='2rem'/>
          `}
      >
        <FlexDiv>
          <Icons.AddShoppingCart size='2rem' />
          <HorizontalSpace />
          <Icons.AddShoppingCart size='3rem' color='red' />
          <HorizontalSpace />
          <Icons.AddShoppingCart
            size='4rem'
            style={{ transform: 'perspective(70px) rotateX(15deg) rotateY(45deg) rotateZ(-15deg)' }}
          />
          <HorizontalSpace />
          <Icons.AddShoppingCart size='2rem' style={{ backgroundColor: 'red' }} />
          <HorizontalSpace />
          <Icons.AddShoppingCart
            size='2rem'
            style={{ backgroundColor: 'var(--cds-button-color-background-active)' }}
          />
          <HorizontalSpace />
          <SpinningShoppingCart size='2rem' />
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <a href='#browse' className='cds-sr-only'>
        Usage
      </a>
      <Heading className='toc' size='medium' level='2' id='browse' spacing>
        Browsing icon library
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        Search all the {Object.keys(Icons).length} icons by typing a query in the text field below.
      </BodyLong>

      <Row>
        <Column>
          <TextField
            label='Icon search query'
            value={filterText}
            onChange={(e: any) => setFilterText(e.target.value)}
          />
        </Column>
        <Column />
      </Row>
      <VerticalSpace size='2' />
      <FlexDiv style={{ flexWrap: 'wrap' }}>
        {!!filterText &&
          Object.keys(Icons)
            .filter((icon) => icon.toLowerCase().match(filterText.toLowerCase()))
            .map((icon: any) => {
              const Icon: any = (Icons as any)[icon]
              return (
                <Panel key={icon} border style={{ padding: '0.5rem', margin: '0.5rem' }}>
                  <Icon size='2rem' />
                  <HorizontalSpace size='0.5' />
                  {icon}
                </Panel>
              )
            })}
      </FlexDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ResourcesIcons
