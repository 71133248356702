import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      lng: 'en',
      fallbackLng: {
        default: ['en']
      },
      debug: true,
      ns: ['label'],
      defaultNS: 'label',
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      },
      interpolation: {
        escapeValue: false
      },
      react: {
        bindI18n: 'languageChange loaded',
        nsMode: 'default'
      }
    },
    (err) => {
      if (err) return console.log('Loading i18n error', err)
      i18n.changeLanguage('en')
    }
  )

i18n.loadLanguages(['en'], () => {})
i18n.language = 'en'
document.documentElement.lang = 'en'
export default i18n
