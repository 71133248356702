import { BodyLong, Datepicker, Heading, Link, VerticalSpace } from '@cegal/ds-components'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import React from 'react'

const ComponentsDatepicker = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='datepicker' level='1' spacing>
        Datepicker
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>Datepicker</code> component is a <code>TextField</code> component with a <code>type</code>{' '}
        prop hardcoded to the value <code>date</code>.
      </BodyLong>

      <BodyLong spacing>
        This components uses the native browser's datepicker, so it works properly with mobile device
        browsers.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='datepicker-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { Datepicker } from '@cegal/ds-components'

const Component = () => {
  return (
    <Datepicker label='Baking cake day'/>
  )
}`}
      >
        <Datepicker label='Baking cake day' />
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='datepicker-limiting' level='2' spacing>
        Limiting dates
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        You can set the <code>min</code>, <code>max</code> or <code>step</code> properties to limit dates to a
        certain range and interval.
      </BodyLong>

      <BodyLong spacing>
        Note that you <strong>still need to validate the date within your app</strong>, as the user can still
        add dates that do not comply with the defined limits.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Datepicker } from '@cegal/ds-components'

const Component = () => {
  return (
     <Datepicker 
        min='2020-01-01' 
        max='2029-12-31' 
        step='7' 
        label='Only Wednesday dates from 2020 to 2029'
      />
  )
}`}
      >
        <Datepicker
          min='2020-01-01'
          max='2029-12-31'
          step='7'
          label='Only Wednesday dates from 2020 to 2029'
        />
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>Datepicker</code> component inherits all the properties from <code>TextField</code> such as{' '}
        <code>hideLabel</code>, native{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLDivElement'>HTMLDivElement</Link>{' '}
        interface properties such as <code>value</code>, and <code>UIEvents</code> such as{' '}
        <code>onClick</code>.
      </BodyLong>

      <BodyLong spacing>
        We recommend you check the{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date'>input date</Link>{' '}
        HTML element properties so you can read about other props that can be used in this component.
      </BodyLong>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsDatepicker
