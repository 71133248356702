import {
  BodyLong,
  FlexCenterDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Link,
  PileStartDiv,
  ReadMore,
  ReadMoreSize,
  Switch,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsHome = () => {
  const [size, setSize] = useState<string>('medium')
  const [open, setOpen] = useState<boolean>(false)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='readmore' level='1' spacing>
        Read more
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>ReadMore</code> component offers an expandable panel for content that can be initially
        hidden from the user, for the sake of layout clarity.
      </BodyLong>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='readmore-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { ReadMore } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexDiv >
      <ReadMore header='Preparation'>
        <ul>
          <li>Add everything in a bowl</li>
          <li>Mix it for 10 min</li>
          <li>
            Put in a oven for 20 min, 200<sup>o</sup> C
          </li>
        </ul>
      </ReadMore>
    </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <ReadMore header='Preparation'>
            <ul>
              <li>Add everything in a bowl</li>
              <li>Mix it for 10 min</li>
              <li>
                Put in a oven for 20 min, 200<sup>o</sup> C
              </li>
            </ul>
          </ReadMore>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='readmore-controlled' level='2' spacing>
        Controlled
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        Set the <code>open</code> and <code>onClick</code> props so you can control the component.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { ReadMore } from '@cegal/ds-components'

const Component = () => {
 const [size, setSize] = useState<string>('medium')
 return (
   <PileStartDiv>
      <FlexCenterDiv>
        <BodyLong>Medium</BodyLong>
        <HorizontalSpace size='0.5' />
        <Switch
          checked={size === 'small'}
          hideLabel
          position='left'
          onClick={() => 
            setSize(size === 'medium' ? 'small' : 'medium')
          }
        />
        <HorizontalSpace size='3.5' />
        <BodyLofng>Small</BodyLofng>
      </FlexCenterDiv>
      <ReadMore 
        open={open} 
        onClick={() => setOpen(!open)} 
        header='Preparation'
      >
       <ul>
        <li>Add everything in a bowl</li>
        <li>Mix it for 10 min</li>
        <li>
          Put in a oven for 20 min, 200<sup>o</sup> C
        </li>
      </ul>
      </ReadMore>
   </PileStartDiv>
  </PileDiv>
  )
}`}
      >
        <PileStartDiv>
          <FlexCenterDiv>
            <BodyLong>Open</BodyLong>
            <HorizontalSpace size='0.5' />
            <Switch checked={!open} hideLabel position='left' onClick={() => setOpen(!open)}>
              Open
            </Switch>
            <HorizontalSpace size='3.5' />
            <BodyLong>Closed</BodyLong>
          </FlexCenterDiv>
          <ReadMore open={open} onClick={() => setOpen(!open)} header='Preparation'>
            <ul>
              <li>Add everything in a bowl</li>
              <li>Mix it for 10 min</li>
              <li>
                Put in a oven for 20 min, 200<sup>o</sup> C
              </li>
            </ul>
          </ReadMore>
        </PileStartDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='readmore-default' level='2' spacing>
        Size
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type ReadMoreSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>ReadMoreSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { ReadMore } from '@cegal/ds-components'

const Component = () => {
 const [size, setSize] = useState<string>('medium')
 return (
    <PileStartDiv >
      <FlexCenterDiv>
        <BodyLong>Medium</BodyLong>
      <HorizontalSpace size='0.5' />
      <Switch
        checked={size === 'small'}
        hideLabel
        position='left'
        onClick={() => 
          setSize(size === 'medium' ? 'small' : 'medium')
        }
      />
      <HorizontalSpace size='3.5' />
      <BodyLong>Small</BodyLong>
    </FlexCenterDiv>
    <ReadMore 
      size={size} 
      header='Preparation'
    >
     <ul>
      <li>Add everything in a bowl</li>
      <li>Mix it for 10 min</li>
      <li>
        Put in a oven for 20 min, 200<sup>o</sup> C
      </li>
    </ul>
    </ReadMore>
  </PileStartDiv>
  )
}`}
      >
        <PileStartDiv>
          <FlexCenterDiv>
            <BodyLong>Medium</BodyLong>
            <HorizontalSpace size='0.5' />
            <Switch
              checked={size === 'small'}
              hideLabel
              position='left'
              onClick={() => setSize(size === 'medium' ? 'small' : 'medium')}
            >
              position
            </Switch>
            <HorizontalSpace size='3.5' />
            <BodyLong>Small</BodyLong>
          </FlexCenterDiv>

          <ReadMore size={size as ReadMoreSize} header='Preparation'>
            <ul>
              <li>Add everything in a bowl</li>
              <li>Mix it for 10 min</li>
              <li>
                Put in a oven for 20 min, 200<sup>o</sup> C
              </li>
            </ul>
          </ReadMore>
        </PileStartDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <BodyLong>
        You can still pass <code>button</code> props from{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLButtonElement'>
          HTMLButtonElement
        </Link>{' '}
        interface, such as <code>type</code>,{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props
        such as <code>style</code>, as well as{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/UIEvent'>UI events</Link> such as{' '}
        <code>onClick</code>. They will all be passed to the <code>&lt;button&gt;</code> element.
      </BodyLong>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>defaultOpen</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets default open status</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>header</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>React.ReactNode</code>
              </Table.DataCell>
              <Table.DataCell>Sets read more header</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>open</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets open status</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets read more size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsHome
