import { Button } from '@cegal/ds-components'
import { Check } from '@cegal/ds-icons'
import React, { useState } from 'react'

const CopyClipboardButton = ({
  label = 'Copy to clipboard',
  size = 'small',
  iconPosition = 'left',
  text
}: any) => {
  const [copied, setCopied] = useState<boolean>(false)

  const copyClipboard = (text: any) => {
    navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  return (
    <Button
      size={size}
      icon={copied ? <Check size='1.2rem' /> : null}
      iconPosition={iconPosition}
      onClick={() => copyClipboard(text)}
    >
      {label}
    </Button>
  )
}

export default CopyClipboardButton
