import {
  BodyLong,
  Button,
  Checkbox,
  PileDiv,
  FlexDiv,
  Heading,
  Link,
  HorizontalSpace,
  SelectMultiple,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsSelectMultiple = () => {
  const options = [
    { label: 'Chocolate cake', value: 'chocolate' },
    { label: 'Cheesecake', value: 'cheese' },
    { label: 'Carrot cake', value: 'carrot' },
    { label: 'Banana cake', value: 'banana' }
  ]
  const [values, setValues] = useState<any>()
  const [checkbox, setCheckbox] = useState<boolean>(false)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='select' level='1' spacing>
        Select multiple
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        This is a select component for multiple elements, based on the{' '}
        <Link href='https://react-select.com/'>react-select</Link> package.
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='select-default' level='2' spacing>
        Default component
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>The default component hides chosen options from the dropdown menu. </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  return (
    <SelectMultiple
      options={[
        { label: 'Chocolate cake', value: 'chocolate' },
        { label: 'Cheesecake', value: 'cheese' },
        { label: 'Carrot cake', value: 'carrot' },
        { label: 'Banana cake', value: 'banana' }
      ]}
    />
  )
}`}
      >
        <SelectMultiple
          options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-controlled' level='2' spacing>
        Controlled
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You control the <code>SelectMultiple</code> component by setting the
        <code>values</code> and <code>onChange</code> props. Note that the <code>onChange</code> callback
        function will receive an array of selected options as a paramenter, instead of an event object typical
        of native HTML form elements.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  const [values, setValues] = useState([])
  const options = [
    { label: 'Chocolate cake', value: 'chocolate' },
    { label: 'Cheesecake', value: 'cheese' },
    { label: 'Carrot cake', value: 'carrot' },
    { label: 'Banana cake', value: 'banana' }
  ]
  return (
    <PileDiv>
      <SelectMultiple
        label='Choose cakes'
        onChange={setValues}
        value={values}
        options={options}
      />
      <VerticalSpace/>
      <FlexDiv>  
        <Button 
          size='small' 
          onClick={() => setValues([])}
        >
          select none
        </Button>
        <HorizontalSpace/>
        <Button 
          size='small' 
          onClick={() => setValues(options)}
        >
          select all
        </Button>
      </FlexDiv>
    </PileDiv>
  )
}`}
      >
        <PileDiv>
          <SelectMultiple label='Choose cakes' onChange={setValues} value={values} options={options} />
          <VerticalSpace />
          <FlexDiv>
            <Button size='small' onClick={() => setValues([])}>
              select none
            </Button>
            <HorizontalSpace />
            <Button size='small' onClick={() => setValues(options)}>
              select all
            </Button>
          </FlexDiv>
        </PileDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-size' level='2' spacing>
        Size
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  const [values, setValues] = useState()
  return (
     <FlexDiv>
        <SelectMultiple
          label='Medium cakes'
          size='medium'
          options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
       <HorizontalSpace/>
       <SelectMultiple
         label='Small cakes'
         size='small'
         options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
    </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <SelectMultiple
            label='Medium cakes'
            size='medium'
            options={[
              { label: 'Chocolate cake', value: 'chocolate' },
              { label: 'Cheesecake', value: 'cheese' },
              { label: 'Carrot cake', value: 'carrot' },
              { label: 'Banana cake', value: 'banana' }
            ]}
          />
          <HorizontalSpace />
          <SelectMultiple
            label='Small cakes'
            size='small'
            options={[
              { label: 'Chocolate cake', value: 'chocolate' },
              { label: 'Cheesecake', value: 'cheese' },
              { label: 'Carrot cake', value: 'carrot' },
              { label: 'Banana cake', value: 'banana' }
            ]}
          />
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-disable' level='2' spacing>
        Disable
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use <code>disabled</code> prop to deactivate the component
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <SelectMultiple
       label='No cakes today'
       disabled
       options={[
        { label: 'Chocolate cake', value: 'chocolate' },
        { label: 'Cheesecake', value: 'cheese' },
        { label: 'Carrot cake', value: 'carrot' },
        { label: 'Banana cake', value: 'banana' }
     ]}/>
  )
}`}
      >
        <SelectMultiple
          label='No cakes today'
          disabled
          options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-error' level='2' spacing>
        Error
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>error</code> prop sets an error border and displays an error message.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  return (
     <SelectMultiple
      label='Choose your cakes'
      error='Share your cake with someone'
      options={[
        { label: 'Chocolate cake', value: 'chocolate' },
        { label: 'Cheesecake', value: 'cheese' },
        { label: 'Carrot cake', value: 'carrot' },
        { label: 'Banana cake', value: 'banana' }
    ]}/>
  )
}`}
      >
        <SelectMultiple
          label='Choose your cakes'
          error='Share your cake with someone'
          options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-hide-label' level='2' spacing>
        Hide label
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>hideLabel</code> prop hides the form label.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  return (
    <SelectMultiple
      label='hidden label'
      hideLabel
      options={[
        { label: 'Chocolate cake', value: 'chocolate' },
        { label: 'Cheesecake', value: 'cheese' },
        { label: 'Carrot cake', value: 'carrot' },
        { label: 'Banana cake', value: 'banana' }
      ]}
    />
  )
}`}
      >
        <SelectMultiple
          label='hidden label'
          hideLabel
          options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-description' level='2' spacing>
        Description
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>description</code> prop adds a longer caption for the select components.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  return (
    <SelectMultiple
      label='Choose a cake'
      description='Only the best cakes are displayed here'
      options={[
        { label: 'Chocolate cake', value: 'chocolate' },
        { label: 'Cheesecake', value: 'cheese' },
        { label: 'Carrot cake', value: 'carrot' },
        { label: 'Banana cake', value: 'banana' }
      ]}
    />
  )
}`}
      >
        <SelectMultiple
          label='Choose a cake'
          description='Only the best cakes are displayed here'
          options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-creatable' level='2' spacing>
        Creatable
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        If you pass a <code>creatable</code> prop as <code>true</code>, you are allowed to create new options
        that do not exist in the dropdown, by writing them on the text field.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
    
  return (
    <SelectMultiple
      label='Creatable cake'
      description='Add a new cake if you want by typing it in the text field'
      creatable
      options={[
        { label: 'Chocolate cake', value: 'chocolate' },
        { label: 'Cheesecake', value: 'cheese' },
        { label: 'Carrot cake', value: 'carrot' },
        { label: 'Banana cake', value: 'banana' }
      ]}/>
  )
}`}
      >
        <SelectMultiple
          label='Creatable cake'
          description='Add a new cake if you want by typing it in the text field'
          creatable
          options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-close-menu' level='2' spacing>
        Close menu on select
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can define if the dropdown menu closes after a user click, with the <code>closeMenuOnSelect</code>{' '}
        prop, which is <code>false</code> by default.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  const [checkbox, setCheckbox] = useState<boolean>(false)
  return (
    <>
      <Checkbox
        checked={checkbox}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setCheckbox(e.target.checked)}
      >
        Close menu on select
      </Checkbox>
      <SelectMultiple
        label='Choose a cake'
        closeMenuOnSelect={checkbox}
        options={[
          { label: 'Chocolate cake', value: 'chocolate' },
          { label: 'Cheesecake', value: 'cheese' },
          { label: 'Carrot cake', value: 'carrot' },
          { label: 'Banana cake', value: 'banana' }
        ]}
      />
   </>
  )
}`}
      >
        <Checkbox
          checked={checkbox}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCheckbox(e.target.checked)}
        >
          Close menu on select
        </Checkbox>
        <SelectMultiple
          label='Choose a cake'
          closeMenuOnSelect={checkbox}
          options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-loading' level='2' spacing>
        Loading
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can set a loading animation with the <code>loading</code> prop if you are for example fetching
        options from the backend and need the user to wait a little until it is ready to use.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  return (
     <SelectMultiple
        label='Choose your cakes'
        description='Loading cakes, please wait'
        loading
        options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
        ]}/>
  )
}`}
      >
        <SelectMultiple
          label='Choose your cakes'
          description='Loading cakes, please wait'
          loading
          options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-searchable' level='2' spacing>
        Searchable
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        Multiple select component by default lets the user type in and search/filter menu options to choose
        from. This is controlled by the <code>searchable</code> prop which is <code>true</code> by default,
        but you can deactivate it, like in the example below.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  return (
     <FlexDiv>
       <SelectMultiple
          label='Searchable cakes'
          description='You can search cakes here'
          options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
        <HorizontalSpace/>
        <SelectMultiple
          label='No-searchable cakes'
          description="You can't search cakes here"
          searchable={false}
           options={[
            { label: 'Chocolate cake', value: 'chocolate' },
            { label: 'Cheesecake', value: 'cheese' },
            { label: 'Carrot cake', value: 'carrot' },
            { label: 'Banana cake', value: 'banana' }
          ]}
        />
     </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <SelectMultiple
            label='Searchable cakes'
            description='You can search cakes here'
            options={[
              { label: 'Chocolate cake', value: 'chocolate' },
              { label: 'Cheesecake', value: 'cheese' },
              { label: 'Carrot cake', value: 'carrot' },
              { label: 'Banana cake', value: 'banana' }
            ]}
          />
          <HorizontalSpace />
          <SelectMultiple
            label='No-searchable cakes'
            description="You can't search cakes here"
            searchable={false}
            options={[
              { label: 'Chocolate cake', value: 'chocolate' },
              { label: 'Cheesecake', value: 'cheese' },
              { label: 'Carrot cake', value: 'carrot' },
              { label: 'Banana cake', value: 'banana' }
            ]}
          />
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-hide-selected' level='2' spacing>
        Hide selected options
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can hide options that are already selected from the dropdown menu, or you can leave them visible
        as selected. This is controlled with the <code>hideSelectedOptions</code> prop, which is{' '}
        <code>false</code> by default
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexDiv>
      <SelectMultiple
        label='Choose your cakes'
        description='Menu will only show unselected options'
        closeMenuOnSelect={false}
        hideSelectedOptions={true}
        options={[
          { label: 'Chocolate cake', value: 'chocolate' },
          { label: 'Cheesecake', value: 'cheese' },
          { label: 'Carrot cake', value: 'carrot' },
          { label: 'Banana cake', value: 'banana' }
            ]}
            />
      <HorizontalSpace/>
      <SelectMultiple
        label='Choose your cakes'
        description='Menu will always show all options'
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        options={[
          { label: 'Chocolate cake', value: 'chocolate' },
          { label: 'Cheesecake', value: 'cheese' },
          { label: 'Carrot cake', value: 'carrot' },
          { label: 'Banana cake', value: 'banana' }
            ]}/>
        </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <SelectMultiple
            label='Choose your cakes'
            description='Menu will only show unselected options'
            closeMenuOnSelect={false}
            hideSelectedOptions
            options={[
              { label: 'Chocolate cake', value: 'chocolate' },
              { label: 'Cheesecake', value: 'cheese' },
              { label: 'Carrot cake', value: 'carrot' },
              { label: 'Banana cake', value: 'banana' }
            ]}
          />
          <HorizontalSpace />
          <SelectMultiple
            label='Choose your cakes'
            description='Menu will always show all options'
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            options={[
              { label: 'Chocolate cake', value: 'chocolate' },
              { label: 'Cheesecake', value: 'cheese' },
              { label: 'Carrot cake', value: 'carrot' },
              { label: 'Banana cake', value: 'banana' }
            ]}
          />
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-seletedontop' level='2' spacing>
        Selected on top
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        the prop <code>selectedOnTop</code> moves the selected elements from the options menu to the top. This
        prop must be used together with the <code>hideSelectedOptions</code> prop set to <code>false</code>,
        so the selected elements are visible.
      </BodyLong>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { SelectMultiple } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexDiv>
      <SelectMultiple
        label='Selected to top'
        description='Sends selected items to top of the menu'
        hideSelectedOptions={false}
        selectedToTop={true}
        options={[
          { label: 'Chocolate cake', value: 'chocolate' },
          { label: 'Cheesecake', value: 'cheese' },
          { label: 'Carrot cake', value: 'carrot' },
          { label: 'Banana cake', value: 'banana' }
            ]}
            />
        </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <SelectMultiple
            label='Selected to top'
            description='Sends selected items to top of the menu'
            hideSelectedOptions={false}
            selectedToTop
            options={[
              { label: 'Chocolate cake', value: 'chocolate' },
              { label: 'Cheesecake', value: 'cheese' },
              { label: 'Carrot cake', value: 'carrot' },
              { label: 'Banana cake', value: 'banana' }
            ]}
          />
        </FlexDiv>
      </DemoableDiv>

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>components</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>any</code>
              </Table.DataCell>
              <Table.DataCell>Passes custom rendering components</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>{}</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>creatable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Allows creation of new select options</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>clearable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Shows a cross button to clear the values</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>closeMenuOnSelect</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Closes dropdown menu after selecting options</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>description</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Set select description</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Disables the select</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets error styles</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>fullWidth</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets full width</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides select label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideSelectedOptions</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hide selected options from dropdown menu</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>label</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets select label</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>loading</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Show loading animation</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>menuPortalTarget</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>HTMLElement</code>
              </Table.DataCell>
              <Table.DataCell>Anchor element to attach the dropdown menu</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                component's <code>div</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>multiple</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets single/multiple select</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>options</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>
                  Array&lt;{'{'}label, value{'}'}&gt;
                </code>
              </Table.DataCell>
              <Table.DataCell>Set select options</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>
                  Array&lt;{'{'}label, value{'}'}&gt;
                </code>
              </Table.DataCell>
              <Table.DataCell>Set select options</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>searchable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Options can be searched</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>selectedToTop</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sends selected values to top</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets select size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>values</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>any</code>
              </Table.DataCell>
              <Table.DataCell>Sets select values</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsSelectMultiple
