import {
  AlignCenterColumn,
  BodyLong,
  Button,
  Column,
  FlexDiv,
  Heading,
  Label,
  Link,
  NavBar,
  PileDiv,
  Row,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import { Cake, Fastfood, FoodBank, RiceBowl } from '@cegal/ds-icons'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'
import reactElementToJSXString from 'react-element-to-jsx-string'

const SampleNavBar = ({ size, variant, orientation, underscore, collapsed, icon }: any) => (
  <NavBar
    size={size}
    variant={variant}
    orientation={orientation}
    underscore={underscore}
    collapsed={collapsed}
  >
    <NavBar.Link icon={icon ? <RiceBowl size='1.5rem' /> : undefined}>Soups</NavBar.Link>
    <NavBar.Link icon={icon ? <FoodBank size='1.5rem' /> : undefined}>Starters</NavBar.Link>
    <NavBar.Link icon={icon ? <Fastfood size='1.5rem' /> : undefined}>Main courses</NavBar.Link>
    <NavBar.Link icon={icon ? <Cake size='1.5rem' /> : undefined}>Desserts</NavBar.Link>
  </NavBar>
)

const ComponentsNavBar = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='navbar' level='1' spacing>
        Navigation Bar
      </Heading>
      <VerticalSpace />
      <BodyLong>
        The <code>NavBar</code> component is a tab-like navigation menu that can be placed horizontally on a
        header, or vertically on a page sidebar.
      </BodyLong>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='navbar-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The default component renders with an horizontal orientation, primary variant and no underscore
        pointer.
      </BodyLong>
      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { NavBar } from '@cegal/ds-components'

const Component = () => {
  return (
  ${reactElementToJSXString(SampleNavBar({}), {
    showDefaultProps: false,
    filterProps: ['size', 'variant', 'orientation', 'collapsed', 'icon', 'underscore']
  })}
  )
}`}
      >
        <SampleNavBar />
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='navbar-underscore' level='2' spacing>
        Underscore pointer
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>orientation</code> prop helps to gives a visual indicator of nav bar interactions.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { NavBar } from '@cegal/ds-components'

const Component = () => {
  return (
  ${reactElementToJSXString(
    SampleNavBar({
      underscore: true
    }),
    {
      showDefaultProps: false,
      filterProps: ['size', 'variant', 'collapsed', 'icon', 'orientation']
    }
  )}
  )
}`}
      >
        <SampleNavBar underscore />
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='navbar-orientation' level='2' spacing>
        Orientation
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>The navigation bar orientation prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type NavBarOrientation = 'horizontal' | 'vertical'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>NavBarOrientation</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { NavBar } from '@cegal/ds-components'

const Component = () => {
  return (
  ${reactElementToJSXString(
    SampleNavBar({
      orientation: 'vertical'
    }),
    {
      showDefaultProps: false,
      filterProps: ['size', 'variant', 'collapsed', 'icon', 'underscore']
    }
  )}
  )
}`}
      >
        <SampleNavBar orientation='vertical' />
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='navbar-collapse' level='2' spacing>
        Collapse
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>collapse</code> prop hides the navbar text. You should use it with the help of icons, and it
        is particularly helpful in vertical orientation, to achieve a collapsed sidebar that maximizes
        workspace{' '}
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { NavBar } from '@cegal/ds-components'

const Component = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  return (
    <PileDiv>
      <FlexDiv>
        <Button 
          size='small'
          onClick={() => setCollapsed(!collapsed)}
        >
          Toggle collapse
        </Button>
      </FlexDiv>
     <VerticalSpace/>

    ${reactElementToJSXString(
      SampleNavBar({
        underscore: true,
        orientation: 'vertical',
        icon: true,
        collapsed
      }),
      {
        showDefaultProps: false,
        filterProps: ['size', 'variant']
      }
    )}
    </PileDiv>
  )
}`}
      >
        <PileDiv>
          <FlexDiv>
            <Button size='small' onClick={() => setCollapsed(!collapsed)}>
              Toggle collapse
            </Button>
          </FlexDiv>
          <VerticalSpace />
          <SampleNavBar underscore orientation='vertical' icon collapsed={collapsed} />
        </PileDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='navbar-size' level='2' spacing>
        Size
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>The navigation bar size prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type NavBarSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>NavBarSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { NavBar } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <Label>Horizontal</Label>
      <Row>
        <Column>
          <Label>Medium</Label>
        </Column>
        <Column>
           ${reactElementToJSXString(
             SampleNavBar({
               size: 'medium',
               underscore: true,
               icon: true
             }),
             {
               showDefaultProps: false,
               filterProps: ['variant', 'collapsed', 'orientation']
             }
           )}
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Small</Label>
        </Column>
        <Column>
         ${reactElementToJSXString(
           SampleNavBar({
             size: 'small',
             underscore: true,
             icon: true
           }),
           {
             showDefaultProps: false,
             filterProps: ['variant', 'collapsed', 'orientation']
           }
         )}
       </Column>
      </Row>
      <VerticalSpace/>
      <Label>Vertical</Label>
      <VerticalSpace/>
      <Row>
        <AlignCenterColumn>
          <Label>Medium</Label>
        </AlignCenterColumn>
        <AlignCenterColumn>
          <Label>Small</Label>
        </AlignCenterColumn>
      </Row>
      <Row>
        <Column>
           ${reactElementToJSXString(
             SampleNavBar({
               orientation: 'vertical',
               size: 'medium',
               underscore: true,
               icon: true
             }),
             {
               showDefaultProps: false,
               filterProps: ['variant', 'collapsed']
             }
           )}
        </Column>
        <Column>
          ${reactElementToJSXString(
            SampleNavBar({
              orientation: 'vertical',
              size: 'small',
              underscore: true,
              icon: true
            }),
            {
              showDefaultProps: false,
              filterProps: ['variant', 'collapsed']
            }
          )}
        </Column>
      </Row>
    </>
}`}
      >
        <Label>Horizontal</Label>
        <Row>
          <Column>
            <Label>Medium</Label>
          </Column>
          <Column>
            <SampleNavBar size='medium' underscore icon />
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>Small</Label>
          </Column>
          <Column>
            <SampleNavBar size='small' underscore icon />
          </Column>
        </Row>
        <VerticalSpace />
        <Label>Vertical</Label>
        <VerticalSpace />
        <Row>
          <AlignCenterColumn>
            <Label>Medium</Label>
          </AlignCenterColumn>
          <AlignCenterColumn>
            <Label>Small</Label>
          </AlignCenterColumn>
        </Row>
        <Row>
          <AlignCenterColumn>
            <SampleNavBar orientation='vertical' size='medium' underscore icon />
          </AlignCenterColumn>
          <Column>
            <SampleNavBar orientation='vertical' size='small' underscore icon />
          </Column>
        </Row>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='navbar-variant' level='2' spacing>
        Variant
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>The navigation bar variant prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type NavBarVariant = 'primary' | 'secondary'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>NavBarVariant</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { NavBar } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <Label>Horizontal</Label>
      <Row>
        <Column>
          <Label>Primary</Label>
        </Column>
        <Column>
           ${reactElementToJSXString(
             SampleNavBar({
               variant: 'primary',
               underscore: true,
               icon: true
             }),
             {
               showDefaultProps: false,
               filterProps: ['size', 'collapsed', 'orientation']
             }
           )}
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Secondary</Label>
        </Column>
        <Column>
         ${reactElementToJSXString(
           SampleNavBar({
             variant: 'secondary',
             underscore: true,
             icon: true
           }),
           {
             showDefaultProps: false,
             filterProps: ['size', 'collapsed', 'orientation']
           }
         )}
       </Column>
      </Row>
      <VerticalSpace/>
      <Label>Vertical</Label>
      <VerticalSpace/>
      <Row>
        <AlignCenterColumn>
          <Label>Primary</Label>
        </AlignCenterColumn>
        <AlignCenterColumn>
          <Label>Secondary</Label>
        </AlignCenterColumn>
      </Row>
      <Row>
        <Column>
           ${reactElementToJSXString(
             SampleNavBar({
               orientation: 'vertical',
               variant: 'primary',
               underscore: true,
               icon: true
             }),
             {
               showDefaultProps: false,
               filterProps: ['size', 'collapsed']
             }
           )}
        </Column>
        <Column>
          ${reactElementToJSXString(
            SampleNavBar({
              orientation: 'vertical',
              variant: 'secondary',
              underscore: true,
              icon: true
            }),
            {
              showDefaultProps: false,
              filterProps: ['size', 'collapsed']
            }
          )}
        </Column>
      </Row>
    </>
}`}
      >
        <Label>Horizontal</Label>
        <Row>
          <Column>
            <Label>Primary</Label>
          </Column>
          <Column>
            <SampleNavBar underscore icon />
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>Secondary</Label>
          </Column>
          <Column>
            <SampleNavBar variant='secondary' underscore icon />
          </Column>
        </Row>
        <VerticalSpace />
        <Label>Vertical</Label>
        <VerticalSpace />
        <Row>
          <AlignCenterColumn>
            <Label>Primary</Label>
          </AlignCenterColumn>
          <AlignCenterColumn>
            <Label>Secondary</Label>
          </AlignCenterColumn>
        </Row>
        <Row>
          <AlignCenterColumn>
            <SampleNavBar orientation='vertical' underscore icon />
          </AlignCenterColumn>
          <Column>
            <SampleNavBar orientation='vertical' variant='secondary' underscore icon />
          </Column>
        </Row>
      </DemoableDiv>

      <VerticalSpace />
      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='NavBar-properties' level='3' spacing>
        NavBar props
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>NavBarProps</code> interface extends <code>React.HTMLAttributes&lt;HTMLElement&gt;</code>,
        as the top level uses a <code>nav</code> tag. As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>collapsed</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Show nav bar elements collapsed, with icon only</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>defaultValue</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Sets default selected bar link (index)</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(value: number) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback function when link is clicked</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>orientation</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'horizontal' | 'vertical'</code>
              </Table.DataCell>
              <Table.DataCell>Sets different bar orientations</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>horizontal</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets different bar sizes</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>underscore</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Shows nav bar underscore pointer</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Sets selected bar link (index)</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'primary' | 'secondary'</code>
              </Table.DataCell>
              <Table.DataCell>Sets different bar variant styles</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>primary</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='NavBar-Link-properties' level='3' spacing>
        NavBar.Link props
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>LinkProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLAnchorElement&gt;</code>, as the top level uses a <code>a</code>{' '}
        tag. As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement'>
          HTMLAnchorElement
        </Link>{' '}
        props and <Link href='https://developer.mozilla.org/en-US/docs/Web/API/UIEvent'>UI events</Link> such
        as <code>onClick</code>.
      </BodyLong>
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>icon</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>React.ReactNode</code>
              </Table.DataCell>
              <Table.DataCell>Add icon element on the left</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsNavBar
