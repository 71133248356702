import { BodyLong, Heading, VerticalSpace } from '@cegal/ds-components'
import CegalIllustrationLevel1Earth from '@cegal/ds-logos/illustrations/Resources_Level_1_Placeholder_Earth.jpg'
import CegalIllustrationLevel1Solar from '@cegal/ds-logos/illustrations/Resources_Level_1_Placeholder_Solar.jpg'
import CegalIllustrationLevel1Water from '@cegal/ds-logos/illustrations/Resources_Level_1_Placeholder_Water.jpg'
import CegalIllustrationLevel1Wind from '@cegal/ds-logos/illustrations/Resources_Level_1_Placeholder_Wind.jpg'
import CegalIllustrationLevel2Earth from '@cegal/ds-logos/illustrations/Resources_Level_2_Placeholder_Earth.jpg'
import CegalIllustrationLevel2Solar from '@cegal/ds-logos/illustrations/Resources_Level_2_Placeholder_Solar.jpg'
import CegalIllustrationLevel2Water from '@cegal/ds-logos/illustrations/Resources_Level_2_Placeholder_Water.jpg'
import CegalIllustrationLevel2Wind from '@cegal/ds-logos/illustrations/Resources_Level_2_Placeholder_Wind.jpg'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import React from 'react'

const ResourcesIllustrations = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' spacing>
        Illustrations
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        The <code>@cegal/ds-logos</code> package contains all illustrations from the Cegal brandpad identity,
        in JPEG format.
      </BodyLong>

      <VerticalSpace />
      <Heading className='toc' size='medium' level='2' id='Resources_Level_1_Placeholder_Earth' spacing>
        Level 1 Earth
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import CegalIllustrationLevel1Earth from '@cegal/ds-logos/illustrations/Resources_Level_1_Placeholder_Earth.jpg'
        
const Image = () => (<img width='500' src={CegalIllustrationLevel1Earth}/>)
`}
      >
        <img alt='logo' width='500' src={CegalIllustrationLevel1Earth} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='Resources_Level_1_Placeholder_Solar' spacing>
        Level 1 Solar
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import CegalIllustrationLevel1Solar from '@cegal/ds-logos/illustrations/Resources_Level_1_Placeholder_Solar.jpg'
          
const Image = () => (<img width='500' src={CegalIllustrationLevel1Solar}/>)
`}
      >
        <img alt='logo' width='500' src={CegalIllustrationLevel1Solar} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='Resources_Level_1_Placeholder_Water' spacing>
        Level 1 Water
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import CegalIllustrationLevel1Water from '@cegal/ds-logos/illustrations/Resources_Level_1_Placeholder_Water.jpg'
          
const Image = () => (<img width='500' src={CegalIllustrationLevel1Water}/>)
`}
      >
        <img alt='logo' width='500' src={CegalIllustrationLevel1Water} />
      </DemoableDiv>

      <Heading className='toc' size='medium' level='2' id='Resources_Level_1_Placeholder_Wind' spacing>
        Level 1 Wind
      </Heading>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import CegalIllustrationLevel1Wind from '@cegal/ds-logos/illustrations/Resources_Level_1_Placeholder_Wind.jpg'
      
const Image = () => (<img width='500' src={CegalIllustrationLevel1Wind}/>)
`}
      >
        <img alt='logo' width='500' src={CegalIllustrationLevel1Wind} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='Resources_Level_2_Placeholder_Earth' spacing>
        Level 2 Earth
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import CegalIllustrationLevel2Earth from '@cegal/ds-logos/illustrations/Resources_Level_2_Placeholder_Earth.jpg'
      
const Image = () => (<img width='500' src={CegalIllustrationLevel2Earth}/>)
`}
      >
        <img alt='logo' width='500' src={CegalIllustrationLevel2Earth} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='Resources_Level_2_Placeholder_Solar' spacing>
        Level 2 Solar
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import CegalIllustrationLevel2Solar from '@cegal/ds-logos/illustrations/Resources_Level_2_Placeholder_Solar.jpg'
                      
const Image = () => (<img width='500' src={CegalIllustrationLevel2Solar}/>)
`}
      >
        <img alt='logo' width='500' src={CegalIllustrationLevel2Solar} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='Resources_Level_2_Placeholder_Water' spacing>
        Level 2 Water
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import CegalIllustrationLevel2Water from '@cegal/ds-logos/illustrations/Resources_Level_2_Placeholder_Water.jpg'
                  
const Image = () => (<img width='500' src={CegalIllustrationLevel2Water}/>)
`}
      >
        <img alt='logo' width='500' src={CegalIllustrationLevel2Water} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='Resources_Level_2_Placeholder_Wind' spacing>
        Level 2 Wind
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import CegalIllustrationLevel2Wind from '@cegal/ds-logos/illustrations/Resources_Level_2_Placeholder_Wind.jpg'
                  
const Image = () => (<img width='500' src={CegalIllustrationLevel2Wind}/>)
`}
      >
        <img alt='logo' width='500' src={CegalIllustrationLevel2Wind} />
      </DemoableDiv>
    </>
  )
}

export default ResourcesIllustrations
