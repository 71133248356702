import { Heading, BodyLong, Link, LinkPanel, Table, VerticalSpace } from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React from 'react'

const ComponentsLinkPanel = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='linkpanel' level='1' spacing>
        Link panel
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>LinkPanel</code> component provides a bigger anchor area for the user to select a link. A
        title and better a description can do a better job in explaining the link panel navigation.
      </BodyLong>

      <BodyLong spacing>
        The link panel provide two other components, <code>Title</code> and <code>Description</code>.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='linkpanel-default' level='2' spacing>
        Default component
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The default <code>LinkPanel</code> component is set to primary variant, medium size.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { LinkPanel } from '@cegal/ds-components'

const Component = () => {
  return (
   <LinkPanel href='#'>
     <LinkPanel.Title>
       Search recipes
     </LinkPanel.Title>
     <LinkPanel.Description>
       Find your dinner among 1342 recipes 
     </LinkPanel.Description>
   </LinkPanel>
  )
}`}
      >
        <LinkPanel href='#'>
          <LinkPanel.Title>Search recipes</LinkPanel.Title>
          <LinkPanel.Description>Find your dinner among 1342 recipes</LinkPanel.Description>
        </LinkPanel>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='linkpanel-variants' level='2' spacing>
        Variants
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>variant</code> prop can be one of the following 3 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type LinkPanelVariant = 'primary' | 'secondary' | 'tertiary'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>LinkPanelVariant</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='linkpanel-variants-primary' level='3' spacing>
        Primary variant
      </Heading>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { LinkPanel } from '@cegal/ds-components'

const Component = () => {
  return (
   <LinkPanel variant='primary' href='#'>
     <LinkPanel.Title>
       Search recipes for beginners
     </LinkPanel.Title>
     <LinkPanel.Description>
       Recipes that take less than 15 minutes
     </LinkPanel.Description>
   </LinkPanel>
  )
}`}
      >
        <LinkPanel variant='primary' href='#'>
          <LinkPanel.Title>Search recipes for beginners</LinkPanel.Title>
          <LinkPanel.Description>Recipes that take less than 15 minutes</LinkPanel.Description>
        </LinkPanel>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='linkpanel-variants-secondary' level='3' spacing>
        Secondary variant
      </Heading>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { LinkPanel } from '@cegal/ds-components'

const Component = () => {
  return (
   <LinkPanel variant='secondary' href='#'>
     <LinkPanel.Title>
       Search vegan recipes
     </LinkPanel.Title>
     <LinkPanel.Description>
       All the taste, without meat nor dairy products
     </LinkPanel.Description>
   </LinkPanel>
  )
}`}
      >
        <LinkPanel variant='secondary' href='#'>
          <LinkPanel.Title>Search vegan recipes</LinkPanel.Title>
          <LinkPanel.Description>All the taste, without meat nor dairy products</LinkPanel.Description>
        </LinkPanel>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='linkpanel-tertiary' level='3' spacing>
        Tertiary variant
      </Heading>
      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { LinkPanel } from '@cegal/ds-components'

const Component = () => {
  return (
   <LinkPanel variant='tertiary' href='#'>
     <LinkPanel.Title>
        Search cake recipes
     </LinkPanel.Title>
     <LinkPanel.Description>
       Your meal is not complete without a sweet treat
     </LinkPanel.Description>
   </LinkPanel>
  )
}`}
      >
        <LinkPanel variant='tertiary' href='#'>
          <LinkPanel.Title>Search cake recipes</LinkPanel.Title>
          <LinkPanel.Description>Your meal is not complete without a sweet treat</LinkPanel.Description>
        </LinkPanel>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>LinkPanelProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLAnchorElement&gt;</code>, as the top level uses a <code>a</code>{' '}
        tag. As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement'>
          HTMLAnchorElement
        </Link>{' '}
        props and <Link href='https://developer.mozilla.org/en-US/docs/Web/API/UIEvent'>UI events</Link> such
        as <code>onClick</code>.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds a margin bottom</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'primary' | 'secondary' | 'tertiary'</code>
              </Table.DataCell>
              <Table.DataCell>Sets link panel variant</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>primary</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsLinkPanel
