import { BodyLong, Checkbox, Heading, Select, Table, Tag, VerticalSpace } from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsTable = () => {
  const [size, setSize] = useState('medium')
  const [sort, setSort] = useState<any>()
  const [selectedRows, setSelectedRows] = React.useState<any[]>([])

  const toggleSelectedRow = (value: any) => {
    setSelectedRows(
      selectedRows.includes(value) ? selectedRows.filter((id) => id !== value) : [...selectedRows, value]
    )
  }

  const handleSort = (sortKey: string | undefined) => {
    setSort(
      sort && sortKey === sort.orderBy && sort.direction === 'descending'
        ? undefined
        : {
            orderBy: sortKey,
            direction:
              sort && sortKey === sort.orderBy && sort.direction === 'ascending' ? 'descending' : 'ascending'
          }
    )
  }

  const data: any = [
    { recipe: 'Chocolate cake', difficulty: 'Medium', time: 30 },
    { recipe: 'Cheesecake', difficulty: 'Expert', time: 40 },
    { recipe: 'Carrot cake', difficulty: 'Medium', time: 30 },
    { recipe: 'Banana cake', difficulty: 'Beginner', time: 20 }
  ]

  const sortData = data.slice().sort((a: any, b: any) => {
    if (sort) {
      const comparator = (a: any, b: any, orderBy: any) => {
        if (orderBy === 'time') {
          return a.time < b.time ? -1 : 1
        }
        if (b[orderBy] < a[orderBy] || b[orderBy] === undefined) {
          return -1
        }
        if (b[orderBy] > a[orderBy]) {
          return 1
        }
        return 0
      }

      return sort.direction === 'ascending' ? comparator(b, a, sort.orderBy) : comparator(a, b, sort.orderBy)
    }
    return 1
  })

  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='table' level='1' spacing>
        Table
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        The <code>Table</code> component has active headers for sorting, expandable rows, and zebra stripes.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading classRecipe='toc' size='medium' id='table-default' level='2' spacing>
        Default component
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        This is how a basic <code>Table</code> component and sub-components should be used.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Table } from '@cegal/ds-components'

const Component = () => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeader scope='col'>
            Recipe
          </Table.ColumnHeader>
          <Table.ColumnHeader scope='col'>
            Difficulty
          </Table.ColumnHeader>
          <Table.ColumnHeader scope='col'>
            Time
          </Table.ColumnHeader>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(({ recipe, difficulty, time }) => (
          <Table.Row key={recipe}>
            <Table.HeaderCell scope='row'>
              {recipe}
            </Table.HeaderCell>
            <Table.DataCell>
              <Tag variant={
                difficulty === 'Expert' ? 'error' : 
                difficulty === 'Medium' ? 'warning' : 
                'success'}
               >{difficulty}
              </Tag>
            </Table.DataCell>
            <Table.DataCell>
              {time} minutes
            </Table.DataCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}`}
      >
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader scope='col'>Recipe</Table.ColumnHeader>
              <Table.ColumnHeader scope='col'>Difficulty</Table.ColumnHeader>
              <Table.ColumnHeader scope='col'>Time</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(({ recipe, difficulty, time }: any) => (
              <Table.Row key={recipe}>
                <Table.HeaderCell scope='row'>{recipe}</Table.HeaderCell>
                <Table.DataCell>
                  <Tag
                    variant={
                      difficulty === 'Expert' ? 'error' : difficulty === 'Medium' ? 'warning' : 'success'
                    }
                  >
                    {difficulty}
                  </Tag>
                </Table.DataCell>
                <Table.DataCell> {time} minutes </Table.DataCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading classRecipe='toc' size='medium' id='table-size' level='2' spacing>
        Size
      </Heading>

      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Table } from '@cegal/ds-components'

const Component = () => {
  const [size, setSize] = useState('medium')  
  return (
    <>  
    <Select value={size} onChange={(e) => setSize(e.target.value)}>
      <option>medium</option>
      <option>small</option>
    </Select>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeader scope='col'>
            Recipe
          </Table.ColumnHeader>
          <Table.ColumnHeader scope='col'>
            Difficulty
          </Table.ColumnHeader>
          <Table.ColumnHeader scope='col'>
            Time
          </Table.ColumnHeader>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(({ recipe, difficulty, time }) => (
          <Table.Row key={recipe}>
            <Table.HeaderCell scope='row'>
              {recipe}
            </Table.HeaderCell>
            <Table.DataCell>
              <Tag variant={
                difficulty === 'Expert' ? 'error' : 
                difficulty === 'Medium' ? 'warning' : 
                'success'}
               >{difficulty}
              </Tag>
            </Table.DataCell>
            <Table.DataCell>
              {time} minutes
            </Table.DataCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
    </>
  )
}`}
      >
        <Select label='size' hideLabel value={size} onChange={(e: any) => setSize(e.target.value)}>
          <option>medium</option>
          <option>small</option>
        </Select>
        <Table size={size}>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader scope='col'>Recipe</Table.ColumnHeader>
              <Table.ColumnHeader scope='col'>Difficulty</Table.ColumnHeader>
              <Table.ColumnHeader scope='col'>Time</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(({ recipe, difficulty, time }: any) => (
              <Table.Row key={recipe}>
                <Table.HeaderCell scope='row'>{recipe}</Table.HeaderCell>
                <Table.DataCell>
                  <Tag
                    variant={
                      difficulty === 'Expert' ? 'error' : difficulty === 'Medium' ? 'warning' : 'success'
                    }
                  >
                    {difficulty}
                  </Tag>
                </Table.DataCell>
                <Table.DataCell> {time} minutes </Table.DataCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading classRecipe='toc' size='medium' id='table-zebra' level='2' spacing>
        Zebra stripes
      </Heading>

      <BodyLong spacing>
        Set the <code>zebraStripes</code> prop to true, to have zebra stripes on odd rows.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Table } from '@cegal/ds-components'

const Component = () => {
  return (
    <Table zebraStripes>
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeader scope='col'>
            Recipe
          </Table.ColumnHeader>
          <Table.ColumnHeader scope='col'>
            Difficulty
          </Table.ColumnHeader>
          <Table.ColumnHeader scope='col'>
            Time
          </Table.ColumnHeader>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(({ recipe, difficulty, time }) => (
          <Table.Row key={recipe}>
            <Table.HeaderCell scope='row'>
              {recipe}
            </Table.HeaderCell>
            <Table.DataCell>
              <Tag variant={
                difficulty === 'Expert' ? 'error' : 
                difficulty === 'Medium' ? 'warning' : 
                'success'}
               >{difficulty}
              </Tag>
            </Table.DataCell>
            <Table.DataCell>
              {time} minutes
            </Table.DataCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}`}
      >
        <Table zebraStripes>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader scope='col'>Recipe</Table.ColumnHeader>
              <Table.ColumnHeader scope='col'>Difficulty</Table.ColumnHeader>
              <Table.ColumnHeader scope='col'>Time</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(({ recipe, difficulty, time }: any) => (
              <Table.Row key={recipe}>
                <Table.HeaderCell scope='row'>{recipe}</Table.HeaderCell>
                <Table.DataCell>
                  <Tag
                    variant={
                      difficulty === 'Expert' ? 'error' : difficulty === 'Medium' ? 'warning' : 'success'
                    }
                  >
                    {difficulty}
                  </Tag>
                </Table.DataCell>
                <Table.DataCell> {time} minutes </Table.DataCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading classRecipe='toc' size='medium' id='table-sort' level='2' spacing>
        Sort
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        Set the <code>sort</code> prop and <code>onSortChange</code> to handle the sort requests. You also
        have to add the <code>sortable</code> prop to the column headers you want to allow to be sorted by.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Table } from '@cegal/ds-components'

const Component = () => {
  const [sort, setSort] = useState<any>()  
  const handleSort = (sortKey: string) => 
    setSort(
      sort && sortKey === sort.orderBy &&
      sort.direction === "descending"
        ? undefined
        : {
          orderBy: sortKey,
          direction:  sort && 
            sortKey === sort.orderBy &&
            sort.direction === 'ascending'
              ? 'descending'
              : 'ascending'
        }
    )
 
  let sortData = data.slice().sort((a, b) => {
    if (sort) {
      const comparator = (a: any, b: any, orderBy: any) => {
         if (orderBy === 'time') {
          return a.time < b.time ? -1 : 1
        }
        if (b[orderBy] < a[orderBy] || b[orderBy] === undefined) {
          return -1
        }
        if (b[orderBy] > a[orderBy]) {
          return 1
        }
        return 0
      }

      return sort.direction === "ascending"
        ? comparator(b, a, sort.orderBy)
        : comparator(a, b, sort.orderBy)
    }
    return 1
  })

  return (
      <Table 
        sort={sort}
        onSortChange={handleSort}
      >
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeader sortable sortKey='recipe'>
            Recipe
          </Table.ColumnHeader>
          <Table.ColumnHeader scope='col'>
            Difficulty
          </Table.ColumnHeader>
          <Table.ColumnHeader sortable sortKey='time'>
            Time
          </Table.ColumnHeader>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortData.map(({ recipe, difficulty, time }) => (
          <Table.Row key={recipe}>
            <Table.HeaderCell scope='row'>
              {recipe}
            </Table.HeaderCell>
            <Table.DataCell>
              <Tag variant={
                difficulty === 'Expert' ? 'error' : 
                difficulty === 'Medium' ? 'warning' : 
                'success'}
               >{difficulty}
              </Tag>
            </Table.DataCell>
            <Table.DataCell>
              {time} minutes
            </Table.DataCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
    
  )
}`}
      >
        <Table sort={sort} onSortChange={handleSort}>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader sortable sortKey='recipe'>
                Recipe
              </Table.ColumnHeader>
              <Table.ColumnHeader>Difficulty</Table.ColumnHeader>
              <Table.ColumnHeader sortable sortKey='time'>
                Time
              </Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortData.map(({ recipe, difficulty, time }: any) => (
              <Table.Row key={recipe}>
                <Table.HeaderCell scope='row'>{recipe}</Table.HeaderCell>
                <Table.DataCell>
                  <Tag
                    variant={
                      difficulty === 'Expert' ? 'error' : difficulty === 'Medium' ? 'warning' : 'success'
                    }
                  >
                    {difficulty}
                  </Tag>
                </Table.DataCell>
                <Table.DataCell>{time} minutes</Table.DataCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading classRecipe='toc' size='medium' id='table-selectable' level='2' spacing>
        Selectable
      </Heading>

      <BodyLong spacing>
        You can have selectable rows, and control the table to get/set the changes being made.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Table } from '@cegal/ds-components'

const Component = () => {
  const [selectedRows, setSelectedRows] = React.useState([])

  const toggleSelectedRow = (value) =>
    setSelectedRows((list) =>
      list.includes(value)
        ? list.filter((id) => id !== value)
        : [...list, value]
    )

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.DataCell>
            <Checkbox
              size="medium"
              checked={
                selectedRows.length === data.length
              }
              indeterminate={
                selectedRows.length &&
                selectedRows.length !== data.length
              }
              onChange={() => {
                selectedRows.length
                  ? setSelectedRows([])
                  : setSelectedRows(
                    data.map(({ difficulty }) => difficulty)
                  )
              }}
              hideLabel
            >
              {""}
            </Checkbox>
          </Table.DataCell>
          <Table.HeaderCell scope="col">
            Recipe
          </Table.HeaderCell>
          <Table.HeaderCell scope="col">
            Difficulty
          </Table.HeaderCell>
          <Table.HeaderCell scope="col">
            Time
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(({ recipe, difficulty, time }) => {
          return (
            <Table.Row
              key={recipe}
              selected={selectedRows.includes(difficulty)}
            >
              <Table.DataCell>
                <Checkbox
                  size={props?.size}
                  hideLabel
                  checked={selectedRows.includes(difficulty)}
                  onChange={() => {
                    toggleSelectedRow(recipe)
                  }}
                  aria-labelledby="id{difficulty}"
                >
                  {" "}
                </Checkbox>
              </Table.DataCell>
             
                <Table.HeaderCell scope='row'>
                  {recipe}
                </Table.HeaderCell>
                <Table.DataCell>
                  <Tag variant={
                    difficulty === 'Expert' ? 'error' :
                      difficulty === 'Medium' ? 'warning' :
                        'success'}
                  >{difficulty}
                  </Tag>
                </Table.DataCell>
                <Table.DataCell>
                  {time} minutes
                </Table.DataCell>
              </Table.Row>
        
          )
        })}
      </Table.Body>
    </Table>
  )
}`}
      >
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.DataCell>
                <Checkbox
                  size='medium'
                  checked={selectedRows.length === data.length}
                  indeterminate={selectedRows.length && selectedRows.length !== data.length}
                  onChange={() => {
                    selectedRows.length
                      ? setSelectedRows([])
                      : setSelectedRows(data.map(({ difficulty }: any) => difficulty))
                  }}
                  hideLabel
                />
              </Table.DataCell>
              <Table.HeaderCell scope='col'>Recipe</Table.HeaderCell>
              <Table.HeaderCell scope='col'>Difficulty</Table.HeaderCell>
              <Table.HeaderCell scope='col'>Time</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(({ recipe, difficulty, time }: any) => {
              return (
                <Table.Row key={recipe} selected={selectedRows.includes(recipe)}>
                  <Table.DataCell>
                    <Checkbox
                      hideLabel
                      checked={selectedRows.includes(recipe)}
                      onChange={() => {
                        toggleSelectedRow(recipe)
                      }}
                      aria-labelledby='id{recipe}'
                    >
                      {' '}
                    </Checkbox>
                  </Table.DataCell>

                  <Table.HeaderCell scope='row'>{recipe}</Table.HeaderCell>
                  <Table.DataCell>
                    <Tag
                      variant={
                        difficulty === 'Expert' ? 'error' : difficulty === 'Medium' ? 'warning' : 'success'
                      }
                    >
                      {difficulty}
                    </Tag>
                  </Table.DataCell>
                  <Table.DataCell>{time} minutes</Table.DataCell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading classRecipe='toc' size='medium' id='table-expandable' level='2' spacing>
        Expandable
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import { Table } from '@cegal/ds-components'

const Component = () => {

  return (
     <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell scope='col'>
              Recipe
            </Table.HeaderCell>
            <Table.HeaderCell scope='col'>
              Difficulty
            </Table.HeaderCell>
            <Table.HeaderCell scope='col'>
              Time
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map(({ recipe, difficulty, time }) => {
            return (
              <Table.ExpandableRow
                key={recipe}
                content='Content for the expandable row'
              >
                <Table.HeaderCell scope='row'>
                  {recipe}
                </Table.HeaderCell>
                <Table.DataCell>
                  <Tag variant={
                    difficulty === 'Expert' ? 'error' :
                      difficulty === 'Medium' ? 'warning' :
                        'success'}
                  >{difficulty}
                  </Tag>
                </Table.DataCell>
                <Table.DataCell>
                  {time} minutes
                </Table.DataCell>
              </Table.ExpandableRow>
            )
          })}
        </Table.Body>
      </Table>
  )
}`}
      >
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell scope='col'>Recipe</Table.HeaderCell>
              <Table.HeaderCell scope='col'>Difficulty</Table.HeaderCell>
              <Table.HeaderCell scope='col'>Time</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(({ recipe, difficulty, time }: any) => {
              return (
                <Table.ExpandableRow key={recipe} content='Content for the expandable row'>
                  <Table.HeaderCell scope='row'>{recipe}</Table.HeaderCell>
                  <Table.DataCell>
                    <Tag
                      variant={
                        difficulty === 'Expert' ? 'error' : difficulty === 'Medium' ? 'warning' : 'success'
                      }
                    >
                      {difficulty}
                    </Tag>
                  </Table.DataCell>
                  <Table.DataCell>{time} minutes</Table.DataCell>
                </Table.ExpandableRow>
              )
            })}
          </Table.Body>
        </Table>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading classRecipe='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace size='2' />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Recipe</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets table size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>zebraStripes</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets zebra stripes on odd rows</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>sort</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>SortState</code>
              </Table.DataCell>
              <Table.DataCell>Sets search sort</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsTable
