import {
  Heading,
  FlexDiv,
  HorizontalSpace,
  Textarea,
  VerticalSpace,
  Table,
  BodyLong
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsTextarea = () => {
  const [value, setValue] = useState<string>('')
  const [value2, setValue2] = useState<string>('')
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='textarea' level='1' spacing>
        Textarea
      </Heading>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='textarea-default' level='2' spacing>
        Default
      </Heading>
      <VerticalSpace size='2' />
      <DemoableDiv
        alignContent='center'
        code={`import { Textarea } from '@cegal/ds-components'

const Component = () => {
  return (
   <Textarea label='Feedback'/>
  )
}`}
      >
        <Textarea label='Feedback' />
      </DemoableDiv>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='textarea-controlled' level='2' spacing>
        Controlled
      </Heading>
      <VerticalSpace size='2' />
      <DemoableDiv
        alignContent='center'
        code={`import { Textarea } from '@cegal/ds-components'

const Component = () => {
  const [value, setValue] = useState<string>('')
  return (
  <Textarea
    label={'Feedback (' + value.length + 
    ' character' + (value.length === 1 ? '' : 's') + ')'}
    value={value}
    onChange={(e: any) => setValue(e.target.value)}
  />
  )
}`}
      >
        <Textarea
          label={'Feedback (' + value.length + ' character' + (value.length === 1 ? '' : 's') + ')'}
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
        />
      </DemoableDiv>

      <Heading className='toc' size='medium' id='textarea-size' level='2' spacing>
        Size
      </Heading>

      <VerticalSpace size='2' />

      <BodyLong spacing>The select size prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <FlexDiv>
       <Textarea label={'Medium'} size='medium'/>
       <HorizontalSpace/>
       <Textarea label={'Small'} size='small'/>
    </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <Textarea label='Medium' size='medium' />
          <HorizontalSpace />
          <Textarea label='Small' size='small' />
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='textarea-resizable' level='2' spacing>
        Resizable
      </Heading>

      <VerticalSpace size='2' />

      <BodyLong spacing>You can let the user to resize the text area so it fits better the content</BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
    <Textarea label='Resizable' resize/>
  )
}`}
      >
        <Textarea label='Resizable' resize />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='textarea-rows' level='2' spacing>
        Set rows
      </Heading>

      <VerticalSpace size='2' />

      <BodyLong spacing>
        You can set <code>rows</code> to set the text area height in rows
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
    <Textarea 
      label='Shows 10 rows, the rest is hidden'
      rows={10}
      value={[...Array(20).keys()].map((i: number) => 'Row ' + (i + 1)).join('\n')}
    />
  )
}`}
      >
        <Textarea
          label='Shows 10 rows, the rest is hidden'
          rows={10}
          value={[...Array(20).keys()].map((i: number) => 'Row ' + (i + 1)).join('\n')}
        />
      </DemoableDiv>

      <Heading className='toc' size='medium' id='textarea-max-length' level='2' spacing>
        Max text length
      </Heading>

      <VerticalSpace size='2' />

      <BodyLong spacing>
        You can set <code>maxLength</code> to let the user know when he wrote too much text. Note that it does
        not stop the user from exceeding the max length.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  const [value, setValue] = useState('')  
  return (
    <Textarea 
      label='Feedback, max 20 chars'
      maxLength={20}
      value={value}
      error={value.length > 20 ? 'You have too much text' : undefined}
      onChange={(e: any) => setValue(e.target.value)}
    />
  )
}`}
      >
        <Textarea
          label='Feedback, max 20 chars'
          maxLength={20}
          value={value2}
          error={value2.length > 20 ? 'You have too much text' : undefined}
          onChange={(e: any) => setValue2(e.target.value)}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='textarea-disable' level='2' spacing>
        Disable
      </Heading>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <Textarea label='Disabled' disabled/>
  )
}`}
      >
        <Textarea label='Disabled' disabled />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='textarea-error' level='2' spacing>
        Error
      </Heading>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <Textarea label='Error' error='You need to write something'/>
  )
}`}
      >
        <Textarea label='Error' error='You need to write something' />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='textarea-hide-label' level='2' spacing>
        Hide label
      </Heading>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <Textarea label='Hidden label' hideLabel/>
  )
}`}
      >
        <Textarea label='Hidden label' hideLabel />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='textarea-description' level='2' spacing>
        Description
      </Heading>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
    <Textarea label='Feedback' description='Your feedback is always welcome'/>
  )
}`}
      >
        <Textarea label='Feedback' description='Your feedback is always welcome' />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace size='2' />

      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets textarea error message</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides textarea label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Disables the textarea</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets textarea size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets textarea value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>description</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Set textarea description</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>maxLength</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Max text length</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>rows</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>initial row height</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>resize</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Lets the user resize</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsTextarea
