import {
  BodyLong,
  BodyShort,
  PileDiv,
  Link,
  RangeSlider,
  Heading,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsRangeSlider = () => {
  const [values, setValues] = useState<number[]>([0, 100])

  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='rangeSlider' level='1' spacing>
        Range slider
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>RangeSlider</code> component differs from the <code>Slider</code> component by having 2
        thumbs, and by returning a pair of values instead of a single value, for range selection.
      </BodyLong>

      <BodyLong spacing>
        The code is based on the{' '}
        <Link href='https://github.com/n3r4zzurr0/react-range-slider-input'>react-range-slider-input</Link>{' '}
        component, thus it inherits all its props.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='rangeslider-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You have to set the component on a parent container with a defined width, so it fills the space.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { RangeSlider } from '@cegal/ds-components'

const Component = () => {
  return (
   <div style={{width:'300px'}}>
      <RangeSlider />
    </div>
  )
}`}
      >
        <div style={{ width: '300px' }}>
          <RangeSlider />
        </div>
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='rangeslider-limiting' level='2' spacing>
        Controlling
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can control the element by setting the <code>value</code> and <code>onChange</code> props.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { RangeSlider } from '@cegal/ds-components'

const Component = () => {
  const [values, setValues] = useState<number[]>([0,100])
  return (
    <PileDiv style={{width: '300px'}}>
      <RangeSlider 
        value={values}
        label={'Choose values'}
        hideLabel
        onChange={setValues}
        min={0}
        max={100}
      />
      <VerticalSpace/>
      <BodyShort>
        {'Values: ' + values}
      </BodyShort>
    </PileDiv>
  )
}`}
      >
        <PileDiv style={{ width: '300px' }}>
          <RangeSlider
            value={values}
            label='Choose values'
            hideLabel
            onChange={setValues}
            min={0}
            max={100}
          />
          <VerticalSpace />
          <BodyShort>{'Values: ' + values}</BodyShort>
        </PileDiv>
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='rangeslider-orientation' level='2' spacing>
        Orientation
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can set the <code>orientation</code> prop to get a vertical slider.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { RangeSlider } from '@cegal/ds-components'

const Component = () => {
  return (
   <div>
      <RangeSlider
       orientation='vertical'
       style={{ height: '100px' }}
      />
    </div>
  )
}`}
      >
        <div>
          <RangeSlider orientation='vertical' style={{ height: '100px' }} />
        </div>
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <BodyLong>
        The <code>RangeSliderProps</code> component inherits props from the parent component{' '}
        <Link href='https://github.com/n3r4zzurr0/react-range-slider-input'>react-range-slider-input</Link>,
        all the properties from <code>FormField</code> such as <code>hideLabel</code>, <code>error</code> or{' '}
        <code>disabled</code>, native <code>HTMLInputElement</code> interface properties such as{' '}
        <code>value</code>, and <code>UIEvents</code> such as <code>onClick</code>.
      </BodyLong>

      <VerticalSpace size='2' />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>defaultValue</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>Array&lt;number&gt;</code>
              </Table.DataCell>
              <Table.DataCell>Sets default values</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>[25,75]</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disabled</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets disabled mode</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides rangeSlider label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>label</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets rangeSlider label</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>max</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Sets maximum value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>100</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>min</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Sets minimum value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>0</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(values: Array&lt;number&gt;) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback function with changed values</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onThumbDragStart</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>() =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>
                Function to be called when the pointerdown event is triggered for any of the thumbs.
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onThumbDragEnd</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>() =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>
                Function to be called when the pointerup event is triggered for any of the thumbs.
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>

            <Table.Row>
              <Table.DataCell>
                <code>onRangeDragStart</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>() =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>
                Function to be called when the pointerdown event is triggered for the range.
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onRangeDragEnd</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>() =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>
                Function to be called when the pointerup event is triggered for the range.
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>orientation</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'horizontal' | 'vertical'</code>
              </Table.DataCell>
              <Table.DataCell>
                String that specifies the axis along which the user interaction is to be registered. By
                default, the range slider element registers the user interaction along the X-axis. It takes
                two different values: horizontal and vertical.
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>'horizontal'</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>rangeSlideDisabled</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Boolean that specifies if the range is slidable or not.</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>step</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Sets step value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>1</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>thumbsDisabled</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>Array&lt;boolean&gt;</code>
              </Table.DataCell>
              <Table.DataCell>
                Array of two Booleans which specify if the lower and upper thumbs are disabled or not,
                respectively. If only one Boolean value is passed instead of an array, the value will apply to
                both thumbs.
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>[false, false]</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>Array&lt;number&gt;</code>
              </Table.DataCell>
              <Table.DataCell>Sets current values</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>[]</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsRangeSlider
