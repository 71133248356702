import { Heading, BodyLong, VerticalSpace, Search, Table } from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsSearch = () => {
  const [message, setMessage] = useState<string>('Controlled search')
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='search' level='1' spacing>
        Search
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>Search</code> component attaches a text field with a submit button, and some utilities like
        a clear button or a suggestion menu.
      </BodyLong>
      <VerticalSpace />
      <Heading className='toc' size='medium' id='search-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The default component has a secondary variant (applied to the button), and a hidden label.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Search } from '@cegal/ds-components'
    
    const Component = () => {
      return (
        <Search label='search'/>
      )
   )
  }`}
      >
        <Search label='search' />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='search-controlled' level='2' spacing>
        Controlled
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        You can pass <code>onClear</code>, <code>onChange</code> and <code>onSubmit</code> callback functions
        ot the Search component, to handle those events.
      </BodyLong>

      <BodyLong spacing>
        Note that the ESC key also triggers the <code>onClear</code> callback, and the ENTER key will trigger
        the <code>onSubmit</code> callback.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Search } from '@cegal/ds-components'
    
    const [message, setMessage] = useState<string>('Controlled search')
    const Component = () => {
      return (
       <Search
         hideLabel={false}
         label={message}
         onSubmit={(e: string) => {
           if (!!e) {
             setMessage('Submit: ' + e)
           }
         }}
         onClear={() => setMessage('Cleared')}
         onChange={(e: string) => {
           if (!!e) {
             setMessage('Change: ' + e)
           }
         }}
       />
      )
   )
  }`}
      >
        <Search
          hideLabel={false}
          label={message}
          onSubmit={(e: string) => {
            if (e) {
              setMessage('Submit: ' + e)
            }
          }}
          onClear={() => setMessage('Cleared')}
          onChange={(e: string) => {
            if (e) {
              setMessage('Change: ' + e)
            }
          }}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='search-variants' level='2' spacing>
        Variants
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>variant</code> prop can be one of the following 3 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type SearchVariant = 'primary' | 'secondary' | 'simple'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>SearchVariant</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Search } from '@cegal/ds-components'
    
    const Component = () => {
      return (
        <>
          <Search 
            label='Primary'
            hideLabel={false} 
            variant='primary'
           />
          <VerticalSpace/>
          <Search 
            label='Secondary' 
            hideLabel={false} 
            variant='secondary'
          />
          <VerticalSpace/>
          <Search 
            label='Simple' 
            hideLabel={false} 
            variant='simple'
          />
        </>
      )
   )
  }`}
      >
        <Search label='Primary' hideLabel={false} variant='primary' />
        <VerticalSpace />
        <Search label='Secondary' hideLabel={false} variant='secondary' />
        <VerticalSpace />
        <Search label='Simple' hideLabel={false} variant='simple' />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='search-sizes' level='2' spacing>
        Sizes
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Search } from '@cegal/ds-components'
    
    const Component = () => {
      return (
        <>
          <Search 
            label='Medium' 
            hideLabel={false} 
          />
          <VerticalSpace/>
          <Search 
            label='Small'
            hideLabel={false} 
            size='small'
          />
        </>
      )
   )
  }`}
      >
        <Search label='Medium' hideLabel={false} />
        <VerticalSpace />
        <Search label='Small' hideLabel={false} size='small' />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='search-clearbutton' level='2' spacing>
        Clear button
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        You have a clear button by default. You can disabled it with <code>clearButton</code> prop set to{' '}
        <code>false</code>.
      </BodyLong>

      <BodyLong spacing>
        You can also set the <code>clearButtonLabel</code> so screen readers get the title for the clear
        button.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Search } from '@cegal/ds-components'
    
    const Component = () => {
      return (
        <Search 
          label='No clear button' 
          hideLabel={false}
          clearButton={false}
        />
      )
   )
  }`}
      >
        <Search label='No clear button' hideLabel={false} clearButton={false} />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='search-error' level='2' spacing>
        Error
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>error</code> prop sets the text field with a error border.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Search } from '@cegal/ds-components'
    
    const Component = () => {
      return (
        <Search 
          label='search' 
          error='Search box is empty' 
        />
      )
   )
  }`}
      >
        <Search label='search' error='Search box is empty' />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='search-disabled' level='2' spacing>
        Disabled
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>disabled</code> prop deactivates the component.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Search } from '@cegal/ds-components'
    
    const Component = () => {
      return (
        <Search label='search' disabled />
      )
   )
  }`}
      >
        <Search label='search' disabled />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>clearButton</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Shows / hides clear button</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>clearButtonLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets clear button label for screen readers</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>Tøm</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disabled</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets search to disabled</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets search error message</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Shows/hides search label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>label</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets search label</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(value: string) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback function when content changes</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onClear</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(value: SearchClearEvent) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback function when clear button is triggered</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onSubmit</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(value: string) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback function when search button is triggered</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets search form size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'primary' | 'secondary' | 'simple'</code>
              </Table.DataCell>
              <Table.DataCell>Sets search variant</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>secondary</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsSearch
