import {
  Accordion,
  Alert,
  Avatar,
  BodyLong,
  BodyShort,
  Box,
  Button,
  Carousel,
  Checkbox,
  CheckboxGroup,
  Chip,
  Column,
  Counter,
  Datepicker,
  Drawer,
  Dropdown,
  Dropzone,
  ErrorSummary,
  FlexCenterDiv,
  FlexCenterSpacedDiv,
  FlexDiv,
  Heading,
  HelpText,
  Link,
  LinkPanel,
  Loader,
  Modal,
  NavBar,
  Pagination,
  Panel,
  PileCenterDiv,
  Popover,
  Radio,
  RadioGroup,
  RangeSlider,
  ReadMore,
  Search,
  Select,
  SelectMultiple,
  Slider,
  Stepper,
  Switch,
  Table,
  Tabs,
  Tag,
  Textarea,
  TextField,
  ToggleGroup,
  Tooltip,
  VerticalSpace
} from '@cegal/ds-components'
import { Cake } from '@cegal/ds-icons/dist'
import ComponentPanel from 'components/ComponentPanel/ComponentPanel'
import { AlignEndDiv } from 'components/styled'

import React, { useRef, useState } from 'react'

const ComponentsHome = () => {
  const [query, setQuery] = useState<string>('')
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openState, setOpenState] = useState<boolean>(false)
  const [pageState, setPageState] = useState<number>(1)
  const [selectMultipleState, setSelectMultipleState] = useState([])
  const [activeStep, setActiveStep] = useState<number>(1)
  const [toggleValue, setToggleValue] = useState<string>('ulest')
  const containerRef = useRef(null)
  const buttonRef = useRef(null)

  const elements = [
    {
      navigate: '/components/accordion',
      label: 'Accordion',
      description:
        'Accordions organize your content into sections that can expand and collapse individually.',
      el: (
        <Accordion border>
          <Accordion.Item>
            <Accordion.Header>Recipe ingredients</Accordion.Header>
            <Accordion.Content>
              <span>You need these ingredients:</span>
              <ul>
                <li>Flour</li>
                <li>Milk</li>
                <li>Eggs</li>
                <li>Sugar</li>
                <li>Butter</li>
              </ul>
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header>Preparation</Accordion.Header>
            <Accordion.Content>
              <ul>
                <li>Add everything in a bowl</li>
                <li>Mix it for 10 min</li>
                <li>
                  Put in a oven for 20 min, 200<sup>o</sup> C
                </li>
              </ul>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>
      )
    },
    {
      navigate: '/components/alert',
      label: 'Alert',
      description: 'Alert banners increase the visibility of notifications over app events to users.',
      el: <Alert variant='success'>Recipe approved</Alert>
    },
    {
      navigate: '/components/avatar',
      label: 'Avatar',
      description: 'Avatars display images with an equal width / height, in round or square shapes.',
      el: <Avatar size='xlarge' src='/static/images/avatar1.png' />
    },
    {
      navigate: '/components/box',
      label: 'Box',
      description: 'Boxes are panels with optional headers and footers, useful for small content sections.',
      el: (
        <Box border>
          <Box.Header>Recipe allergies</Box.Header>
          <Box.Body>
            <span>Gluten</span>
            <br />
            <span>Dairy</span>
          </Box.Body>
          <Box.Footer style={{ textAlign: 'end' }}>
            <Button size='small'>Add</Button>
          </Box.Footer>
        </Box>
      )
    },
    {
      navigate: '/components/button',
      label: 'Button',
      description: 'A collection of buttons for different kinds of user actions.',
      el: <Button variant='secondary'>Download recipe</Button>
    },
    {
      navigate: '/components/carousel',
      label: 'Carousel',
      description: 'A sliding display panel',
      el: (
        <Carousel border>
          <Carousel.Item> Eggs </Carousel.Item>
          <Carousel.Item> Milk </Carousel.Item>
          <Carousel.Item> Bread </Carousel.Item>
        </Carousel>
      )
    },
    {
      navigate: '/components/checkbox',
      label: 'Checkbox',
      description: 'Form element that collects boolean values from the user.',
      el: <Checkbox>Bake a cake today</Checkbox>
    },
    {
      navigate: '/components/checkboxgroup',
      label: 'Checkbox group',
      description: 'Fielset element that groups checkbox components into a common section.',
      el: (
        <CheckboxGroup size='small' legend='Shopping list'>
          <Checkbox value='Eggs'>Eggs</Checkbox>
          <Checkbox value='Milk'>Milk</Checkbox>
          <Checkbox value='Coffee'>Coffee</Checkbox>
        </CheckboxGroup>
      )
    },
    {
      navigate: '/components/chip',
      label: 'Chip',
      description: 'Small badge with option interaction',
      el: <Chip>🌶 Spicy</Chip>
    },
    {
      navigate: '/components/counter',
      label: 'Counter',
      description: 'Form element for number picking',
      el: <Counter label='Number of pizzas' />
    },
    {
      navigate: '/components/datepicker',
      label: 'Datepicker',
      description: 'Form element for date picking with a calendar dropdown for day selection.',
      el: <Datepicker label='Expiration date' />
    },
    {
      navigate: '/components/drawer',
      label: 'Drawer',
      description: 'Sidebar hidden menu that slides to the viewport when called by the user.',
      el: (
        <>
          <Drawer open={openDrawer} overlay overlayCloses>
            <Panel>
              <Button onClick={() => setOpenDrawer(!openDrawer)}>Close</Button>
            </Panel>
          </Drawer>
          <Button variant='secondary' onClick={() => setOpenDrawer(!openDrawer)}>
            Filter recipes
          </Button>
        </>
      )
    },
    {
      navigate: '/components/dropdown',
      label: 'Dropdown',
      description: 'Dropdown menu component that packs contextual actions in a hidden way.',
      el: (
        <Dropdown>
          <Button as={Dropdown.Toggle}>Toggle</Button>
          <Dropdown.Menu>
            <Dropdown.Menu.GroupedList>
              <Dropdown.Menu.GroupedList.Heading>Recipes</Dropdown.Menu.GroupedList.Heading>
              <Dropdown.Menu.GroupedList.Item>Eggs</Dropdown.Menu.GroupedList.Item>
              <Dropdown.Menu.GroupedList.Item>Milk</Dropdown.Menu.GroupedList.Item>
            </Dropdown.Menu.GroupedList>
            <Dropdown.Menu.Divider />
            <Dropdown.Menu.List>
              <Dropdown.Menu.List.Item>Sugar</Dropdown.Menu.List.Item>
              <Dropdown.Menu.List.Item>Flour</Dropdown.Menu.List.Item>
            </Dropdown.Menu.List>
          </Dropdown.Menu>
        </Dropdown>
      )
    },
    {
      navigate: '/components/dropzone',
      label: 'Dropzone',
      description: 'Drop zone area for drag and drop file uploads.',
      el: <Dropzone onDrop={() => {}} />
    },
    {
      navigate: '/components/errorsummary',
      label: 'Error summary',
      description: 'Alert panel that aggregates error messages in one place, from a form validation.',
      el: (
        <ErrorSummary heading='Recipe incomplete'>
          <ErrorSummary.Item>You must add sugar</ErrorSummary.Item>
        </ErrorSummary>
      )
    },
    {
      navigate: '/components/helptext',
      label: 'Help text',
      description: 'Help button for contextual information displayed by a popover element.',
      el: <HelpText>Recipe takes 15 min to make</HelpText>
    },
    {
      navigate: '/components/link',
      label: 'Link',
      description: 'Styled anchor element suited for internal and external navigation links.',
      el: <Link href='#a'>See shopping list</Link>
    },
    {
      navigate: '/components/linkpanel',
      label: 'Link panel',
      description: 'Bigger anchor element for links that need a longer title and an optional description.',
      el: (
        <LinkPanel href='#a2'>
          <LinkPanel.Title>See my recipes</LinkPanel.Title>
          <LinkPanel.Description>You saved 4 recipes</LinkPanel.Description>
        </LinkPanel>
      )
    },
    {
      navigate: '/components/loader',
      label: 'Loader',
      description: 'Loader animation with a spinning circular SVG element with different styles and sizes.',
      el: <Loader size='xlarge' />
    },
    {
      navigate: '/components/modal',
      label: 'Modal',
      description: 'Dialog panel that blocks the viewport and prompts user for an input before continuing.',
      el: (
        <>
          <Button variant='secondary' onClick={() => setOpenModal(!openModal)}>
            See ingredients
          </Button>
          {containerRef.current && (
            <Modal
              parentSelector={() => containerRef?.current!}
              open={openModal}
              aria-label='Modal demo'
              closeButton={false}
              onClose={() => setOpenModal(!openModal)}
            >
              <Modal.Content>
                <Modal.Content.Body>
                  <BodyLong>This recipe needs:</BodyLong>
                  <ul>
                    <li>Flour</li>
                    <li>Eggs</li>
                    <li>Sugar</li>
                  </ul>
                </Modal.Content.Body>
                <Modal.Content.Footer style={{ textAlign: 'end' }}>
                  <Button onClick={() => setOpenModal(!openModal)}> OK </Button>
                </Modal.Content.Footer>
              </Modal.Content>
            </Modal>
          )}
        </>
      )
    },
    {
      navigate: '/components/navbar',
      label: 'Navigation bar',
      description: 'Navigation element that groups links and other components for header and sidebar menus.',
      el: (
        <NavBar underscore>
          <NavBar.Link>Soups</NavBar.Link>
          <NavBar.Link>Starters</NavBar.Link>
          <NavBar.Link>Desserts</NavBar.Link>
        </NavBar>
      )
    },
    {
      navigate: '/components/pagination',
      label: 'Pagination',
      description:
        'Navigation buttons for browsing pages of elements where only a slice them should be visible.',
      el: (
        <Pagination
          size='small'
          page={pageState}
          onPageChange={(x: number) => setPageState(x)}
          count={3}
          boundaryCount={1}
          siblingCount={1}
        />
      )
    },
    {
      navigate: '/components/panel',
      label: 'Panel',
      description: 'Baseline component that wraps content with an optional border, padding or background.',
      el: (
        <Panel border background>
          <PileCenterDiv>
            <Avatar spacing size='small' src='/static/images/avatar1.png' />
            <BodyShort>Pasta recipe</BodyShort>
            <BodyShort>Time: 15 min.</BodyShort>
          </PileCenterDiv>
        </Panel>
      )
    },
    {
      navigate: '/components/popover',
      label: 'Popover',
      description:
        'tooltip component that displays as a balloon, next to any element, to show context information.',
      el: (
        <>
          <Button ref={buttonRef} onClick={() => setOpenState(true)}>
            Put cake in the oven
          </Button>
          <Popover
            placement='top'
            open={openState}
            onClose={() => setOpenState(false)}
            anchorEl={buttonRef.current}
          >
            <Popover.Content>Make sure the oven is pre-heated</Popover.Content>
          </Popover>
        </>
      )
    },
    {
      navigate: '/components/radiogroup',
      label: 'Radio group',
      description: 'Group of radio buttons handy to display a good description for each available option.',
      el: (
        <RadioGroup legend='Shopping list' defaultValue='milk'>
          <Radio value='sales'>Milk</Radio>
          <Radio value='energy'>Eggs</Radio>
          <Radio value='settings'>Flour</Radio>
        </RadioGroup>
      )
    },
    {
      navigate: '/components/rangeslider',
      label: 'Range slider',
      description: 'Slider with a lower and higher thumbs.',
      el: (
        <div style={{ width: '100%' }}>
          <RangeSlider label='Allocation' hideLabel />
          <VerticalSpace size='0.5' />
          <FlexCenterSpacedDiv>
            <div>0%</div>
            <div> 100%</div>
          </FlexCenterSpacedDiv>
        </div>
      )
    },
    {
      navigate: '/components/readmore',
      label: 'Read more',
      description: 'Panel component with hidden content and a visibility toggle button on top.',
      el: (
        <ReadMore header='Preparation'>
          <ul>
            <li>Add everything in a bowl</li>
            <li>Mix it for 10 min</li>
            <li>
              Put in a oven for 20 min, 200<sup>o</sup> C
            </li>
          </ul>
        </ReadMore>
      )
    },
    {
      navigate: '/components/search',
      label: 'Search',
      description: 'Text field component coupled with a search button and optional search suggestions.',
      el: <Search hideLabel={false} label='Search recipes' size='medium' variant='secondary' />
    },
    {
      navigate: '/components/select',
      label: 'Select',
      description: 'Native select dropdown component for picking one single option from a menu list.',
      el: (
        <Select label='Choose your cake'>
          <option value='chocolateCake'>Chocolate cake</option>
          <option value='cheeseCake'>Cheesecake</option>
          <option value='carrotCake'>Carrot cake</option>
        </Select>
      )
    },
    {
      navigate: '/components/selectmultiple',
      label: 'Select multiple',
      description: 'Select component for selecting / creating / deleting multiple options in one go.',
      el: (
        <SelectMultiple
          anchorEl={containerRef?.current}
          label='Choose your cakes'
          // @ts-ignore
          values={selectMultipleState}
          onChange={setSelectMultipleState}
          options={[
            { label: 'Chocolate cake', value: 'cake1' },
            { label: 'Cheesecake', value: 'cake2' },
            { label: 'Carrot cake', value: 'cake3' }
          ]}
        />
      )
    },
    {
      navigate: '/components/slider',
      label: 'Slider',
      description: 'Numeric selection within a range of values, with a sliding movement along a bar.',
      el: (
        <FlexCenterDiv>
          <div>0%</div>
          &nbsp;
          <Slider label='Allocation' hideLabel />
          &nbsp;<div> 100%</div>
        </FlexCenterDiv>
      )
    },
    {
      navigate: '/components/stepper',
      label: 'Stepper',
      description: 'List of step components useful to navigate a workflow, or display its status.',
      el: (
        <Stepper aria-labelledby='stepper-heading' activeStep={activeStep} onStepChange={setActiveStep}>
          <Stepper.Step>Make cake</Stepper.Step>
          <Stepper.Step>Eat cake</Stepper.Step>
        </Stepper>
      )
    },
    {
      navigate: '/components/switch',
      label: 'Switch',
      description: 'Alternative checkbox component, with a better visibility over its checked state.',
      el: <Switch>Get recipe updates</Switch>
    },
    {
      navigate: '/components/table',
      label: 'Table',
      description: 'Styled table elements with sortable column headers, expandable / selectable rows.',
      el: (
        <Table size='small'>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Amount</Table.ColumnHeader>
              <Table.ColumnHeader>Item</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>200g</Table.DataCell>
              <Table.DataCell>Butter</Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>3 tsp</Table.DataCell>
              <Table.DataCell>Sugar</Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>2</Table.DataCell>
              <Table.DataCell>Eggs</Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      )
    },
    {
      navigate: '/components/tabs',
      label: 'Tabs',
      description: 'Tab-style menu for switching between different panel contents, showing one at a time.',
      el: (
        <Tabs defaultValue='soups' size='small'>
          <Tabs.List>
            <Tabs.Tab value='soups' label='Soups' />
            <Tabs.Tab value='main courses' label='Main courses' />
            <Tabs.Tab value='desserts' label='Desserts' />
          </Tabs.List>
          <Tabs.Panel value='soups'>
            <VerticalSpace />
            <ul>
              <li>Chicken soup</li>
              <li>Vegetable soup</li>
            </ul>
          </Tabs.Panel>
          <Tabs.Panel value='main courses'>
            <Panel noPadding={false}>
              <VerticalSpace />
              <ul>
                <li>Pizza</li>
                <li>Sandwich</li>
              </ul>
            </Panel>
          </Tabs.Panel>
          <Tabs.Panel value='desserts'>
            <VerticalSpace />
            <ul>
              <li>Chocolate cake</li>
              <li>Ice cream</li>
            </ul>
          </Tabs.Panel>
        </Tabs>
      )
    },
    {
      navigate: '/components/tag',
      label: 'Tag',
      description: 'Styled labels for categorizing certain elements with the help of colors.',
      el: <Tag variant='error'>Spicy</Tag>
    },
    {
      navigate: '/components/textarea',
      label: 'Text area',
      description: 'Text area components with charater limits, resizing capability, error handling.',
      el: <Textarea label='Comment' value='This is a great cake for a party' onChange={() => {}} />
    },
    {
      navigate: '/components/textfield',
      label: 'Text field',
      description: 'Text field components to let user enter and edit text content.',
      el: <TextField label='Ingredient' value='Butter' onChange={() => {}} />
    },
    {
      navigate: '/components/togglegroup',
      label: 'Toggle group',
      description: 'Styled toggle buttons group for picking a value among a list of options.',
      el: (
        <ToggleGroup onChange={setToggleValue} value={toggleValue} size='small'>
          <ToggleGroup.Item value='soups'>Soups</ToggleGroup.Item>
          <ToggleGroup.Item value='maincourses'>Main courses</ToggleGroup.Item>
          <ToggleGroup.Item value='desserts'>Desserts</ToggleGroup.Item>
        </ToggleGroup>
      )
    },
    {
      navigate: '/components/Tooltip',
      label: 'Tooltip',
      description: 'Balloon tooltip on hover for quick descriptions over an element.',
      el: (
        <Tooltip content='You have one cake in the oven' placement='top'>
          <Button variant='secondary'>
            <Cake size='1.2rem' aria-hidden /> Cakes
          </Button>
        </Tooltip>
      )
    }
  ]

  return (
    <>
      <VerticalSpace />
      <Heading size='large'>Components library</Heading>
      <VerticalSpace size='2' />
      <AlignEndDiv>
        <Column>
          <TextField label='Search' value={query} onChange={(e: any) => setQuery(e.target.value)} />
        </Column>
        <Column>
          <div>
            <Button variant='secondary' onClick={() => setQuery('')}>
              Clear
            </Button>
          </div>
        </Column>
      </AlignEndDiv>
      <VerticalSpace size='2' />
      <FlexDiv ref={containerRef} style={{ flexWrap: 'wrap' }}>
        {elements
          .filter(
            (el) =>
              el.description.toLowerCase().match(query.toLowerCase()) ||
              el.label.toLowerCase().match(query.toLowerCase())
          )
          .map((e) => (
            <ComponentPanel key={e.label} navigate={e.navigate} label={e.label} description={e.description}>
              {e.el}
            </ComponentPanel>
          ))}
      </FlexDiv>
    </>
  )
}

export default ComponentsHome
