import { FlexDiv, Heading, LinkPanel, Panel, VerticalSpace } from '@cegal/ds-components'
import { HomePageLinkPanel } from 'components/styled'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const data = [
  {
    link: '/resources/illustrations',
    title: 'Illustrations',
    description: 'Images from Cegal brand identity that you can use to decorate the background of your app'
  },
  {
    link: '/resources/icons',
    title: 'Icons',
    description: 'Browse available icons from @cegal/ds-icons package, and properly import them to your app'
  },
  {
    link: '/resources/logos',
    title: 'Logos',
    description: 'All Cegal logos and favicons available through the @cegal/ds-logos package'
  },
  {
    link: '/resources/colours',
    title: 'Colors/themes',
    description: "Color palette and CSS variables from the design system's themes and modes"
  },
  {
    link: '/resources/typography',
    title: 'Typography',
    description: 'Recommendations on how to structure our content with the typography components'
  },
  {
    link: '/resources/spacing',
    title: 'Spacing/grids',
    description: 'Organize the layout of your application with the help of design system components'
  },
  {
    link: '/resources/utilities',
    title: 'Utilities',
    description: 'Assorted utilities that can speed up bootstrapping your application'
  },
  {
    link: '/resources/graphs',
    title: 'Graphs',
    description: 'Add charts to your application with no effort'
  }
]

const ResourcesHome = () => {
  const navigate = useNavigate()
  return (
    <>
      <VerticalSpace />
      <Panel>
        <Heading size='large'>Resources</Heading>
      </Panel>
      <FlexDiv style={{ flexWrap: 'wrap' }}>
        {data.map((item, i) => (
          <HomePageLinkPanel
            key={item.link}
            href='#'
            onClick={() => navigate(item.link)}
            style={{ animationDelay: i * 0.05 + 's' }}
            variant='secondary'
          >
            <div className='content'>
              <LinkPanel.Title>{item.title}</LinkPanel.Title>
              <LinkPanel.Description>{item.description}</LinkPanel.Description>
            </div>
          </HomePageLinkPanel>
        ))}
      </FlexDiv>
    </>
  )
}

export default ResourcesHome
