import { LinkPanel } from '@cegal/ds-components'
import styled, { keyframes } from 'styled-components'

export const slideInFromLeft = keyframes` 
  0% {
    opacity: 0;
    transform: translateX(-20px);
}
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

export const HomePageLinkPanel: any = styled(LinkPanel)`
  max-width: 450px;
  margin: 1rem;
  padding-right: 1rem;
  .content {
    padding: 1rem;
  }
`
export const NavDiv = styled.div`
  flex: 1;
  position: sticky;
  align-self: flex-start;
  height: auto;
  top: 4rem;
  padding-top: 2rem;
  left: 0rem;
  @media (max-width: 768px) {
    display: none;
  }
`

export const NavDiv2 = styled.nav`
  position: fixed;
  align-self: flex-end;
  max-height: 100vh;
  top: 0rem;
  width: 200px;
  overflow-y: auto;
  @media (max-width: 768px) {
    display: none;
  }
`

export const ContentDiv = styled.div`
  flex: 4;
  max-width: calc(100% - 216px);
  width: 100%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`
export const SideBarDiv = styled.div`
  display: inline-block;
  margin-right: 1rem;
  width: 200px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const AlignEndDiv = styled.div`
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  flex-direction: row;
  align-items: flex-end;
`

export const SelectDiv = styled.div`
  padding: 0.5rem;
  margin-right: 4rem;
  display: none;

  @media (min-width: 1228px) {
    margin-right: 0;
    display: block;
  }
`
export const SelectDivMobile = styled.div`
  padding: 0.5rem;
  margin-right: 4rem;

  @media (min-width: 1228px) {
    margin-right: 0;
    display: none;
  }
`

export const MenuButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  @media (min-width: 1228px) {
    display: none;
  }
`
export const TableWrapperDiv = styled.div`
  overflow-x: auto;
`

NavDiv.displayName = 'NavDiv'
