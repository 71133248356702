import { BodyLong, BodyShort, Heading, HorizontalSpace, VerticalSpace } from '@cegal/ds-components'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import React from 'react'

const ComponentsSpacing = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='spacing' level='1' spacing>
        Spacing
      </Heading>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='horizontalspace' level='2' spacing>
        Horizontal space
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        Use the <code>HorizontalSpace</code> component as a spacing element. An optional <code>size</code>{' '}
        prop sets custom spacing (default is 1), in rem values.{' '}
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { HorizontalSpace } from '@cegal/ds-components'

const Component = () => {
  return (
     <BodyLong>
        this is a <HorizontalSpace size='10'/>long space
     </BodyShort>
  )
}`}
      >
        <BodyLong>
          this is a <HorizontalSpace size='10' />
          long space
        </BodyLong>
      </DemoableDiv>

      <VerticalSpace />

      <BodyLong spacing>
        If you want to use another tag, for example, <code>span</code>, us the <code>as</code> polymorphic
        prop.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { HorizontalSpace } from '@cegal/ds-components'

const Component = () => {
  return (
     <BodyShort>
        this is a 
        <HorizontalSpace as='span' size='10'/>
        long space with a{' '}
        <code>div</code>{' '}
         element.
     </BodyShort>
  )
}`}
      >
        <BodyShort>
          this is a
          <HorizontalSpace as='span' size='10' />
          long space with a <code>div</code> element.
        </BodyShort>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='verticalspace' level='2' spacing>
        Vertical space
      </Heading>

      <BodyLong spacing>
        For vertical separations with a <code>div</code> tag. An optional <code>size</code> prop sets custom
        spacing (default is 1), in rem values.{' '}
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { VerticalSpace } from '@cegal/ds-components'

const Component = () => {
  return (
     <>
        <BodyLong>this is a line</BodyLong>
        <VerticalSpace size='5'/>
        <BodyLong>this is another line</BodyLong>
     </>
  )
}`}
      >
        <BodyLong>this is a line</BodyLong>
        <VerticalSpace size='5' />
        <BodyLong>this is another line</BodyLong>
      </DemoableDiv>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsSpacing
