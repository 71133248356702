import * as types from 'constants/actionTypes'
import { AnyAction } from 'redux'
import { Theme } from 'declarations/app'

export interface AppState {
  theme: Theme
}

const localTheme = window.localStorage.getItem('theme')

export const initialAppState: AppState = {
  theme: (localTheme ?? 'cegal-light') as Theme
}

const appReducer = (state: AppState = initialAppState, action: AnyAction): AppState => {
  switch (action.type) {
    case types.THEME_SET:
      window.localStorage.setItem('theme', action.payload.theme)
      return {
        ...state,
        theme: action.payload.theme
      }

    default:
      return state
  }
}

export default appReducer
