import { BodyLong, Heading, Link, Table, VerticalSpace } from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import { TableWrapperDiv } from 'components/styled'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ResourcesUtilities = () => {
  const navigate = useNavigate()

  return (
    <>
      <VerticalSpace />
      <Heading size='large' spacing>
        Utilities
      </Heading>
      <VerticalSpace size='2' />
      <div>
        <BodyLong spacing>
          The design system also provides some common utilities that users may find helpful.
        </BodyLong>

        <a href='#fetch' className='cds-sr-only'>
          fetch
        </a>
        <Heading className='toc' size='medium' level='2' id='fetch' spacing>
          Fetch
        </Heading>

        <BodyLong spacing>
          The <strong>fetch</strong> utility came from the need of a API call function that works with Redux
          actions, and has a simple mocking functionality to help development without backend support, and
          that works seamlessly in development and production environments.
        </BodyLong>

        <ul>
          <li>
            <BodyLong spacing>
              When <code>process.env.REACT_APP_ENVIRONMENT</code> is <code>development</code> or, in case is
              not defined, the application's hostname is <code>localhost</code> and is running in a browser,
              then the fetch call will work in a <strong>mocked mode</strong> by default, where responses{' '}
              <strong>have a simulated delay</strong> (up to 2 seconds), they return a predefined payload, and
              can even return different HTTP codes.
            </BodyLong>
          </li>

          <li>
            <BodyLong spacing>
              When <code>process.env.REACT_APP_ENVIRONMENT</code> is <code>test</code>,{' '}
              <code>production</code> or, in case is not defined, the application's hostname is NOT{' '}
              <code>localhost</code>, then the fetch call uses the native <code>fetch</code> function. The
              response is processed (text or binary) and ready to consume in action's payload. In test
              environments, it is recommended that you do your own unit test mock of the call function.
            </BodyLong>
          </li>

          <li>
            <BodyLong spacing>
              When <code>process.env.NODE_ENV</code> is <code>chaos</code>, then the call function will use
              the mocking mode, with a 50% probability of returning 40X and 50X errors. If mocking mode is
              asked to be skipped (for instance, to use a dev backend server), then the mocking mode will only
              use mocking mode for 40X and 50X errors -- the 20X results will NOT be served by the mocking
              mode.
            </BodyLong>
          </li>
        </ul>

        <a href='#fetchdispatch' className='cds-sr-only'>
          Dispatch actions
        </a>
        <Heading className='toc' size='small' level='3' id='fetchdispatch' spacing>
          Dispatch actions
        </Heading>

        <BodyLong spacing>
          The <code>call</code> class method returns a function that accepts a <code>dispatch</code> function
          as a single parameter. In other words, it expects a store system that has a thunk middleware, such
          as <code>@reduxjs/toolkit</code>. See the{' '}
          <Link href='#' onClick={() => navigate('/tips')}>
            Tips page
          </Link>{' '}
          for help on how to set up Redux toolkit in your app.
        </BodyLong>

        <a href='#fetchdispatchrequest' className='cds-sr-only'>
          Request action
        </a>
        <Heading className='toc' size='xsmall' level='4' id='fetchdispatchrequest' spacing>
          Request action
        </Heading>

        <BodyLong>The action dispatched with a request has this form: </BodyLong>
        <CodeDiv spacing expand={false}>
          {`{
  type: {type.request},
  context: {context}
}`}
        </CodeDiv>
        <VerticalSpace />
        <a href='#fetchdispatchsredirect' className='cds-sr-only'>
          Redirect action
        </a>
        <Heading className='toc' size='xsmall' level='4' id='fetchdispatchsredirect' spacing>
          Redirect action
        </Heading>

        <BodyLong>The action dispatched with a redirect has this form: </BodyLong>
        <CodeDiv spacing expand={false}>
          {`{
  type: {type.redirect},
  status: {HTTP response status code}
  headers: {
    Location: {the URL you need to follow next, goes here}
  }
  originalPayload: {body || payload}, request body if used
  context: {context}
}`}
        </CodeDiv>

        <a href='#fetchdispatchsuccess' className='cds-sr-only'>
          Success action
        </a>
        <Heading className='toc' size='xsmall' level='4' id='fetchdispatchsuccess' spacing>
          Success action
        </Heading>

        <BodyLong>The action dispatched with a success has this form: </BodyLong>
        <CodeDiv spacing expand={false}>
          {`{
  type: {type.success},
  status: HTTP response status code
  headers: response headers 
  payload: response body
  originalPayload: {body || payload}, request body if used
  context: {context}
}`}
        </CodeDiv>
        <a href='#fetchdispatchsfailure' className='cds-sr-only'>
          Failure action
        </a>
        <Heading className='toc' size='xsmall' level='4' id='fetchdispatchsfailure' spacing>
          Failure action
        </Heading>

        <BodyLong>The action dispatched with a failure has this form: </BodyLong>
        <CodeDiv spacing expand={false}>
          {`{
  type: {type.failure},
  payload: response body
  headers: response headers 
  error: {
   message: the response.statusText string value,
   stack: the stack track
  },
  status: HTTP response status code
  originalPayload: {body || payload}, request body if used,
  context: {context}
}`}
        </CodeDiv>

        <VerticalSpace />
        <a href='#types' className='cds-sr-only'>
          Action types
        </a>
        <Heading className='toc' size='small' level='3' id='types' spacing>
          Action types
        </Heading>

        <ul>
          <li>types.request: when the request starts</li>
          <li>types.success: when responses have 20x status codes</li>
          <li>types.redirect: when responses have 30x status codes. It defaults to 'API/CALL/REDIRECT'</li>
          <li>
            types.unauthorized: when responses have 401 status codes. If not given, types.failure is used
            instead
          </li>
          <li>
            types.forbidden: when responses have 403 status codes. If not given, types.failure is used instead
          </li>
          <li>
            types.failure: when responses have 40x status codes. If <code>cascadeFailureError</code> is set to{' '}
            <code>true</code>, then it also is dispatched with 50x status code responses
          </li>
          <li>
            types.serverInternalError: when responses have 50x status codes. If not given, string
            'SERVER_INTERNAL_ERROR' is used
          </li>
        </ul>

        <VerticalSpace size='2' />
        <a href='#examples' className='cds-sr-only'>
          Examples
        </a>
        <Heading className='toc' size='small' level='3' id='examples' spacing>
          Examples
        </Heading>

        <a href='#examples1' className='cds-sr-only'>
          Simple GET
        </a>
        <Heading className='toc' size='xsmall' level='4' id='examples1' spacing>
          Simple GET
        </Heading>

        <BodyLong>A simple GET request would look like this:</BodyLong>

        <CodeDiv spacing expand={false}>
          {`
import { fetch } from '@cegal/ds-utils'

const api = new fetch.Call()

const getUsers = () => {
  return api.call({
    url: 'api/users',
    headers: {
     'Authentication: Bearer 123456'
    },
    type: {
      request: 'USERS_LIST_REQUEST',
      success: 'USERS_LIST_SUCCESS',
      failure: 'USERS_LIST_FAILURE'
    }
})`}
        </CodeDiv>
        <BodyLong spacing>
          The default request method is GET, and default response type is JSON. The <code>call</code> function
          dispatches a request action when fetch begins, and will either dispatch a success action or a
          failure action. Headers are optional.
        </BodyLong>

        <a href='#examples2' className='cds-sr-only'>
          Cascading failures
        </a>
        <Heading className='toc' size='xsmall' level='4' id='examples2' spacing>
          Cascading failures
        </Heading>

        <BodyLong>
          The 500 errors will dispatch a types.serverInternalError action. If you want to capture the 500
          error as a failure, then you can set <code>cascadeFailureError</code> to <code>true</code>, so that
          additionally a failure action is dispatched as well.
        </BodyLong>

        <CodeDiv spacing expand={false}>
          {`
import { fetch } from '@cegal/ds-utils'

const api = new fetch.Call()

const getUsers = () => {
   return api.call({
    url: 'api/users',
    cascadeFailureError: true,
    type: {
      request: 'USERS_LIST_REQUEST',
      success: 'USERS_LIST_SUCCESS',
      failure: 'USERS_LIST_FAILURE'
    }
  })
}`}
        </CodeDiv>

        <a href='#examples3' className='cds-sr-only'>
          Mocking requests
        </a>
        <Heading className='toc' size='xsmall' level='4' id='examples3' spacing>
          Mocking requests
        </Heading>

        <BodyLong spacing>
          You can mock the successful body response with the <code>expectedPayload</code> property. It accepts
          either an object, or a function that will return the object.
        </BodyLong>

        <BodyLong spacing>
          You can mock the HTTP code, so you can test different server responses. You do this with the{' '}
          <code>expectedResponses</code>, an object where the key is the HTTP code, and the value is a
          relative number that sets the probability of that code to be replied.
        </BodyLong>

        <BodyLong>
          In the example below, the request will succeed 33% of the time with a 200 code and the body{' '}
          <code>
            {'{'}id: 1, name: 'Sales'{'}'}
          </code>
          , and it will fail 33% of the time with a 404 error (dispatched as 'USERS_LIST_FAILURE'), and 33% of
          the time with a 500 error (dispatched as 'SERVER_INTERNAL_ERROR'). To handle this as well with a
          'USERS_LIST_FAILURE', you only need to add <code>cascadeFailureError</code> with <code>true</code>.
        </BodyLong>

        <CodeDiv spacing expand={false}>
          {`
import { fetch } from '@cegal/ds-utils'

const api = new fetch.Call()

const getUsers = () => {
  return api.call({
     url: 'api/users',
     expectedPayload: () => ({id: 1, name: 'Sales'}),
     expectedResponses: { 200: 1, 404: 1, 500: 1 },
     maxDelayTime = 5000,
     type: {
      request: 'USERS_LIST_REQUEST',
      success: 'USERS_LIST_SUCCESS',
      failure: 'USERS_LIST_FAILURE'
    }
  })
}`}
        </CodeDiv>

        <a href='#examples4' className='cds-sr-only'>
          Authentication headers
        </a>
        <Heading className='toc' size='xsmall' level='4' id='examples4' spacing>
          Authentication headers
        </Heading>

        <BodyLong>You can pass a header object to send request headers, as shown below.</BodyLong>

        <CodeDiv spacing expand={false}>
          {`
        api.call({
           method: 'POST',
           url: 'api/user/new',
           headers: {
            'Authentication: Bearer 123456'
          },
          type: {
            request: 'NEW_USER_REQUEST',
            success: 'NEW_USER_SUCCESS',
            failure: 'NEW_USER_FAILURE'
          }
      })`}
        </CodeDiv>

        <a href='#examples5' className='cds-sr-only'>
          Using a backend server in development mode
        </a>
        <Heading className='toc' size='xsmall' level='4' id='examples5' spacing>
          Using a backend server in development mode
        </Heading>

        <BodyLong>
          In development mode, the <strong>fetch</strong> function will use the mocked response, while in test
          or production mode, it will use the real <strong>fetch</strong> call. If you want to use a real
          backend server in your development, then you can add <code>skipFake</code> to true, which will
          bypass the mock response, as shown below.
        </BodyLong>

        <CodeDiv spacing expand={false}>
          {`
        api.call({
          method: 'POST',
          url: 'api/user/new',
          skipFake: true,
          type: {
            request: 'NEW_USER_REQUEST',
            success: 'NEW_USER_SUCCESS',
            failure: 'NEW_USER_FAILURE'
          }
      })`}
        </CodeDiv>

        <BodyLong spacing>
          If you do not have a backend server running, this call will return a 500 error.
        </BodyLong>

        <a href='#propstable' className='cds-sr-only'>
          Props table
        </a>
        <Heading className='toc' size='small' level='3' id='properties' spacing>
          Props table
        </Heading>
        <TableWrapperDiv>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeader>Name</Table.ColumnHeader>
                <Table.ColumnHeader>Type</Table.ColumnHeader>
                <Table.ColumnHeader>Description</Table.ColumnHeader>
                <Table.ColumnHeader>Required</Table.ColumnHeader>
                <Table.ColumnHeader>Default</Table.ColumnHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.DataCell>
                  <code>body</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>any</code>
                </Table.DataCell>
                <Table.DataCell>Body content for PUT/POST requests</Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>-</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>context</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>any</code>
                </Table.DataCell>
                <Table.DataCell>passes a generic object to the fetch actions</Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>-</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>cascadeFailureError</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>boolean</code>
                </Table.DataCell>
                <Table.DataCell>Trigger failure actions for 5XX errors</Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>false</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>expectedPayload</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>object</code> or <code>() =&gt; object</code>
                </Table.DataCell>
                <Table.DataCell>Expected payload return for mocked calls</Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>-</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>expectedResponses</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>object</code>
                </Table.DataCell>
                <Table.DataCell>Expected HTTP status replies and probabilities from 0 to 1</Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>{'{200: 1}'}</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>headers</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code />
                </Table.DataCell>
                <Table.DataCell>Additional request headers</Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>false</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>maxDelayTime</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>number</code>
                </Table.DataCell>
                <Table.DataCell>
                  Maximum time, in milliseconds, for simulating delays between request and response
                </Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>2000</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>method</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>string</code>
                </Table.DataCell>
                <Table.DataCell>HTTP request method</Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>GET</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>responseType</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>'json' | 'pdf' | 'blob'</code>
                </Table.DataCell>
                <Table.DataCell>
                  Set expected data type so it can be processed into the action payload
                </Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>json</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>payload</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>any</code>
                </Table.DataCell>
                <Table.DataCell>
                  Same as <code>body</code>, just a synonymous key
                </Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>-</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>skipFake</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>boolean</code>
                </Table.DataCell>
                <Table.DataCell>
                  set to true if you want to skip mocks and use real calls while in dev mode
                </Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>false</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>type</code>
                </Table.DataCell>
                <Table.DataCell>Object with action types</Table.DataCell>
                <Table.DataCell>
                  Set action types for events: request, forbidden, redirect, success, failure
                </Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>-</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>url</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>string</code>
                </Table.DataCell>
                <Table.DataCell>Request URL</Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>-</code>
                </Table.DataCell>
              </Table.Row>
              <Table.Row>
                <Table.DataCell>
                  <code>useTimestamp</code>
                </Table.DataCell>
                <Table.DataCell>
                  <code>boolean</code>
                </Table.DataCell>
                <Table.DataCell>
                  Append a timestamp param on URL to prevent some browsers to cache requests
                </Table.DataCell>
                <Table.DataCell>No</Table.DataCell>
                <Table.DataCell>
                  <code>false</code>
                </Table.DataCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </TableWrapperDiv>
        <VerticalSpace size='3' />
      </div>
    </>
  )
}

export default ResourcesUtilities
