import {
  Heading,
  VerticalSpace,
  Panel,
  BodyShort,
  Link,
  Button,
  Modal,
  Table,
  BodyLong
} from '@cegal/ds-components'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useRef, useState } from 'react'

const ComponentsHome = () => {
  const [open1, setOpen1] = useState<boolean>(false)
  const [open2, setOpen2] = useState<boolean>(false)
  const [open3, setOpen3] = useState<boolean>(false)
  const modalContainerRef = useRef(null)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='modal' level='1' spacing>
        Modal
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>Modal</code> component 'steals' the user attention to solve a situation before proceeding.
        Modals are aggressive elements that interrupt the user's workflow, so use them if there is something
        important to address, otherwise think of other wfays to prompt the user.
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='modal-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The default modal attaches itself at the body element level, therefore outside the <code>div</code>{' '}
        that contains the <code>data-theme</code> attribute.
      </BodyLong>

      <BodyLong>
        The Cegal design system theme is therefore not applied to it, unless you either anchor the modal to an
        element under a <code>Container</code>, or you use a<code>Container</code> with{' '}
        <code>data-theme</code> in the Modal content.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Modal } from '@cegal/ds-components'

const Component = () => {
  const [openState, setOpenState] = useState<boolean>(false)
  return (
    <>
      <Button onClick={() => setOpenState(true)}>
        See ingredients
      </Button>
      <Modal
        open={openState}
        onClose={() => setOpenState(!openState)}
      >
        <Modal.Content>
          <Panel>
            <Heading spacing level='1'>
              This recipe needs:
            </Heading>
            <ul>
              <li>Flour</li>
              <li>Eggs</li>
              <li>Sugar</li>
            </ul>
            <BodyLong>
              Note how the modal content does not
              inherit theme styles.
            </BodyLong>
            <BodyLong>
              The modal elements are being attached
              at the body element level, therefore outside
              the <code>div</code> that contains the
            <code>data-theme</code> attribute
            </BodyLong>
            <BodyLong>
              Note also that you have to take care of the 
              padding, if you are not going to use{' '}
              <code>Modal.Content</code> elements.
            </BodyLong>
          </Panel>
        </Modal.Content>
      </Modal>
    </>
  )
}`}
      >
        <>
          <Button onClick={() => setOpen1(true)}>See ingredients</Button>
          <Modal open={open1} onClose={() => setOpen1(!open1)}>
            <Modal.Content>
              <Panel>
                <Heading spacing level='1'>
                  This recipe needs:
                </Heading>
                <ul>
                  <li>Flour</li>
                  <li>Eggs</li>
                  <li>Sugar</li>
                </ul>
                <BodyLong>Note how the modal content does not inherit theme styles.</BodyLong>
                <BodyLong>
                  The modal elements are being attached at the body element level, therefore outside the{' '}
                  <code>div</code> that contains the
                  <code>data-theme</code> attribute
                </BodyLong>
                <BodyLong>
                  Note also that you have to take care of the padding, if you are not going to use{' '}
                  <code>Modal.Content</code> elements.
                </BodyLong>
              </Panel>
            </Modal.Content>
          </Modal>
        </>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='modal-container' level='2' spacing>
        Anchoring modal's container
      </Heading>

      <VerticalSpace />
      <BodyLong spacing>
        To regain the theme styles, you can provide an element reference where the modal should anchor to.
        That element should have (or have a parent element with) a <code>data-theme</code> prop, and with a{' '}
        <code>Container</code> element for proper text and background styling.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Modal } from '@cegal/ds-components'

const Component = () => {
  const modalContainerRef = useRef(null)  
  const [openState, setOpenState]= useState<boolean>(false)
  return (
    <div ref={modalContainerRef}>
      <Button onClick={() => setOpenState(true)}>
         See ingredients
      </Button>
      {modalContainerRef?.current && (
        <Modal
          parentSelector={() => modalContainerRef?.current}
          open={openState}
          onClose={() => setOpenState(!openState)}
        >
          <Modal.Content>
            <Panel>
            <Heading spacing level='1'>
                This recipe needs:
              </Heading>
              <ul>
                <li>Flour</li>
                <li>Eggs</li>
                <li>Sugar</li>
              </ul>
              <BodyLong>
                Note that this modal does inherit 
                theme styles, as it is being attached to the
                parent's <code>DIV</code> element.
              </BodyLong>
            </Panel>
          </Modal.Content>
        </Modal>
      )}
    </div>
  )
}`}
      >
        <div ref={modalContainerRef}>
          <Button onClick={() => setOpen2(true)}>See ingredients</Button>
          {modalContainerRef?.current && (
            <Modal
              parentSelector={() => modalContainerRef?.current!}
              open={open2}
              aria-label='Modal demo'
              onClose={() => setOpen2(!open2)}
            >
              <Modal.Content>
                <Panel>
                  <Heading spacing level='1'>
                    This recipe needs:
                  </Heading>
                  <ul>
                    <li>Flour</li>
                    <li>Eggs</li>
                    <li>Sugar</li>
                  </ul>
                  <BodyLong>
                    Note that this modal does inherit theme styles, as it is being attached to the parent's{' '}
                    <code>DIV</code> element.
                  </BodyLong>
                </Panel>
              </Modal.Content>
            </Modal>
          )}
        </div>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='modal-box' level='2' spacing>
        Modal content as a <code>Box</code>
      </Heading>

      <VerticalSpace />
      <BodyLong spacing>
        The <code>Modal.Content</code> component is actually a <code>Box</code> component, which means that
        you can use the <code>Header</code>, <code>Body</code> and <code>Footer</code> sub-components and
        their props.
      </BodyLong>
      <BodyLong spacing>
        This is handy when you want your modal to have a nice place for a title, and action buttons at the
        bottom of the modal.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { Modal } from '@cegal/ds-components'

const Component = () => {
  const modalContainerRef = useRef(null)  
  const [openState, setOpenState]= useState<boolean>(false)
  return (
    <div ref={modalContainerRef}>
      <Button onClick={() => setOpenState(true)}>
        See ingredients
      </Button>
      {modalContainerRef?.current && (
        <Modal
          parentSelector={() => modalContainerRef?.current}
          open={openState}
          closeButton={false}
          onClose={() => setOpenState(!openState)}
        >
          <Modal.Content>
            <Modal.Content.Header>  
              <Heading spacing level='3' size='medium'>
                Ingredients for chocolate cake
              </Heading>
            </Modal.Content.Header>
            <Modal.Content.Body>
              <BodyShort>You will need: </BodyShort>
              <ul>
                <li>4 eggs</li>
                <li>4 soup spoons of powdered chocolate</li>
                <li>3 teacups of flour</li>
                <li>2 soup spoons of baking powder</li>
                <li>2 soup spoons of butter</li>
                <li>2 teacups of sugar</li>
                <li>1 teacup of milk</li>
              </ul>
            </Modal.Content.Body>
            <Modal.Content.Footer style={{textAlign: 'end'}}>
              <Button 
                size='small' 
                onClick={() => setOpen3(!open3)}
              >
                Ok, got it
              </Button>
            </Modal.Content.Footer>
          </Modal.Content>
        </Modal>
      )}
    </div>
  )
}`}
      >
        <div ref={modalContainerRef}>
          <Button onClick={() => setOpen3(true)}>See ingredients</Button>
          {modalContainerRef?.current && (
            <Modal
              parentSelector={() => modalContainerRef.current!}
              open={open3}
              closeButton={false}
              onClose={() => setOpen3(!open3)}
            >
              <Modal.Content>
                <Modal.Content.Header>
                  <Heading spacing level='3' size='medium'>
                    Ingredients for chocolate cake
                  </Heading>
                </Modal.Content.Header>
                <Modal.Content.Body>
                  <BodyShort>You will need: </BodyShort>
                  <ul>
                    <li>4 eggs</li>
                    <li>4 soup spoons of powdered chocolate</li>
                    <li>3 teacups of flour</li>
                    <li>2 soup spoons of baking powder</li>
                    <li>2 soup spoons of butter</li>
                    <li>2 teacups of sugar</li>
                    <li>1 teacup of milk</li>
                  </ul>
                </Modal.Content.Body>
                <Modal.Content.Footer style={{ textAlign: 'end' }}>
                  <Button size='small' onClick={() => setOpen3(!open3)}>
                    Ok, got it
                  </Button>
                </Modal.Content.Footer>
              </Modal.Content>
            </Modal>
          )}
        </div>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>ModalProps</code> uses the
        <code>ReactModal</code> component from the{' '}
        <Link href='https://www.npmjs.com/package/react-modal'>react-modal package</Link>.
      </BodyLong>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>aria-describedby</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets aria describedby value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>aria-label</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets aria label value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>aria-labelledby</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets aria labelledby value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>aria-modal</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets aria modal value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>closeButton</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds a close button to the modal</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onClose</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>() =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback function when modal closes</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>open</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets model open state</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>parentSelector</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>() =&gt; HTMLElement</code>
              </Table.DataCell>
              <Table.DataCell>Set a parent node to attach modal</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>body</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>shouldCloseOnOverlayClick</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Set if modal should close with overlay click</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsHome
