import { Alert, BodyLong, Content, Heading, Link, VerticalSpace } from '@cegal/ds-components'
import React from 'react'

const Contribute = () => {
  return (
    <Content>
      <VerticalSpace />
      <Heading size='large' level='1'>
        Contribute
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        The Cegal design system aims to serve the needs for Cegal developers. As such, we welcome all kinds of
        feedback and contributions from everyone.
      </BodyLong>

      <BodyLong spacing>This is how ou can help us to improve this app and the design system.</BodyLong>

      <VerticalSpace />

      <Heading className='toc' size='medium' id='contribute-feedback' level='2' spacing>
        Give us your feedback
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        We plan to be active at{' '}
        <Link href='https://cegal.workplace.com/groups/designsystem'>Workspace's design system group</Link>,
        where we can discuss any topic you wish.
      </BodyLong>

      <BodyLong spacing>
        If you want to reach us out in a more private way, find us on Teams (nuno.cardoso@cegal.com,
        sarah.e.reyfuse@cegal.com and kristina.teigland@cegal.com).
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='contribute-code' level='2' spacing>
        Get familiar with our code
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        If you want to get more familiar with the design system's code, the{' '}
        <Link href='//github.com/sysco-middleware/-cegal'>Cegal design system's Github repo</Link> is
        available for you to clone and browse. This is the place to start if you want to get more technical.
      </BodyLong>

      <Alert>
        You need to be a member of the{' '}
        <Link href='//github.com/sysco-middleware/'>sysco-middleware github organization</Link> to be able to
        access the repository and issue tracker. If you are not part of the organization, you can ask one of
        the design system's administrators to be added.
      </Alert>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='contribute-try' level='2' spacing>
        Give us a try on your existing project or new project
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        If you are planning to modernize the UI of your apps, or just starting a new one, consider the use of
        this design system to help bootstrap the UI. We have two themes, one suited for Cegal internal apps,
        but also another (neutral) theme for client apps.
      </BodyLong>

      <BodyLong spacing>
        You can easily toggle between themes or even extend to create your own theme, to fit your preferences.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='contribute-issues' level='2' spacing>
        Report bugs
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        If you want to report bugs or request improvements, the{' '}
        <Link href='//github.com/sysco-middleware/-cegal/issues'>issue tracker on Github</Link> is the best
        place to keep track of your issue. Be sure to give enough information to replicate the problem, or
        give enough context to understand what a desired improvement should address
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='contribute-word' level='2' spacing>
        Spread the word
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        Talk to other Cegal developers and let them know about this design system. Spread the word and help us
        reach everyone that can benefit from this system.
      </BodyLong>

      <VerticalSpace size='3' />
    </Content>
  )
}

export default Contribute
