import { BodyLong, Content, Heading, Link, VerticalSpace } from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import React from 'react'

const Accessibility = () => {
  return (
    <Content>
      <VerticalSpace />
      <Heading size='large'>Accessibility</Heading>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' level='2' id='a11y' spacing>
        A11y compliance
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        For React, there is a handy tool,
        <Link target='_blank' href='https://github.com/dequelabs/axe-core-npm/' rel='noreferrer'>
          @axe-core/react
        </Link>
        , that will console log accessibility problems detected in dom. Import it like this:
      </BodyLong>

      <CodeDiv spacing expand={false}>
        {`var React = require('react');
var ReactDOM = require('react-dom');

if (process.env.NODE_ENV !== 'production') {
var axe = require('@axe-core/react.');
axe(React, ReactDOM, 1000);
}`}
      </CodeDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' level='2' id='WCAG Guidelines' spacing>
        WCAG Guidelines
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        When designing/developing one should be mindful to follow WCAG guidelines (
        <Link href='https://www.w3.org/TR/WCAG22/'> www.w3.org</Link>) so that applications are accessible for
        everyone.
      </BodyLong>
      <VerticalSpace size='2' />
      <Heading size='small' level='3' id='Contrast'>
        Contrast
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        The following are some guidelines about contrast (must meet these standards to comply to WCAG minimum
        requirements):
      </BodyLong>
      <ul>
        <li>Contrast for regular text or images with text must have a contrast of 4.5:1.</li>
        <li>Contrast for Large text or components must be 3:1.</li>
        <li>Text that is part of a Logo (ex. company Logo) has no contrast requirements.</li>
        <li>Disabled components or purely decorative images also have no contrast requirements.</li>
      </ul>
      <VerticalSpace size='2' />
      <Heading size='small' level='3' id='Text'>
        Text
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>The following are some guidelines about text:</BodyLong>
      <ul>
        <li>Text should be resizable up to 200x by assistive technology.</li>
        <li>
          Choose fonts that are easily readable (ex. Tahoma, Calibri, Helvetica, Arial, Verdana, and Times New
          Roman).
        </li>
        <li>Line height (line spacing) to at least 1.5 times the font size.</li>
        <li>Spacing following paragraphs to at least 2 times the font size.</li>
        <li>Letter spacing (tracking) to at least 0.12 times the font size.</li>
        <li>Word spacing to at least 0.16 times the font size.</li>
      </ul>
      <VerticalSpace size='2' />
      <Heading size='small' level='3' id='Images'>
        Images
      </Heading>
      <VerticalSpace size='2' />
      <ul>
        <li>
          Images that include information (whose purpose is not purely decoration) must include descriptive
          text.
        </li>
      </ul>
      <VerticalSpace size='2' />
      <Heading size='small' level='3' id='Colours'>
        Colours
      </Heading>
      <VerticalSpace size='2' />
      <ul>
        <li>
          Colour should not be the only means of conveying information. For example: showing validation errors
          or hover actions, which can be done using thicker borders and contrast.
        </li>
      </ul>
      <VerticalSpace size='2' />
      <Heading size='small' level='3' id='General Guidelines'>
        General Guidelines
      </Heading>
      <VerticalSpace size='2' />
      <ul>
        <li>
          Avoid text/images/components that move or flash (especially if it flashes more than 3 times per
          second or lasts longer than 5 seconds). When using flashing, we must include an option to stop/pause
          it.
        </li>
        <li>Pointer targetable area should be at least 24px by 24px. </li>
        <li>
          Elements that are repeated in multiple areas of an application should be consistent and in the same
          order unless changed by the user.
        </li>
        <li>
          When an authenticated session expires, the user can continue the activity without loss of data after
          re-authenticating.
        </li>
      </ul>
      <VerticalSpace />
      <BodyLong spacing>
        When there are legal commitments or transactions involved at least one the following criteria must be
        met:
      </BodyLong>
      <ul>
        <li>Reversible - user can undo action.</li>
        <li>Data input by the user is checked for errors and provided the opportunity to edit them.</li>
        <li>A mechanism is provided for the user to review, confirm or correct data before submission.</li>
      </ul>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' level='2' id='arias' spacing>
        Aria tags
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        ARIA supplements HTML so that interactions and widgets commonly used in applications can be passed to
        assistive technologies when there is no other way.
      </BodyLong>

      <BodyLong spacing>The design system adds proper aria tags for its components.</BodyLong>
      <BodyLong spacing>But if you need to add your own components, try to add these aria tags.</BodyLong>

      <BodyLong spacing>
        <Link href='https://web.dev/semantics-aria/'>https://web.dev/semantics-aria/</Link>
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='Accessibility tools' spacing>
        Accessibility Tools
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        there is a handy online tool for checking contrast of text and UI components, in{' '}
        <Link href='https://webaim.org/resources/contrastchecker/'>WebAIM Contrast Checker</Link>.
      </BodyLong>

      <BodyLong spacing>Chrome Extensions that can be useful to check accessibility:</BodyLong>
      <ul>
        <li>Chrome Vox - Screen reader</li>
        <li>Wave Evaluation Tool - Evaluate web accessibility within your browser.</li>
        <li>Google Lighthouse - Google's web accessibility Checker.</li>
        <li>Colorblindly - visual imparement simulator.</li>
      </ul>
    </Content>
  )
}

export default Accessibility
