import { Alert, BodyLong, Nav, Heading, HorizontalSpace, VerticalSpace } from '@cegal/ds-components'
import FavIconLogoBlackGreen from '@cegal/ds-logos/favicon/Favicon-black-green.png'
import FavIconLogoBlackWhite from '@cegal/ds-logos/favicon/Favicon-black-white.png'
import LogoPngCegalBlack from '@cegal/ds-logos/logos/png/cegal-black.png'
import LogoPngCegalWhite from '@cegal/ds-logos/logos/png/cegal-white.png'
// @ts-ignore
import { ReactComponent as LogoSvgCegalBlack } from '@cegal/ds-logos/logos/svg/CegalBlack.svg'
// @ts-ignore
import { ReactComponent as LogoSvgCegalWhite } from '@cegal/ds-logos/logos/svg/CegalWhite.svg'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import React from 'react'

const ResourcesLogos = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large'>Logos</Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>@cegal/ds-logos</code> package contains all logos and favicons from Cegal.
      </BodyLong>

      <Heading className='toc' size='medium' level='2' id='favicons' spacing>
        Favicons
      </Heading>
      <VerticalSpace size='2' />

      <Heading className='toc' size='small' level='3' id='favicons-black' spacing>
        Black / green
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import FavIconLogoBlackGreen from '@cegal/ds-logos/favicon/Favicon-black-green.png' 
              
export default () => (<img alt='favicon' width='24' src={FavIconLogoBlackGreen}/>)
        `}
      >
        <img alt='favicon' width='24' src={FavIconLogoBlackGreen} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' level='3' id='favicons-white' spacing>
        Black / white
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import FavIconLogoBlackGreen from '@cegal/ds-logos/favicon/Favicon-black-white.png' 
              
export default () => (<img alt='favicon' width='24' src={FavIconLogoBlackWhite}/>)
        `}
      >
        <img alt='favicon' width='24' src={FavIconLogoBlackWhite} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='png' spacing>
        PNG logos
      </Heading>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' level='3' id='png-black' spacing>
        Cegal black
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import LogoPngCegalBlack from '@cegal/ds-logos/png/cegal-black.png'
              
export default () => ( <img alt='logo' width='170' src={LogoPngCegalBlack} />)
        `}
      >
        <img alt='logo' width='170' src={LogoPngCegalBlack} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' level='3' id='png-white' spacing>
        Cegal white
      </Heading>

      <DemoableDiv
        alignContent='center'
        initialTheme='cegal-dark'
        code={`import LogoPngCegalWhite from '@cegal/ds-logos/png/cegal-white.png'
              
export default () => ( <img alt='logo' width='170' src={LogoPngCegalWhite} />)
        `}
      >
        <img alt='logo' width='170' src={LogoPngCegalWhite} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='svg' spacing>
        SVG logos
      </Heading>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' level='3' id='svg-black' spacing>
        Cegal black
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import { ReactComponent as LogoSvgCegalBlack } from '@cegal/ds-logos/svg/CegalBlack.svg' 
     
export default () => ( <LogoSvgCegalBlack width='170'/>)
        `}
      >
        <LogoSvgCegalBlack width='170' />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' level='3' id='svg-white' spacing>
        Cegal white
      </Heading>

      <DemoableDiv
        initialTheme='cegal-dark'
        alignContent='center'
        code={`import { ReactComponent as LogoSvgCegalWhite } from '@cegal/ds-logos/svg/CegalWhite.svg' 
     
export default () => ( <LogoSvgCegalWhite width='170'/>)
        `}
      >
        <LogoSvgCegalWhite width='170' />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Alert variant='info'>
        If you need to switch between black/white Cegal logos with light/dark modes, then add{' '}
        <code>class='logo'</code> into the logo element. If inside components like <code>Nav</code> or{' '}
        <code>Header</code>, the color switch will be done for you.
      </Alert>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { ReactComponent as LogoSvgCegalWhite } 
  from '@cegal/ds-logos/svg/CegalWhite.svg' 
import { ReactComponent as LogoSvgCegalBlack } 
  from '@cegal/ds-logos/svg/CegalBlack.svg'

const Component = () => ( 
  <Nav>
    <LogoSvgCegalWhite class='logo' width='170' />
    <HorizontalSpace/>
    <LogoSvgCegalBlack class='logo' width='170' />
    <BodyLong>
    These logos will always have the right text color, 
    because of the class <code>logo</code> and we are inside a Nav
    </BodyLong>
  </Nav>
)`}
      >
        <Nav>
          <LogoSvgCegalWhite class='logo' width='170' />
          <HorizontalSpace />
          <LogoSvgCegalBlack class='logo' width='170' />
          <BodyLong>
            These logos will always have the right text color, because of the class <code>logo</code> and we
            are inside a Nav
          </BodyLong>
        </Nav>
      </DemoableDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ResourcesLogos
