import {
  BodyLong,
  BodyShort,
  FlexCenterDiv,
  FlexDiv,
  Heading,
  HelpText,
  HorizontalSpace,
  Link,
  Select,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import { Placement } from '@popperjs/core'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsHelpText = () => {
  const [placement, setPlacement] = useState<Placement>('top')

  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='helptext' level='1' spacing>
        Help text
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>Help text</code> component is a useful button that displays a popover message. It works best
        on form fields that require a more detailed explanation, placed after the form field label.
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='helptext-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { HelpText } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexDiv>
      <BodyShort>
      Add 3 tablespoons of sugar
      </BodyShort>
      <HorizontalSpace size='0.5' />
       <HelpText title="Recipe name" placement="top">
         A metric tablespoon is exactly equal to 15 ml (0.51 US fl oz)
       </HelpText>
     </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <BodyShort>Add 3 tablespoons of sugar</BodyShort>
          <HorizontalSpace size='0.5' />
          <HelpText title='Recipe name' placement='top'>
            A metric tablespoon is exactly equal to 15 ml (0.51 US fl oz)
          </HelpText>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='helptext-placement' level='2' spacing>
        Popover placement
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The HelpText <code>placement</code> prop is a <code>Placement</code> type from{' '}
        <Link href='https://www.npmjs.com/package/@popperjs/core'>@popperjs/core</Link>, and can be one of the
        following 12 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        {`export type Placement = 'top' | 'bottom' | 'right' | 'left' | 'top-start' | 'top-end' |
   'bottom-start' | 'bottom-end' | 'right-start' | 'right-end' | 'left-start' | 'left-end'`}
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>HelpTextPlacement</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { HelpText } from '@cegal/ds-components'

const Component = () => {
  const [placement, setPlacement] = useState<HelpTextPlacement>('top')
  return (
    <FlexBaseDiv>
      <Select 
        onChange={(e: any) => setPosition(e.target.value)} 
        value={position}
      >
        <option>top</option>
        <option>bottom</option>
        <option>right</option>
        <option>left</option>
        <option>top-start</option>
        <option>top-end</option>
        <option>bottom-start</option>
        <option>bottom-end</option>
        <option>right-start</option>
        <option>right-end</option>
        <option>left-start</option>
        <option>left-end</option>
      </Select>
      <HorizontalSpace/>
      <HelpText placement={placement}>
        {/*  eslint-disable-line */ \`this is a ${placement} placement\`}
      </HelpText>
    </FlexBaseDiv>
  )
}`}
      >
        <FlexCenterDiv>
          <Select
            label='placement'
            hideLabel
            onChange={(e: any) => setPlacement(e.target.value)}
            value={placement}
          >
            <option>top</option>
            <option>bottom</option>
            <option>right</option>
            <option>left</option>
            <option>top-start</option>
            <option>top-end</option>
            <option>bottom-start</option>
            <option>bottom-end</option>
            <option>right-start</option>
            <option>right-end</option>
            <option>left-start</option>
            <option>left-end</option>
          </Select>
          <HorizontalSpace />
          <HelpText placement={placement}>{`this is a ${placement} placement`}</HelpText>
        </FlexCenterDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='helptext-variant' level='2' spacing>
        Popover variant
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>variant</code> prop is passed to the <code>Popover</code> component, which can have 2
        values.
      </BodyLong>

      <CodeDiv expand={false} spacing>
        {`export type PopoverVariant = 'primary' | 'secondary'`}
      </CodeDiv>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { HelpText } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexDiv>
      <HelpText title="Help text variant" variant='primary'>
        this is a primary variant
      </HelpText>
      <HorizontalSpace/>
      <HelpText title="Help text variant" variant='secondary'>
        this is a secondary variant
      </HelpText>
    </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <HelpText title='Help text variant' variant='primary'>
            this is a primary variant
          </HelpText>
          <HorizontalSpace />
          <HelpText title='Help text variant' variant='secondary'>
            this is a secondary variant
          </HelpText>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>HelpTextProps</code> extends <code>React.HTMLAttributes&lt;HTMLButtonElement&gt;</code>, as
        the top level uses a <code>button</code> tag. As such, you can pass <code>button</code> props from{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLButtonElement'>
          HTMLButtonElement
        </Link>{' '}
        interface, such as <code>type</code>,{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props
        such as <code>style</code>, as well as{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/UIEvent'>UI events</Link> such as{' '}
        <code>onClick</code>. They will all be passed to the <code>&lt;button&gt;</code> element.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>title</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets the help text title for screen readers</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>hjelp</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>placement</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>
                  'top' | 'bottom' | 'right' | 'left' | 'top-start' | 'top-end' | 'bottom-start' |
                  'bottom-end' | 'right-start' | 'right-end' | 'left-start' | 'left-end'
                </code>
              </Table.DataCell>
              <Table.DataCell>Sets popover placement</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>top</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>popoverAnchorEl</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>Element</code>
              </Table.DataCell>
              <Table.DataCell>
                Sets popover anchor element. Set this to an anchor element if you are having trouble with
                popover positioning.
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>null</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'primary' | 'secondary'</code>
              </Table.DataCell>
              <Table.DataCell>Sets popover variant.</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>primary</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsHelpText
