import {
  Heading,
  BodyLong,
  PileCenterDiv,
  TextField,
  Link,
  VerticalSpace,
  Pagination,
  Table
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsPagination = () => {
  const [page1, setPage1] = useState<number>(1)
  const [page2, setPage2] = useState<number>(1)
  const [page3, setPage3] = useState<number>(1)
  const [page4, setPage4] = useState<number>(1)
  const [page5, setPage5] = useState<number>(1)
  const [count1, setCount1] = useState<number>(1)
  const [count2, setCount2] = useState<number>(1)
  return (
    <>
      <VerticalSpace size='3' />
      <Heading size='large' id='pagination' level='1' spacing>
        Pagination
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>Pagination</code> component goes along with tables or other list elements that need to show
        only a subset of elements at a time.
      </BodyLong>

      <BodyLong spacing>
        You need to set at least <code>count</code>, <code>page</code> and <code>onPageChange</code> props so
        you can have a working component.
      </BodyLong>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { Pagination } from '@cegal/ds-components'

const Component = () => {
  const [page, setPage] = useState<number>(1)
  return (
    <Pagination
      count={9} 
      page={page}
      onPageChange={setPage}
    />
  )
}`}
      >
        <Pagination count={9} page={page1} onPageChange={setPage1} />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-sizes' level='2' spacing>
        Sizes
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 3 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type PaginationSize = 'medium' | 'small' | 'xsmall'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>PaginationSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Pagination } from '@cegal/ds-components'

const Component = () => {
  const [page, setPage] = useState<number>(1)
  return (
    <>
      <Pagination 
        size='medium' 
        count={9} 
        page={page} 
        onPageChange={setPage}
      />
      <Pagination 
        size='small' 
        count={9} 
        page={page} 
        onPageChange={setPage}
      />
      <Pagination 
        size='xsmall' 
        count={9} 
        page={page} 
        onPageChange={setPage}
      />
    </>
  )
}`}
      >
        <Pagination size='medium' count={9} page={page2} onPageChange={setPage2} />
        <Pagination size='small' count={9} page={page2} onPageChange={setPage2} />
        <Pagination size='xsmall' count={9} page={page2} onPageChange={setPage2} />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-texts' level='2' spacing>
        Labels
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>You can add labels next to the button arrows.</BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Pagination } from '@cegal/ds-components'

const Component = () => {
  const [page, setPage] = useState<number>(1)
  return (
    <Pagination 
      prevNextTexts 
      count={9} 
      page={page} 
      onPageChange={setPage}
    />
  )
}`}
      >
        <Pagination prevNextTexts count={9} page={page3} onPageChange={setPage3} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='pagination-counts' level='2' spacing>
        Boundary and sibling counts
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can set how many buttons should be visible at the beginning and end, with the{' '}
        <code>boundaryCount</code> prop. The default is 1, see the example below
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Pagination } from '@cegal/ds-components'

const Component = () => {
  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  return (
    <PileCenterDiv>
      <TextField 
         type='number'
          min='1'
          max='3'
         onChange={(e: any) => 
           setCount(parseInt(e.target.value))} 
         value={count.toString()} 
      />  
      <VerticalSpace/>
      <Pagination 
        boundaryCount={count}
        prevNextTexts 
        count={20} 
        page={page} 
        onPageChange={setPage}
      />
    </PileCenterDiv>
  )
}`}
      >
        <PileCenterDiv>
          <TextField
            label='Boundary count'
            type='number'
            min='1'
            max='3'
            onChange={(e: any) => setCount1(parseInt(e.target.value))}
            value={count1.toString()}
          />
          <VerticalSpace />
          <Pagination boundaryCount={count1} prevNextTexts count={20} page={page4} onPageChange={setPage4} />
        </PileCenterDiv>
      </DemoableDiv>

      <BodyLong spacing>
        You can set how many buttons should be visible around the current page, with the <code>sibling</code>{' '}
        prop. The default is 1, see the example below.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Pagination } from '@cegal/ds-components'

const Component = () => {
  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  return (
    <PileCenterDiv>
      <TextField 
         type='number'
          min='1'
          max='3'
         onChange={(e: any) => 
           setCount(parseInt(e.target.value))} 
         value={count.toString()} 
      />  
      <VerticalSpace/>
      <Pagination 
        siblingCount={count}
        prevNextTexts 
        count={20} 
        page={page} 
        onPageChange={setPage}
      />
    </PileCenterDiv>
  )
}`}
      >
        <PileCenterDiv>
          <TextField
            label='Sibling count'
            type='number'
            min='1'
            max='3'
            onChange={(e: any) => setCount2(parseInt(e.target.value))}
            value={count2.toString()}
          />
          <VerticalSpace />
          <Pagination siblingCount={count2} prevNextTexts count={20} page={page5} onPageChange={setPage5} />
        </PileCenterDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>PaginationProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLElement&gt;</code>, as the top level uses a <code>nav</code> tag. As
        such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props.
      </BodyLong>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>boundaryCount</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Number of always visible pages at the beginning and end</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>1</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>count</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Total number of pages</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onPageChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(page: number) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>callback function called when page changes</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>page</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Current pagination page</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>prevNextTexts</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds text labels next to button icons</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>renderItem</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(item: PaginationItemProps) =&gt; ReturnType&lt;React.FC&gt;</code>
              </Table.DataCell>
              <Table.DataCell>Overrides the page item renderer</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>siblingCount</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>
                Number of always visible pages before and after the current page
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>1</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small' | 'xsmall'</code>
              </Table.DataCell>
              <Table.DataCell>Pagination size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsPagination
