import {
  Avatar,
  BodyLong,
  Dropzone,
  DropzoneFile,
  FlexDiv,
  FlexCenterDiv,
  Heading,
  Link,
  PileDiv,
  HorizontalSpace,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsDropzone = () => {
  const [files, setFiles] = useState<Array<DropzoneFile>>([])
  const [file, setFile] = useState<DropzoneFile | undefined>(undefined)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='Dropzone' level='1' spacing>
        Dropzone
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        The <code>Dropzone</code> component renders a droppable area where user can add drag files into.
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Dropzone-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The default <code>Dropzone</code> component accepts multiple files of any type and size.
      </BodyLong>

      <DemoableDiv
        alignContent='inherit'
        code={`import { Dropzone } from '@cegal/ds-components'

const Component = () => {
  return <Dropzone />
}`}
      >
        <Dropzone />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Dropzone-controlled' level='2' spacing>
        Controlling
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        To access the files being dropped <code>Dropzone</code>, use the <code>onDrop</code> prop.
      </BodyLong>

      <BodyLong spacing>
        You cannot set an initial file list, as the component's role is to receive uploaded files. Also note
        that the <code>Dropzone</code> component doesn't have a state, so it forgets what happened with
        previously dropped files.
      </BodyLong>

      <BodyLong spacing>
        The <code>onDrop</code> callback function returns <code>Array&lt;DropzoneFile&gt;</code>. The{' '}
        <code>DropzoneFile</code> structure is as follows:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        {`export interface DropzoneFile {
  size: number
  name: string
  mimetype: string
  content: string
  lastModified: number
  lastModifiedDate: Date
}`}
      </CodeDiv>

      <BodyLong spacing>
        The <code>content</code> string is the uploaded file encoded as base64, like the example below:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        data:application/pdf;base64,JVBERi0xLjUKJYCBgoMKMSAwIG9iago8PC9GaWx(...)
      </CodeDiv>

      <DemoableDiv
        alignContent='inherit'
        code={`import { Dropzone } from '@cegal/ds-components'

const Component = () => {
  const [files, setFiles] = useState<Array<DropzoneFile>>([])
  return (
    <PileDiv>
      <Dropzone 
        onDrop={setFiles}
      />
      <VerticalSpace/>
      <BodyLong>
        You uploaded {files.length} file(s).
      </BodyLong>
    </PileDiv>
  )
}`}
      >
        <PileDiv>
          <Dropzone onDrop={setFiles} />
          <VerticalSpace />
          <BodyLong>You uploaded {files.length} file(s).</BodyLong>
        </PileDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Dropzone-accept' level='2' spacing>
        Filtering by file types
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can restrict which file types can be accepted with the <code>accept</code> prop, a JS object with
        mimetype as keys, file extensions in array of values.
      </BodyLong>

      <CodeDiv expand={false} spacing>
        {`<Dropzone
  accept={{
   'image/jpeg': ['.jpeg', '.jpg'],  
   'image/png': ['.png']  
  }}
/>`}
      </CodeDiv>

      <VerticalSpace />

      <BodyLong spacing>
        The example below takes only one image file, and updates the picture on the right.
      </BodyLong>

      <DemoableDiv
        alignContent='inherit'
        code={`import { Dropzone } from '@cegal/ds-components'

const Component = () => {
  const [file, setFile] = 
    useState<DropzoneFile | undefined>(undefined)
  return (
    <FlexCenterDiv>
      <Dropzone 
      
        multiple={false}
        accept={{
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          'image/gif': ['.gif']
        }}
        onDrop={(files: Array<DropzoneFile>) => 
          setFile(files?.[0]) 
        }
      />
      <HorizontalSpace/>
      <Avatar 
        variant='square' 
        size='xlarge' 
        src={file?.content}
      />
    </FlexCenterDiv>  
  )
}`}
      >
        <FlexCenterDiv style={{ width: '100%' }}>
          <FlexDiv style={{ flex: '4' }}>
            <Dropzone
              style={{ flex: '4' }}
              multiple={false}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': ['.png'],
                'image/gif': ['.gif']
              }}
              onDrop={(files: Array<DropzoneFile>) => setFile(files?.[0])}
            />
          </FlexDiv>
          <FlexDiv>
            <HorizontalSpace />
            <Avatar variant='square' size='xlarge' src={file?.content} />
          </FlexDiv>
        </FlexCenterDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Dropzone-single' level='2' spacing>
        Single file drop
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        To limit the <code>Dropzone</code> component to a single dropped file, set the <code>multiple</code>{' '}
        prop to <code>false</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='inherit'
        code={`import { Dropzone } from '@cegal/ds-components'

const Component = () => {
  return (
    <Dropzone multiple={false}/>    
  )
}`}
      >
        <Dropzone multiple={false} />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Dropzone-maxsize' level='2' spacing>
        Limit number of files
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        To limit the maximum number of files, set the <code>maxFiles</code> prop to the number you want.
      </BodyLong>

      <DemoableDiv
        alignContent='inherit'
        code={`import { Dropzone } from '@cegal/ds-components'

const Component = () => {
  return (
    <Dropzone maxFiles={2}/>    
  )
}`}
      >
        <Dropzone maxFiles={2} />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Dropzone-maxfilesize' level='2' spacing>
        Max file size
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        To limit the maximum size of dropped files, set the <code>maxFileSize</code> prop to the number you
        want, in bytes.
      </BodyLong>

      <DemoableDiv
        alignContent='inherit'
        code={`import { Dropzone } from '@cegal/ds-components'

const Component = () => {
  return (
    <Dropzone maxFileSize={1000000}/>    
  )
}`}
      >
        <Dropzone maxFileSize={1000000} />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Dropzone-labels' level='2' spacing>
        Customize labels
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can customize labels by passing to the <code>label</code> props an object with only the key/values
        you want to override. The labels you don't override will be using the following values:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        {`const labels = {
  all: 'alle',
  accepted: 'Accepted',
  size: 'Size',
  dropFilesHere: 'Click here to drop files',
  allowedFileFormat: 'Allowed file format {{filFormat}}',
  file: 'file',
  files: 'files',
  singleFile: 'only single file',
  multipleFiles: 'multiple files allowed',
  maxFiles: 'max {{maxFiles}} {{file}}',
  maxFileSize: 'max size {{maxFileSize}}',
  fileIsTooBigLimitIs: 'File is too big ({{size}}), limit is {{maxFileSize}}',
  maxFilesExceeded: 'Too many files, max {{maxFiles}} {{file}}',
  invalidFileType: 'Not a file of type {{filFormat}}',
  rejected: 'Rejected',
  removed: 'Removed',
  total: 'Total'
}`}
      </CodeDiv>

      <DemoableDiv
        alignContent='inherit'
        code={`import { Dropzone } from '@cegal/ds-components'

const Component = () => {
  return (
    <Dropzone 
      labels={{dropFilesHere: 'Upload images, docs, etc...'}}
    />    
  )
}`}
      >
        <Dropzone labels={{ dropFilesHere: 'Upload images, docs, etc...' }} />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>DropzoneProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLDivElement&gt;</code>, as the top level component is a{' '}
        <code>Panel</code> component as a <code>div</code>. As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLDivElement'>HTMLDivElement</Link>{' '}
        props.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>accept</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>Accept</code>
              </Table.DataCell>
              <Table.DataCell>Restrict dropping to certain mimetypes / file extensions</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>labels</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>Record&lt;string, string&gt;</code>
              </Table.DataCell>
              <Table.DataCell>Override default labels</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>messages</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Render feedback messages</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>maxFiles</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Limit number of files per drop</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>maxFilesSize</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Limit max size of dropped files</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>multiple</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Allow multiple file drop</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onDrop</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>
                  (files: Array&gt;DropzoneFile&lt;, acceptedFiles: Array&gt;globalThis.File&lt;,
                  rejectedFiles: Array&gt;FileRejection&lt;) =&lt; void)
                </code>
              </Table.DataCell>
              <Table.DataCell>Callback function with dropped accepted files</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsDropzone
