import {
  BodyLong,
  Column,
  Container,
  Content,
  FlexDiv,
  Header,
  Heading,
  Label,
  Link,
  Margin,
  Nav,
  PileCenterDiv,
  Row,
  Select,
  VerticalSpace
} from '@cegal/ds-components'
// @ts-ignore
import { ReactComponent as CegalBlackLogo } from '@cegal/ds-logos/logos/svg/CegalBlack.svg'
// @ts-ignore
import { ReactComponent as CegalWhiteLogo } from '@cegal/ds-logos/logos/svg/CegalWhite.svg'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import React, { useState } from 'react'

const ComponentsContainers = () => {
  const [theme, setTheme] = useState('cegal-light')

  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='containers' level='1' spacing>
        Containers
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        Containers is a collection of styled components meant for building page layouts.
      </BodyLong>

      <BodyLong spacing>
        The <Link href='/resources/spacing'>spacing section of the Resources page</Link> has a better overview
        on how you can combine grid components to create page layouts. This page centers more on code examples
        and property details
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='containers-container' level='2' spacing>
        Container
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        A <code>Container</code> component is a styled <code>div</code> element that sets text and background
        colors, font styles and sizes, line heights and flex flows. You should use a <code>Container</code>{' '}
        comnponent to wrap your app contents, so the theme is properly applied.
      </BodyLong>

      <BodyLong spacing>
        In the demo below, we are changing the <code>data-theme</code> to the Container to showcase how the{' '}
        <code>Container</code> component sets some font and colour values.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Container } from '@cegal/ds-components'

const Component = () => {
  const [theme, setTheme] = useState('cegal-light')
  return (
    <Container 
      data-theme={theme} 
      style={{padding: '2rem'}}
    >
      <BodyLong>
        Current theme: {theme}
      </BodyLong>
      <Select 
        value={theme} 
        onChange={(e: any) => setTheme(e.target.value)}
      >
         <option>cegal-light</option>
         <option>cegal-dark</option>
         <option>neutral-light</option>
         <option>neutral-dark</option>
       </Select>
    </Container>
  )
}`}
      >
        <Container data-theme={theme} style={{ padding: '2rem' }}>
          <PileCenterDiv>
            <BodyLong>Current theme: {theme}</BodyLong>
            <Select label='theme' hideLabel value={theme} onChange={(e: any) => setTheme(e.target.value)}>
              <option>cegal-light</option>
              <option>cegal-dark</option>
              <option>neutral-light</option>
              <option>neutral-dark</option>
            </Select>
          </PileCenterDiv>
        </Container>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='containers-header' level='2' spacing>
        Header
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>Header</code> component is a non-growable flex box, recommended to be added on the page top.
        If you add a Cegal SVG logo inside the header, with a <code>logo</code> class, then the{' '}
        <code>Header</code> can correct the logo color to the right mode with light/dark theme changes.
      </BodyLong>

      <BodyLong spacing>
        In a nutshell, just use a SVG logo and add a <code>logo</code> class in a header, and the design
        system ensures it is properly colored, as shown below.
      </BodyLong>

      <DemoableDiv
        alignContent='inherit'
        code={`import { Header } from '@cegal/ds-components'

const Component = () => {
  return (
    <Header style={{padding: '2rem'}}>
      <Row>
        <Column/>
        <Column>
          <Label>Logo class</Label>
        </Column>
        <Column>
          <Label> no Logo class</Label>
        </Column>
      </Row>
      <VerticalSpace/>
      <Row>
        <Column>
          <Label>White logo</Label>
        </Column>
        <Column>
          <CegalWhiteLogo className='logo' />
        </Column>
        <Column>
          <CegalWhiteLogo className='notlogo' />
        </Column>
      </Row>
      <VerticalSpace/>
      <Row>
        <Column>
          <Label>Black logo</Label>
        </Column>
        <Column>
          <CegalBlackLogo className='logo' />
        </Column>
        <Column>
          <CegalBlackLogo className='notlogo'/>
        </Column>
      </Row>
    </Header>
  )
}`}
      >
        <Header style={{ padding: '2rem' }}>
          <Row>
            <Column />
            <Column>
              <Label>Logo class</Label>
            </Column>
            <Column>
              <Label> no Logo class</Label>
            </Column>
          </Row>
          <VerticalSpace />
          <Row>
            <Column>
              <Label>White logo</Label>
            </Column>
            <Column>
              <CegalWhiteLogo className='logo' />
            </Column>
            <Column>
              <CegalWhiteLogo className='notlogo' />
            </Column>
          </Row>
          <VerticalSpace />
          <Row>
            <Column>
              <Label>Black logo</Label>
            </Column>
            <Column>
              <CegalBlackLogo className='logo' />
            </Column>
            <Column>
              <CegalBlackLogo className='notlogo' />
            </Column>
          </Row>
        </Header>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='containers-nav' level='2' spacing>
        Nav
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>Nav</code> component is a styled <code>nav</code> element that also corrects the color of a
        Cegal SVG logo with a <code>logo</code> class, just like the <code>Header</code> component above.
      </BodyLong>

      <BodyLong spacing>
        You should add navigation elements inside a <code>Nav</code> component, like the
        <Link href='/components/navbar'>NavBar</Link> component.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Nav } from '@cegal/ds-components'

const Component = () => {
  return (
    <Nav>
       <CegalWhiteLogo 
         className='logo' 
         style={{ height: '2rem' }}
        />
    </Nav>
  )
}`}
      >
        <Nav>
          <CegalWhiteLogo className='logo' style={{ height: '2rem' }} />
        </Nav>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='containers-content' level='2' spacing>
        Content
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>Content</code> is a generic <code>div</code> element with a default padding. It's meant to
        be the container where you add your page content .
      </BodyLong>

      <BodyLong spacing>
        You can pass a custom <code>padding</code> prop (a number in rems) and/or a <code>flex</code> prop (a
        number). Note that <code>Content</code> does not set theme styles, therefore it will inherit the
        scoped data-theme.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Content } from '@cegal/ds-components'

const Component = () => {
  return (
    <Content padding='2'>
       <CegalWhiteLogo style={{ height: '2rem' }} />
       <CegalBlackLogo style={{ height: '2rem' }} />
    </Content>
  )
}`}
      >
        <Content padding='2'>
          <CegalWhiteLogo className='logo' style={{ height: '2rem' }} />
          <CegalBlackLogo style={{ height: '2rem' }} />
        </Content>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='containers-margin' level='2' spacing>
        Margin
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        Margin is a generic <code>div</code> element with a flex set to 1, to serve as margin gutters and
        letting the <code>Content</code> component to align in the center.
      </BodyLong>

      <BodyLong spacing>
        You can pass a custom <code>padding</code> prop (a number in rems) and/or a <code>flex</code> prop (a
        number).
      </BodyLong>

      <DemoableDiv
        code={`import { Margin } from '@cegal/ds-components'

const Component = () => {
  return (
     <Container>
        <FlexDiv>
          <Margin>Margin</Margin>
          <Content padding='0'>Content</Content>
          <Margin>Margin</Margin>
        </FlexDiv>
     </Container>
  )
}`}
      >
        <Container>
          <FlexDiv>
            <Margin>Margin</Margin>
            <Content padding='0'>Content</Content>
            <Margin>Margin</Margin>
          </FlexDiv>
        </Container>
      </DemoableDiv>
    </>
  )
}

export default ComponentsContainers
