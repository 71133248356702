import { Heading, VerticalSpace, BodyLong, Table, ToggleGroup } from '@cegal/ds-components'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { MarkEmailRead, MarkEmailUnread, Send } from '@cegal/ds-icons'
import React from 'react'
import { TableWrapperDiv } from 'components/styled'

const ComponentsToggleGroup = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='togglegroup' level='1' spacing>
        ToggleGroup
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        Toggle group component is a radio-group style component that asks user to choose one option, but in a
        more horizontal style with no need for big labels. Just like the <code>Tabs</code> component, but
        without the purpose of changing page content.
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='togglegroup-default' level='2' spacing>
        Default
      </Heading>
      <VerticalSpace size='2' />
      <DemoableDiv
        alignContent='center'
        code={`import { ToggleGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <ToggleGroup onChange={() => {}}>
      <ToggleGroup.Item value='unread'>Unread</ToggleGroup.Item>
      <ToggleGroup.Item value='read'>Read</ToggleGroup.Item>
      <ToggleGroup.Item value='sent'>Sent</ToggleGroup.Item>
    </ToggleGroup>
  )
}`}
      >
        <ToggleGroup onChange={() => {}}>
          <ToggleGroup.Item value='unread'>Unread</ToggleGroup.Item>
          <ToggleGroup.Item value='read'>Read</ToggleGroup.Item>
          <ToggleGroup.Item value='sent'>Sent</ToggleGroup.Item>
        </ToggleGroup>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='togglegroup-size' level='2' spacing>
        Size
      </Heading>
      <VerticalSpace size='2' />
      <DemoableDiv
        alignContent='center'
        code={`import { ToggleGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
    <ToggleGroup>
      <ToggleGroup.Item value='unread'>Unread</ToggleGroup.Item>
      <ToggleGroup.Item value='read'>Read</ToggleGroup.Item>
      <ToggleGroup.Item value='sent'>Sent</ToggleGroup.Item>
    </ToggleGroup>
    <VerticalSpace/>
    <ToggleGroup size='small'>
      <ToggleGroup.Item value='unread'>Unread</ToggleGroup.Item>
      <ToggleGroup.Item value='read'>Read</ToggleGroup.Item>
      <ToggleGroup.Item value='sent'>Sent</ToggleGroup.Item>
    </ToggleGroup>
    </>
  )
}`}
      >
        <ToggleGroup onChange={() => {}}>
          <ToggleGroup.Item value='unread'>Unread</ToggleGroup.Item>
          <ToggleGroup.Item value='read'>Read</ToggleGroup.Item>
          <ToggleGroup.Item value='sent'>Sent</ToggleGroup.Item>
        </ToggleGroup>
        <VerticalSpace />
        <ToggleGroup onChange={() => {}} size='small'>
          <ToggleGroup.Item value='unread'>Unread</ToggleGroup.Item>
          <ToggleGroup.Item value='read'>Read</ToggleGroup.Item>
          <ToggleGroup.Item value='sent'>Sent</ToggleGroup.Item>
        </ToggleGroup>
      </DemoableDiv>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='togglegroup-icons' level='2' spacing>
        Icons
      </Heading>
      <VerticalSpace size='2' />
      <DemoableDiv
        alignContent='center'
        code={`import { ToggleGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <ToggleGroup onChange={() => {}}>
      <ToggleGroup.Item value='unread'>
          <MarkEmailUnread size='1.5rem'/>Unread
      </ToggleGroup.Item>
      <ToggleGroup.Item value='read'>
          <MarkEmailRead size='1.5rem'/>Read
      </ToggleGroup.Item>
      <ToggleGroup.Item value='sent'>
        <Send size='1.5rem'/>Sent
      </ToggleGroup.Item>
    </ToggleGroup>
  )
}`}
      >
        <ToggleGroup onChange={() => {}}>
          <ToggleGroup.Item value='unread'>
            <MarkEmailUnread size='1.5rem' />
            Unread
          </ToggleGroup.Item>
          <ToggleGroup.Item value='read'>
            <MarkEmailRead size='1.5rem' />
            Read
          </ToggleGroup.Item>
          <ToggleGroup.Item value='sent'>
            <Send size='1.5rem' />
            Sent
          </ToggleGroup.Item>
        </ToggleGroup>
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='properties-togglegroup' level='2' spacing>
        ToggleGroup
      </Heading>

      <VerticalSpace size='2' />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets toggle group size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets toggle group value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>defaultValue</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets toggle group default value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(value: string) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback for selected toggle</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>label</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Set toggle group label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='properties-togglegroup-item' level='2' spacing>
        ToggleGroup.Item
      </Heading>

      <VerticalSpace size='2' />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets toggle group item value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsToggleGroup
