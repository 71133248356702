import { BodyShort, Content, Heading, Link, VerticalSpace } from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const StartHere = () => {
  const navigate = useNavigate()

  return (
    <Content>
      <VerticalSpace />

      <Heading size='large'>Start here</Heading>

      <VerticalSpace size='2' />

      <Heading className='toc' level='2' id='react-package' size='medium'>
        Install a package/project manager
      </Heading>

      <VerticalSpace size='2' />

      <BodyShort spacing>
        The two most popular package/project managers among frontend developers are{' '}
        <Link href='https://create-react-app.dev/'>Create React App</Link> and{' '}
        <Link href='https://yarnpkg.com/'>Yarn</Link>.
      </BodyShort>
      <BodyShort spacing>
        <strong>Yarn</strong> lets you do advanced configurations, therefore is its preferred among senior
        developers.
      </BodyShort>

      <BodyShort spacing>
        <strong>Create React App</strong> is simple and comes out of the box with a basic working template and
        dependencies like <Link href='https://jestjs.io'>Jest testing framework</Link>, therefore it is more
        suited to beginner developers that wish to bootstrap a new project.
      </BodyShort>
      <BodyShort>Choose the one you prefer, and let's continue.</BodyShort>

      <VerticalSpace size='2' />

      <Heading className='toc' level='3' id='react-package-cra' size='small'>
        Create React app
      </Heading>

      <VerticalSpace size='2' />

      <BodyShort spacing>
        Make sure you have Create React App installed first. If you do not have it, run:
      </BodyShort>

      <CodeDiv spacing expand={false}>
        npm install --global create-react-app
      </CodeDiv>

      <BodyShort spacing>Then, on your working directory, just type:</BodyShort>
      <CodeDiv spacing expand={false}>
        {'create-react-app {name-of-your-new-project}'}
      </CodeDiv>

      <BodyShort>It will bootstrap a new project for you.</BodyShort>

      <VerticalSpace size='2' />

      <Heading className='toc' level='3' id='react-package-yarn' size='small'>
        Yarn
      </Heading>

      <VerticalSpace size='2' />

      <BodyShort spacing>Make sure you have yarn installed first. If you do not have it, run:</BodyShort>

      <CodeDiv spacing expand={false}>
        {`corepack enable
npm install --global corepack`}
      </CodeDiv>

      <BodyShort spacing>Then, on your working directory, just type:</BodyShort>

      <CodeDiv spacing expand={false}>
        {`mkdir {name-of-your-new-project}
cd {name-of-your-new-project}
yarn init -2`}
      </CodeDiv>

      <BodyShort>It will initialize a project for you.</BodyShort>

      <VerticalSpace size='2' />

      <Heading className='toc' level='2' id='react-import' size='medium'>
        Add the design system to your project
      </Heading>
      <VerticalSpace size='2' />

      <BodyShort spacing>
        To install the basic design system into your React project, run the command:
      </BodyShort>

      <CodeDiv spacing expand={false}>
        npm install --save @cegal/ds-css @cegal/ds-components
      </CodeDiv>

      <BodyShort spacing>Optionally, if you are going to need icons or logos, you can also import:</BodyShort>

      <CodeDiv spacing expand={false}>
        npm install --save @cegal/ds-icons @cegal/ds-logos
      </CodeDiv>

      <BodyShort spacing>
        For some optional utilities, like a fetch with mocking capabilities, import:
      </BodyShort>

      <CodeDiv spacing expand={false}>
        npm install --save @cegal/ds-utils
      </CodeDiv>

      <BodyShort spacing>
        The utilities, icons, logos and graphs are not essential packages to import, but they are nice to have
        around.
      </BodyShort>

      <VerticalSpace size='2' />
      <Heading className='toc' level='2' id='react-use' size='medium'>
        Use the design system
      </Heading>
      <VerticalSpace size='2' />

      <BodyShort spacing>
        To use the design system's styles and components, import the CSS styles and all the components you
        need, as shown below:
      </BodyShort>

      <CodeDiv spacing expand={false}>
        {`import { Button } from '@cegal/ds-components'
import '@cegal/ds-css'

const Component = () => {
  return (
      <Button>This is my first button</Button>
  )
}`}
      </CodeDiv>

      <BodyShort spacing>
        You only need to import the @cegal/ds-css stylesheet once, so it is recommended that it is done in
        your project's src/index.tsx file.
      </BodyShort>

      <VerticalSpace size='2' />

      <Heading className='toc' level='2' id='react-customise' size='medium'>
        Customise components
      </Heading>

      <VerticalSpace size='2' />

      <BodyShort spacing>
        We recommend you use <code>styled-components</code> to create customise components on top of the
        design system ones, like shown below, for a button with green background:
      </BodyShort>

      <CodeDiv spacing expand={false}>
        {`import { Button } from '@cegal/ds-components'
import styled from 'styled-components'
import '@cegal/ds-css'

const CustomButton = styled(Button)\`
  background-color: green;
\`

const Component = () => {
  return (
      <CustomButton>
         This is a green button
      </CustomButton>
  )
}`}
      </CodeDiv>

      <BodyShort spacing>
        Visit the{' '}
        <Link href='#' onClick={() => navigate('/resources/colours')}>
          Resources &gt; Colors/Themes page
        </Link>{' '}
        for a list of theme colors, sizes and other variables you can use to style your components.
      </BodyShort>

      <VerticalSpace size='3' />
    </Content>
  )
}

export default StartHere
