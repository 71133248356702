import { Accordion, PileDiv, Heading, VerticalSpace } from '@cegal/ds-components'
import CopyClipboardButton from 'components/CopyClipboardButton/CopyClipboardButton'
import React from 'react'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx'
import prism from 'react-syntax-highlighter/dist/esm/styles/prism/prism'
// @ts-ignore
import dark from 'react-syntax-highlighter/dist/esm/styles/prism/vs-dark'
import { useAppSelector } from 'store'
import styled from 'styled-components'

export const CornerDiv = styled.div<{ padded: boolean }>`
  position: absolute;
  top: ${(props) => (props.padded ? '2.25' : '1.25')}rem;
  right: 0.5rem;
`

export const CodeDivWrapper = styled(PileDiv)`
  .code:hover .copyButton {
    display: inherit;
  }
  .code .copyButton {
    display: none;
  }
`

SyntaxHighlighter.registerLanguage('jsx', jsx)

const CodeDiv = ({ expand = true, copy = true, spacing = false, children, theme, padded = false }: any) => {
  const themeFromStore = useAppSelector((state) => state.app.theme)

  const _theme = theme ?? themeFromStore
  return (
    <CodeDivWrapper>
      {expand ? (
        <Accordion
          border
          style={{
            paddingTop: (padded ? '1' : '0') + 'rem',
            paddingBottom: (padded ? '1' : '0') + 'rem'
          }}
        >
          <Accordion.Item>
            <Accordion.Header>
              <Heading level='4' size='xsmall'>
                Show
              </Heading>
            </Accordion.Header>
            <Accordion.Content style={{ padding: '0px' }}>
              <div className='code' style={{ position: 'relative' }}>
                {copy && (
                  <CornerDiv padded={padded} className='copyButton'>
                    <CopyClipboardButton text={children} />
                  </CornerDiv>
                )}
                <SyntaxHighlighter
                  language='jsx'
                  style={_theme === 'cegal-light' || _theme === 'neutral-light' ? prism : dark}
                >
                  {children}
                </SyntaxHighlighter>
              </div>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>
      ) : (
        <div
          className='code'
          style={{
            paddingTop: (padded ? '1' : '0') + 'rem',
            paddingBottom: (padded ? '1' : '0') + 'rem',
            position: 'relative'
          }}
        >
          {copy && (
            <CornerDiv padded={padded} className='copyButton'>
              <CopyClipboardButton text={children} />
            </CornerDiv>
          )}
          <SyntaxHighlighter
            language='jsx'
            style={_theme === 'cegal-light' || _theme === 'neutral-light' ? prism : dark}
            wrapLines
          >
            {children}
          </SyntaxHighlighter>
        </div>
      )}
      {spacing && <VerticalSpace />}
    </CodeDivWrapper>
  )
}

export default CodeDiv
