import {
  BodyLong,
  BodyShort,
  FlexBaseDiv,
  FlexBaseSpacedDiv,
  FlexCenterDiv,
  FlexCenterSpacedDiv,
  FlexDiv,
  FlexEndDiv,
  FlexEndSpacedDiv,
  FlexStartDiv,
  FlexStartSpacedDiv,
  Heading,
  Panel,
  PileCenterDiv,
  PileDiv,
  PileEndDiv,
  PileStartDiv,
  VerticalSpace,
  Row,
  Column,
  AlignStartRow,
  AlignCenterRow,
  AlignEndRow,
  AlignCenterColumn,
  AlignEndColumn
} from '@cegal/ds-components'
import * as Icons from '@cegal/ds-icons'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import React from 'react'
import styled from 'styled-components'

const VisibleRow = styled(Row)`
  border: 1px solid var(--cds-border-color);
  height: 7rem;
`

const ComponentsHome = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='grids' level='2' spacing>
        Grids
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        Grids are a collection of styled <code>div</code> elements that are useful to align their contents or
        set spaces between them, for a layout organization that is easier to read in the code.
      </BodyLong>

      <BodyLong spacing>
        The grid elements fall into these categories:
        <ul>
          <li>
            <BodyShort spacing>
              <strong>FlexDiv</strong> elements, <code>div</code> with flex flow as <code>row</code> for
              aligning elements in a horizontal way.
            </BodyShort>
          </li>
          <li>
            <BodyShort spacing>
              <strong>PileDiv</strong> elements, <code>div</code> with flex flow as <code>column</code> for
              aligning elements in a vertical way. It differs from <code>Column</code> as it does not set any
              margin of padding.
            </BodyShort>
          </li>
          <li>
            <BodyShort spacing>
              <strong>Row</strong> elements, which extends <code>FlexDiv</code> with margin for gutters,
              responsive flow, and different values for vertical alignments
            </BodyShort>
          </li>
          <li>
            <BodyShort spacing>
              <strong>Column</strong> elements, which extends <code>PileDiv</code> with margin for gutters,
              and different values on horizontal alignments
            </BodyShort>
          </li>

          <li>
            <BodyShort spacing>
              Other generic <code>div</code> elements for quick padding or display styles.
            </BodyShort>
          </li>
        </ul>
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='flexdiv' level='2' spacing>
        FlexDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        FlexDiv is a shorthand for a <code>div</code> element with a <code>row</code> flex direction
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { FlexDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <FlexDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
      <Icons.FormatAlignJustify size='2rem'/>
    </FlexDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '100px', width: '300px' }}>
          <FlexDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
            <Icons.FormatAlignJustify size='2rem' />
          </FlexDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='small' id='FlexBaseDiv' level='3' spacing>
        FlexBaseDiv
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        FlexBaseDiv is a <code>FlexDiv</code> component with <code>align-items</code> set to{' '}
        <code>baseline</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { FlexBaseDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <FlexBaseDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
      <Icons.FormatAlignJustify size='2rem'/>
    </FlexBaseDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '100px', width: '300px' }}>
          <FlexBaseDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
            <Icons.FormatAlignJustify size='2rem' />
          </FlexBaseDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='small' id='FlexStartDiv' level='3' spacing>
        FlexStartDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        FlexStartDiv is a <code>FlexDiv</code> component with <code>align-items</code> set to{' '}
        <code>flex-start</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { FlexStartDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <FlexStartDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
      <Icons.FormatAlignJustify size='2rem'/>
    </FlexStartDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '100px', width: '300px' }}>
          <FlexStartDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
            <Icons.FormatAlignJustify size='2rem' />
          </FlexStartDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='small' id='FlexCenterDiv' level='3' spacing>
        FlexCenterDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        FlexStartDiv is a <code>FlexDiv</code> component with <code>align-items</code> set to{' '}
        <code>center</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { FlexCenterDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <FlexCenterDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
      <Icons.FormatAlignJustify size='2rem'/>
    </FlexCenterDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '100px', width: '300px' }}>
          <FlexCenterDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
            <Icons.FormatAlignJustify size='2rem' />
          </FlexCenterDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='small' id='FlexEndDiv' level='3' spacing>
        FlexEndDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        FlexStartDiv is a <code>FlexDiv</code> component with <code>align-items</code> set to{' '}
        <code>flex-end</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { FlexEndDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <FlexEndDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
      <Icons.FormatAlignJustify size='2rem'/>
    </FlexEndDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '100px', width: '300px' }}>
          <FlexEndDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
            <Icons.FormatAlignJustify size='2rem' />
          </FlexEndDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='small' id='FlexBaseSpacedDiv' level='3' spacing>
        FlexBaseSpacedDiv
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        FlexBaseSpacedDiv is a <code>FlexBaseDiv</code> component with <code>justify-content</code> set to{' '}
        <code>spaced-between</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { FlexBaseSpacedDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <FlexBaseSpacedDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
      <Icons.FormatAlignJustify size='2rem'/>
    </FlexBaseSpacedDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '100px', width: '300px' }}>
          <FlexBaseSpacedDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
            <Icons.FormatAlignJustify size='2rem' />
          </FlexBaseSpacedDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='small' id='FlexStartSpacedDiv' level='3' spacing>
        FlexStartSpacedDiv
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        FlexStartSpacedDiv is a <code>FlexStartDiv</code> component with <code>justify-content</code> set to{' '}
        <code>spaced-between</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { FlexStartSpacedDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <FlexStartSpacedDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
      <Icons.FormatAlignJustify size='2rem'/>
    </FlexStartSpacedDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '100px', width: '300px' }}>
          <FlexStartSpacedDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
            <Icons.FormatAlignJustify size='2rem' />
          </FlexStartSpacedDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='FlexCenterSpacedDiv' level='3' spacing>
        FlexCenterSpacedDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        FlexCenterSpacedDiv is a <code>FlexCenterDiv</code> component with <code>justify-content</code> set to{' '}
        <code>spaced-between</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { FlexCenterSpacedDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <FlexCenterSpacedDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
      <Icons.FormatAlignJustify size='2rem'/>
    </FlexCenterSpacedDiv>
  )
}`}
      >
        <FlexDiv>
          <Panel border style={{ display: 'flex', height: '100px', width: '300px' }}>
            <FlexCenterSpacedDiv style={{ width: '100%' }}>
              <Icons.FormatAlignLeft size='2rem' />
              <Icons.FormatAlignCenter size='2rem' />
              <Icons.FormatAlignRight size='2rem' />
              <Icons.FormatAlignJustify size='2rem' />
            </FlexCenterSpacedDiv>
          </Panel>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='FlexEndSpacedDiv' level='3' spacing>
        FlexEndSpacedDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        FlexEndSpacedDiv is a <code>FlexEndDiv</code> component with <code>justify-content</code> set to{' '}
        <code>spaced-between</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { FlexEndSpacedDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <FlexEndSpacedDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
      <Icons.FormatAlignJustify size='2rem'/>
    </FlexEndSpacedDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '100px', width: '300px' }}>
          <FlexEndSpacedDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
            <Icons.FormatAlignJustify size='2rem' />
          </FlexEndSpacedDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='PileDiv' level='2' spacing>
        PileDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        <code>PileDiv</code> is a shorthand for <code>div</code> with a column flex direction
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { PileDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <PileDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
    </PileDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '150px', width: '300px' }}>
          <PileDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
          </PileDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='PileStartDiv' level='3' spacing>
        PileStartDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        <code>PileStartDiv</code> is a <code>PileDiv</code> with a <code>align-items</code> set to{' '}
        <code>flex-start</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { FlexStartDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <PileStartDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
    </PileStartDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '150px', width: '300px' }}>
          <PileStartDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
          </PileStartDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='PileCenterDiv' level='3' spacing>
        PileCenterDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        <code>PileCenterDiv</code> is a <code>PileDiv</code> with a <code>align-items</code> set to{' '}
        <code>center</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { PileCenterDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <PileCenterDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
    </PileCenterDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '150px', width: '300px' }}>
          <PileCenterDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
          </PileCenterDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='PileEndDiv' level='3' spacing>
        PileEndDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        <code>PileEndDiv</code> is a <code>PileDiv</code> with a <code>align-items</code> set to{' '}
        <code>flex-end</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { PileEndDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <FlexEndDiv>
      <Icons.FormatAlignLeft size='2rem'/>
      <Icons.FormatAlignCenter size='2rem'/>
      <Icons.FormatAlignRight size='2rem'/>
    </FlexEndDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '150px', width: '300px' }}>
          <PileEndDiv style={{ width: '100%' }}>
            <Icons.FormatAlignLeft size='2rem' />
            <Icons.FormatAlignCenter size='2rem' />
            <Icons.FormatAlignRight size='2rem' />
          </PileEndDiv>
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='grids-row' level='2' spacing>
        Row
      </Heading>

      <BodyLong spacing>
        For a layout container that will flow like a table row (it rhymes). It breaks for column flow for
        small screens ( &lt; 768 px).
      </BodyLong>

      <BodyLong spacing>
        Rows have a negative margin in the left and right of 0.5rem, so that the <code>Column</code> component
        can reclaim with a margin and create natural gutters between columns, therefore it is recommended that{' '}
        <code>Row</code> children will be wrapped in <code>Column</code> components.
      </BodyLong>

      <BodyLong spacing>
        In the demos below, we are setting <code>style</code> and <code>height</code> props only to make
        alignments more visible. You do not need to pass them to <code>Row</code>, as they should be invisible
        elements.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Row } from '@cegal/ds-components'

const VisibleRow = style(Row)\`
  border: 1px solid var(--cds-border-color);
  height: 7rem;
\`
const Component = () => {
    
  return (
    <VisibleRow>
      <Column>
        <Panel border>One panel</Panel>
      </Column>
      <Column>
        <Panel border>Another panel</Panel>
      </Column>
    </Row>
  )
}`}
      >
        <VisibleRow>
          <Column>
            <Panel border>One panel</Panel>
          </Column>
          <Column>
            <Panel border>Another panel</Panel>
          </Column>
        </VisibleRow>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='grids-AlignStartRow' level='3' spacing>
        AlignStartRow
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        Same as <code>Row</code>, but with an align-items set to <code>flex-start</code>.{' '}
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Row, AlignStartRow } from '@cegal/ds-components'

const VisibleRow = style(Row)\`
  border: 1px solid var(--cds-border-color);
  height: 7rem;
\`

const Component = () => {
  return (
    <VisibleRow as={AlignStartRow}>
      <Column>
        <Panel border>One panel</Panel>
      </Column>
      <Column>
        <Panel border>Another panel</Panel>
      </Column>
    </AlignStartRow>
  )
}`}
      >
        <VisibleRow as={AlignStartRow}>
          <Column>
            <Panel border>One panel</Panel>
          </Column>
          <Column>
            <Panel border>Another panel</Panel>
          </Column>
        </VisibleRow>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='grids-AlignCenterRow' level='3' spacing>
        AlignCenterRow
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        Same as <code>Row</code>, but with an align-items set to <code>center</code>.{' '}
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Row, AlignCenterRow } from '@cegal/ds-components'

const VisibleRow = style(Row)\`
  border: 1px solid var(--cds-border-color);
  height: 7rem;
\`

const Component = () => {
  return (
    <VisibleRow as={AlignCenterRow}>
      <Column>
        <Panel border>One panel</Panel>
      </Column>
      <Column>
        <Panel border>Another panel</Panel>
      </Column>
    </AlignStartRow>
  )
}`}
      >
        <VisibleRow as={AlignCenterRow}>
          <Column>
            <Panel border>One panel</Panel>
          </Column>
          <Column>
            <Panel border>Another panel</Panel>
          </Column>
        </VisibleRow>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='grids-AlignEndRow' level='3' spacing>
        AlignEndRow
      </Heading>

      <BodyLong spacing>
        Same as <code>Row</code>, but with an align-items set to <code>flex-end</code>.{' '}
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Row, AlignEndRow } from '@cegal/ds-components'

const VisibleRow = style(Row)\`
  border: 1px solid var(--cds-border-color);
  height: 7rem;
\`

const Component = () => {
  return (
    <VisibleRow as={AlignEndRow}>
      <Column>
        <Panel border>One panel</Panel>
      </Column>
      <Column>
        <Panel border>Another panel</Panel>
      </Column>
    </AlignStartRow>
  )
}`}
      >
        <VisibleRow as={AlignEndRow}>
          <Column>
            <Panel border>One panel</Panel>
          </Column>
          <Column>
            <Panel border>Another panel</Panel>
          </Column>
        </VisibleRow>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='grids-Column' level='2' spacing>
        Column
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>Column</code> components is meant to be places inside <code>Row</code> components, in a
        similar way on how <code>td</code> elements live inside <code>tr</code> elements in a{' '}
        <code>table</code> element.
      </BodyLong>

      <BodyLong spacing>
        You can set the <code>flex</code> prop so you can have different column width ratios within the same
        row.
      </BodyLong>

      <BodyLong spacing>
        The default alignment is <code>flex-start</code>, therefore <code>Column</code> is basically a{' '}
        <code>AlignStartColumn</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Row, AlignEndRow } from '@cegal/ds-components'

const VisibleRow = style(Row)\`
  border: 1px solid var(--cds-border-color);
  height: 7rem;
\`

const Component = () => {
  return (
    <VisibleRow>
      <Column>
        <Panel border>One column</Panel>
      </Column>
      <Column flex='3'>
        <Panel border>a 3x bigger column</Panel>
      </Column>
    </AlignStartRow>
  )
}`}
      >
        <VisibleRow>
          <Column>
            <Panel border>One column</Panel>
          </Column>
          <Column flex='3'>
            <Panel border>a 3x bigger column</Panel>
          </Column>
        </VisibleRow>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='grids-AlignCenterColumn' level='3' spacing>
        AlignCenterColumn
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        Same as <code>Column</code>, but vertically aligning items to the center
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Column } from '@cegal/ds-components'

const VisibleRow = style(Row)\`
  border: 1px solid var(--cds-border-color);
  height: 7rem;
\`

const Component = () => {
  return (
    <VisibleRow>
      <Column>
        <Panel border>Standard column</Panel>
      </Column>
      <AlignCenterColumn>
        <Panel border>AlignCenterColumn</Panel>
      </AlignCenterColumn>
    </VisibleRow>
  )
}`}
      >
        <VisibleRow>
          <Column>
            <Panel border>Standard column</Panel>
          </Column>
          <AlignCenterColumn>
            <Panel border>AlignCenterColumn</Panel>
          </AlignCenterColumn>
        </VisibleRow>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='grid-AlignEndColumn' level='3' spacing>
        AlignEndColumn
      </Heading>

      <VerticalSpace />
      <BodyLong spacing>
        This is a <code>Column</code> with vertical alignment to <code>flex-end</code>.{' '}
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { AlignEndColumn } from '@cegal/ds-components'

const VisibleRow = style(Row)\`
  border: 1px solid var(--cds-border-color);
  height: 7rem;
\`

const Component = () => {
  return (
    <VisibleRow>
      <Column>
        <Panel border>Standard column</Panel>
      </Column>
      <AlignEndColumn>
        <Panel border>AlignEndColumn</Panel>
      </AlignEndColumn>
    </VisibleRow>
  )
}`}
      >
        <VisibleRow>
          <Column>
            <Panel border>Standard column</Panel>
          </Column>
          <AlignEndColumn>
            <Panel border>AlignEndColumn</Panel>
          </AlignEndColumn>
        </VisibleRow>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='HiddenDiv' level='2' spacing>
        HiddenDiv
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        <code>HiddenDiv</code> is a <code>div</code> with <code>display</code> set to <code>none</code>. It is
        useful in some cases, specially when adding text visible only to screen readers.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { HiddenDiv } from '@cegal/ds-components'
    
const Component = () => {
  return (
    <HiddenDiv>
      Hidden content
    </HiddenDiv>
  )
}`}
      >
        <Panel border style={{ display: 'flex', height: '100px', width: '300px' }}>
          It's a little hard to show a hidden DIV. It just adds display: none; to the style.
        </Panel>
      </DemoableDiv>

      <VerticalSpace size='2' />
    </>
  )
}

export default ComponentsHome
