import {
  BodyLong,
  FlexCenterDiv,
  FlexDiv,
  HorizontalSpace,
  Label,
  PileDiv,
  Switch,
  VerticalSpace
} from '@cegal/ds-components'
import cl from 'clsx'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'store'
import styled, { keyframes } from 'styled-components'

const fade = keyframes`
0% { width: 0px}
100% { width: 250px}
`

const fadeout = keyframes`
0% { width: 250px}
100% { width: 0px}
`

const SlideButton = styled.button`
  height: 100%;
  padding: 0;
  border: none;
  width: 15px;
  cursor: pointer;
  background-color: var(--cds-background-color-inverted-light-opaque);
`
const Menu = styled(PileDiv)`
  width: 0px;
  overflow: hidden;
  &.open {
    animation: ${fade} 0.3s ease-in-out forwards;
    width: 250px;
  }
  &.close {
    animation: ${fadeout} 0.3s ease-in-out forwards;
    width: 0px;
  }
`

const DemoableDiv = ({
  children,
  code,
  alignContent = 'inherit',
  justifyContent = 'center',
  initialTheme
}: any) => {
  const theme = useAppSelector((state) => state.app.theme)
  const usetheme = initialTheme ?? theme
  const [_theme, setTheme] = useState<string>(usetheme.split('-')[0])
  const [_shade, setShade] = useState<string>(usetheme.split('-')[1])
  const [_background, setBackground] = useState<string>('opaque')
  const [menu, toggleMenu] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    setTheme(theme.split('-')[0])
    setShade(theme.split('-')[1])
  }, [theme])

  return (
    <div className='www-demoablediv'>
      <FlexDiv style={{ marginBottom: '0.5rem' }}>
        <PileDiv
          flex='4'
          className={cl('area', `area--${_background}`)}
          data-theme={_theme + '-' + _shade}
          style={{ justifyContent, alignItems: alignContent, padding: '1rem' }}
        >
          {children}
        </PileDiv>
        <FlexDiv>
          <SlideButton onClick={() => toggleMenu(!menu)}>{menu ? '>' : '<'}</SlideButton>
          <Menu className={cl({ open: menu === true, close: menu === false })}>
            <div style={{ padding: '0.5rem' }}>
              <Label spacing>Options</Label>
              <Label size='small'>Theme</Label>
              <FlexCenterDiv style={{ flexWrap: 'nowrap' }}>
                <BodyLong size='small'>Cegal</BodyLong>
                <HorizontalSpace size='0.5' />
                <Switch
                  size='small'
                  checked={_theme === 'neutral'}
                  hideLabel
                  position='left'
                  onClick={() => setTheme(_theme === 'cegal' ? 'neutral' : 'cegal')}
                >
                  theme
                </Switch>
                <HorizontalSpace size='3.5' />
                <BodyLong size='small'>Neutral</BodyLong>
              </FlexCenterDiv>

              <VerticalSpace size='0.5' />
              <Label size='small'>Mode</Label>
              <FlexCenterDiv style={{ flexWrap: 'nowrap' }}>
                <BodyLong size='small'>Light</BodyLong>
                <HorizontalSpace size='0.5' />
                <Switch
                  checked={_shade === 'dark'}
                  hideLabel
                  size='small'
                  position='left'
                  onClick={() => setShade(_shade === 'light' ? 'dark' : 'light')}
                >
                  mode
                </Switch>
                <HorizontalSpace size='3.5' />
                <BodyLong size='small'>Dark</BodyLong>
              </FlexCenterDiv>
              <VerticalSpace size='0.5' />
              <Label size='small'>Background</Label>
              <FlexCenterDiv style={{ flexWrap: 'nowrap' }}>
                <BodyLong size='small'>Opaque</BodyLong>
                <HorizontalSpace size='0.5' />
                <Switch
                  size='small'
                  checked={_background === 'transparent'}
                  hideLabel
                  position='left'
                  onClick={() => setBackground(_background === 'transparent' ? 'opaque' : 'transparent')}
                >
                  mode
                </Switch>
                <HorizontalSpace size='3.5' />
                <BodyLong size='small'>Transparent</BodyLong>
              </FlexCenterDiv>
            </div>
          </Menu>
        </FlexDiv>
      </FlexDiv>
      {code && <CodeDiv>{code}</CodeDiv>}
    </div>
  )
}

export default DemoableDiv
