import {
  BodyLong,
  ErrorSummary,
  Heading,
  PileDiv,
  Table,
  Link,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useRef } from 'react'

const ComponentsErrorSummary = () => {
  const nameFieldRef = useRef(null)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='errorsummary' level='1' spacing>
        Error summary
      </Heading>

      <VerticalSpace />
      <BodyLong spacing>
        The <code>ErrorSummary</code> component is an alert panel for form validation errors, normally placed
        after a form submit button that triggered the validation.
      </BodyLong>

      <BodyLong spacing>
        The <code>ErrorSummary.Item</code> component is by default an anchor element, which should focus to
        the form field that contains the error, when the user clicks on the link.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { ErrorSummary } from '@cegal/ds-components'

const Component = () => {
  return (
    <ErrorSummary
       heading='You must fix these errors before sending the data:'
    >
      <ErrorSummary.Item href='#1'>
        You must fill up an age
      </ErrorSummary.Item>
      <ErrorSummary.Item href='#2'>
        E-mail field must have a valid e-mail address
      </ErrorSummary.Item>
    </ErrorSummary>
  )
}`}
      >
        <ErrorSummary heading='You must fix these errors before sending the data:'>
          <ErrorSummary.Item href='#1'>You must fill up an age</ErrorSummary.Item>
          <ErrorSummary.Item href='#2'>E-mail field must have a valid e-mail address</ErrorSummary.Item>
        </ErrorSummary>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='errorsummary-size' level='2' spacing>
        Sizes
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>The size prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { ErrorSummary } from '@cegal/ds-components'

const Component = () => {
  return (
    <PileDiv>
      <ErrorSummary
         size='medium'
         heading='You must fix these errors before sending the data:'
      >
        <ErrorSummary.Item href='#1'>
          You must fill up an age
        </ErrorSummary.Item>
        <ErrorSummary.Item href='#2'>
          E-mail field must have a valid e-mail address
        </ErrorSummary.Item>
      </ErrorSummary>
      <VerticalSpace/>
       <ErrorSummary
         size='small'
         heading='You must fix these errors before sending the data:'
      >
        <ErrorSummary.Item href='#1'>
          You must fill up an age
        </ErrorSummary.Item>
        <ErrorSummary.Item href='#2'>
          E-mail field must have a valid e-mail address
        </ErrorSummary.Item>
      </ErrorSummary>
    </PileDiv>
  )
}`}
      >
        <PileDiv>
          <ErrorSummary size='medium' heading='You must fix these errors before sending the data:'>
            <ErrorSummary.Item href='#1'>You must fill up an age</ErrorSummary.Item>
            <ErrorSummary.Item href='#2'>E-mail field must have a valid e-mail address</ErrorSummary.Item>
          </ErrorSummary>
          <VerticalSpace />
          <ErrorSummary size='small' heading='You must fix these errors before sending the data:'>
            <ErrorSummary.Item href='#1'>You must fill up an age</ErrorSummary.Item>
            <ErrorSummary.Item href='#2'>E-mail field must have a valid e-mail address</ErrorSummary.Item>
          </ErrorSummary>
        </PileDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='errorsummary-focus' level='2' spacing>
        Focus on elements
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        It is a good practice that the error summary item's link will focus on the form element that needs
        user intervention, for example:
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { ErrorSummary } from '@cegal/ds-components'

const Component = () => {
  return (
    <PileDiv>
      <TextField ref={nameFieldRef} error="Name can't be empty"/>
      <VerticalSpace/>
      <ErrorSummary
        heading='You must fix these errors before sending the data:'
      >
        <ErrorSummary.Item href='#name-field' onClick={
          () => (nameFieldRef!.current as HTMLInputElement | null)?.focus()}>
           You must write a name
         </ErrorSummary.Item>
       </ErrorSummary>
     </PileDiv>
  )
}`}
      >
        <PileDiv>
          <TextField label='error' hideLabel ref={nameFieldRef} error="Name can't be empty" />
          <VerticalSpace />
          <ErrorSummary heading='You must fix these errors before sending the data:'>
            <ErrorSummary.Item
              href='#name-field'
              onClick={() => (nameFieldRef!.current as HTMLInputElement | null)?.focus()}
            >
              You must write a name
            </ErrorSummary.Item>
          </ErrorSummary>
        </PileDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='errorsummary-icon' level='2' spacing>
        Icon display
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        If you want to hide the icon next to the error header, use the <code>icon</code> prop:
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { ErrorSummary } from '@cegal/ds-components'

const Component = () => {
  return (
    <ErrorSummary
      icon={false}
      heading='You must fix these errors before sending the data:'
    >
      <ErrorSummary.Item>
         You must write a name
       </ErrorSummary.Item>
     </ErrorSummary>
  )
}`}
      >
        <ErrorSummary icon={false} heading='You must fix these errors before sending the data:'>
          <ErrorSummary.Item>You must write a name</ErrorSummary.Item>
        </ErrorSummary>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props tables
      </Heading>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='properties-errorsummary' level='3' spacing>
        ErrorSummary
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>ErrorSummaryProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLElement&gt;</code>, as the top level uses a <code>section</code>{' '}
        tag. As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>heading</code>
              </Table.DataCell>
              <Table.DataCell>React.ReactNode</Table.DataCell>
              <Table.DataCell>Sets heading content</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>headingTag</code>
              </Table.DataCell>
              <Table.DataCell>React.ElementType</Table.DataCell>
              <Table.DataCell>Sets heading tag level</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>h2</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>icon</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Show error icon</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets error summary size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='properties-errorsummary-item' level='3' spacing>
        ErrorSummary.Item
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>ErrorSummary.Item</code> is an HTML anchor, so it accepts all anchors props and events such
        as <code>href</code> and <code>onClick</code>.
      </BodyLong>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsErrorSummary
