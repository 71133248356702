import {
  BodyLong,
  Button,
  Drawer,
  FlexDiv,
  Table,
  Link,
  Heading,
  HorizontalSpace,
  PileCenterDiv,
  Select,
  VerticalSpace
} from '@cegal/ds-components'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useRef, useState } from 'react'

const ComponentsDrawer = () => {
  const [open1, setOpen1] = useState<boolean>(false)
  const [open2, setOpen2] = useState<boolean>(false)
  const [open3, setOpen3] = useState<boolean>(false)
  const [open4, setOpen4] = useState<boolean>(false)
  const [open5, setOpen5] = useState<boolean>(false)
  const [open6, setOpen6] = useState<boolean>(false)
  const [placement2, setPlacement2] = useState<string>('left')
  const containerRef = useRef(null)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='Drawer' level='1' spacing>
        Drawer
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>Drawer</code> component opens a side panel for contextual options to the current page.
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Drawer-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The default <code>Drawer</code> aligns to the left, comes with no mask, and has a slide animation.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Drawer } from '@cegal/ds-components'

const Component = () => {
  return (
     <>
       <Drawer open={openDrawer}>
         <PileCenterDiv style={{margin: '1rem'}}>
           <Button onClick={
             () => setOpenDrawer(!openDrawer)
           }>
             Close drawer
           </Button>
         </PileCenterDiv>
       </Drawer>
       <Button 
         onClick={() => setOpenDrawer(!openDrawer)}
       >
         Open default drawer
       </Button>
     </>
  )
}`}
      >
        <Drawer open={open1}>
          <PileCenterDiv style={{ margin: '1rem' }}>
            <Button onClick={() => setOpen1(!open1)}>Close drawer</Button>
          </PileCenterDiv>
        </Drawer>
        <Button onClick={() => setOpen1(!open1)}>Open default drawer</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Drawer-placement' level='2' spacing>
        Placement
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        Add a <code>placement</code> prop to set the drawer position. Currently only <code>left</code> and{' '}
        <code>right</code> placements are supported.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Drawer } from '@cegal/ds-components'

const Component = () => {
  return (
     <>
        <Drawer
           open={openDrawer} 
           placement={placement}
        >
          <PileCenterDiv style={{ margin: '1rem' }}>
            <Button onClick={
              () => setOpenDrawer(!openDrawer)}
            >
              Close drawer
            </Button>
          </PileCenterDiv>
        </Drawer>
        <FlexDiv>
          <Select 
            onChange={(e: any) => 
              setPlacement(e.target.value)}
          >
            <option>left</option>
            <option>right</option>
          </Select>
          <HorizontalSpace/>
          <Button 
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            Open {placement} drawer
          </Button>
        </FlexDiv>
     </>
  )
}`}
      >
        <Drawer open={open2} placement={placement2 as any}>
          <PileCenterDiv style={{ margin: '1rem' }}>
            <Button onClick={() => setOpen2(!open2)}>Close drawer</Button>
          </PileCenterDiv>
        </Drawer>
        <FlexDiv>
          <Select label='' hideLabel onChange={(e: any) => setPlacement2(e.target.value)}>
            <option>left</option>
            <option>right</option>
          </Select>
          <HorizontalSpace />
          <Button onClick={() => setOpen2(!open2)}>Open {placement2} drawer</Button>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Drawer-overlay' level='2' spacing>
        Overlay
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        Add a <code>overlay</code> prop to set a drawer overlay. The drawer overlay prevents the user from
        interacting with the page until he closes the drawer.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Drawer } from '@cegal/ds-components'

const Component = () => {
  return (
     <>
       <Drawer 
         open={openDrawer} 
         overlay
       >
         <PileCenterDiv style={{margin: '1rem'}}>
           <Button 
             onClick={() => setOpenDrawer(!openDrawer)}
           >
             Close drawer
           </Button>
         </PileCenterDiv>
       </Drawer>
       <Button 
         onClick={() => setOpenDrawer(!openDrawer)}
       >
         Open drawer with overlay
       </Button>
     </>
  )
}`}
      >
        <Drawer open={open3} overlay>
          <PileCenterDiv style={{ margin: '1rem' }}>
            <Button onClick={() => setOpen3(!open3)}>Close drawer</Button>
          </PileCenterDiv>
        </Drawer>
        <Button onClick={() => setOpen3(!open3)}>Open drawer with overlay</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Drawer-overlayCloses' level='2' spacing>
        Overlay closes on click
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        Add a <code>overlayCloses</code> prop, along with <code>overlay</code> prop. to set a drawer overlay.
        The drawer overlay prevents the user from interacting with the page until he closes the drawer.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Drawer } from '@cegal/ds-components'

const Component = () => {
  return (
     <>
       <Drawer 
         open={openDrawer} 
         overlay 
         overlayCloses
       >
         <PileCenterDiv style={{margin: '1rem'}}>
           <BodyLong>
             Click on the overlay to close it
           </BodyLong>
         </PileCenterDiv>
       </Drawer>
       <Button 
         onClick={() => setOpenDrawer(!openDrawer)}
       >
         Open drawer with closable overlay
       </Button>
     </>
  )
}`}
      >
        <Drawer open={open4} overlay overlayCloses onClose={() => setOpen4(false)}>
          <PileCenterDiv style={{ margin: '1rem' }}>
            <BodyLong>Click on the overlay to close it</BodyLong>
          </PileCenterDiv>
        </Drawer>
        <Button onClick={() => setOpen4(!open4)}>Open drawer with closable overlay</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Drawer-animation' level='2' spacing>
        Sliding animation
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can remove the animation by setting it to <code>false</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Drawer } from '@cegal/ds-components'

const Component = () => {
  return (
     <>
       <Drawer 
         open={openDrawer} 
         animate={false}
       >
         <PileCenterDiv style={{margin: '1rem'}}>
            <Button 
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              Close drawer
            </Button>
         </PileCenterDiv>
       </Drawer>
       <Button
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          Open drawer with no animation
        </Button>
     </>
  )
}`}
      >
        <Drawer animate={false} open={open5}>
          <PileCenterDiv style={{ margin: '1rem' }}>
            <Button onClick={() => setOpen5(!open5)}>Close drawer</Button>
          </PileCenterDiv>
        </Drawer>
        <Button onClick={() => setOpen5(!open5)}>Open drawer with no animation</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Drawer-container' level='2' spacing>
        Anchoring drawer
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        By default, the drawer will attach itself to the <code>body</code> element. This means that it may not
        inherit the <code>data-theme</code> prop set by you in your app.
      </BodyLong>

      <BodyLong spacing>
        You can counter that in two ways:
        <ul>
          <li>
            Add a <code>data-theme</code> to the drawer contents, so it knowss which theme to use, or
          </li>
          <li>
            make the drawer render in a container that is under a <code>data-theme</code> scope.
          </li>
        </ul>
      </BodyLong>

      <BodyLong spacing>
        For the latter option, which is recommended, you set a reference for a container node, and then pass
        it to the drawer through a getContainer prop.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Drawer } from '@cegal/ds-components'

const Component = () => {
  const containerRef = useRef(null)  
  return (
     <div ref={containerRef}>
       <Drawer 
         open={openDrawer} 
         overlay
         getContainer={
           () => containerRef!.current as any
         }>
         <PileCenterDiv style={{margin: '1rem'}}>
            <Button 
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              Close drawer
            </Button>
         </PileCenterDiv>
       </Drawer>
       <Button 
         onClick={() => setOpenDrawer(!openDrawer)}
       >
         Open drawer within a container
       </Button>
     </div>
  )
}`}
      >
        <div ref={containerRef}>
          <Drawer open={open6} overlay getContainer={() => containerRef!.current as any}>
            <PileCenterDiv style={{ margin: '1rem' }}>
              <Button onClick={() => setOpen6(!open6)}>Close drawer</Button>
            </PileCenterDiv>
          </Drawer>
          <Button onClick={() => setOpen6(!open6)}>Open drawer within a container</Button>
        </div>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>DrawerProps</code> interface extends <code>RCDrawerProps</code>, from the{' '}
        <Link href='https://www.npmjs.com/package/rc-drawer'>rc-drawer package</Link>.
      </BodyLong>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>animate</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Animates sliding drawer</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>defaultOpen</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets drawer's default open state</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>destroyOnClose</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Remove drawer DOM elements after closing</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>tue</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>open</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets drawer open state</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>overlay</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets a drawer overlay</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>overlayCloses</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Lets overlay clicks close the drawer</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>placement</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'left' | 'right'</code>
              </Table.DataCell>
              <Table.DataCell>Sets drawer position</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>'left'</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>width</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code> (px, rem, vw, etc)
              </Table.DataCell>
              <Table.DataCell>Sets drawer width</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>378px</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsDrawer
