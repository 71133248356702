import { BodyShort, Link, Panel, PileDiv, VerticalSpace } from '@cegal/ds-components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { slideInFromLeft } from '../styled'

export const ComponentPanelDiv = styled(Panel)`
  width: 300px;
  min-height: 200px;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  transform: translateX(-20px);
  opacity: 0;
  animation: ${slideInFromLeft} 0.2s forwards;
`

const ComponentPanel = ({ children, navigate, description, label }: any) => {
  const nav = useNavigate()
  return (
    <PileDiv style={{ maxWidth: '320px' }}>
      <ComponentPanelDiv border>{children}</ComponentPanelDiv>
      <PileDiv
        flex='1'
        style={{
          paddingTop: '0.5rem',
          paddingBottom: '2rem',
          paddingLeft: '1rem',
          paddingRight: '1rem'
        }}
      >
        <Link style={{ fontSize: '1.2rem' }} href='#' variant='secondary' onClick={() => nav(navigate)}>
          {label}
        </Link>
        <VerticalSpace size='0.5' />
        <BodyShort>{description}</BodyShort>
      </PileDiv>
    </PileDiv>
  )
}

export default ComponentPanel
