import { Avatar, BodyLong, Chip, Heading, Table, VerticalSpace } from '@cegal/ds-components'
import { Email } from '@cegal/ds-icons/dist/Email'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React from 'react'

const ComponentsChip = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='Chip' level='1' spacing>
        Chip
      </Heading>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Chip-default' level='2' spacing>
        Default
      </Heading>

      <DemoableDiv
        alignContent='center'
        code={`import { Chip } from '@cegal/ds-components'

const Component = () => {
  return (
    <Chip>Default</Chip>
  )
}`}
      >
        <Chip>Default</Chip>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Chip-size' level='2' spacing>
        Size
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>The chip size prop have one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type ChipSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>ChipSize</code> from <code>@cegal/ds-components</code>.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Chip } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <Chip size="medium">medium</Chip>
      <VerticalSpace/>
      <Chip size="small">small</Chip>
    </>
    )
}`}
      >
        <Chip size='medium'>medium</Chip>
        <VerticalSpace />
        <Chip size='small'>small</Chip>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Chip-selected' level='2' spacing>
        Selected style
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        You set the <code>selected</code> boolean to true, so the chip renders as selected.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Chip } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <Chip selected size="medium">Selected medium</Chip>
      <VerticalSpace/>
      <Chip selected size="small">Selected small</Chip>
    </>
    )
}`}
      >
        <Chip selected size='medium'>
          Selected medium
        </Chip>
        <VerticalSpace />
        <Chip selected size='small'>
          Selected small
        </Chip>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Chip-button' level='2' spacing>
        Chip as button
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        You set the <code>button</code> boolean to true, so the chip renders like a button.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Chip } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <Chip button size="medium">medium</Chip>
      <VerticalSpace/>
      <Chip button size="small">small</Chip>
    </>
    )
}`}
      >
        <Chip button size='medium'>
          medium
        </Chip>
        <VerticalSpace />
        <Chip button size='small'>
          small
        </Chip>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Chip-elements' level='2' spacing>
        Left and right elements
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        You can add elements to the sides of the chip content. Those can be interactable or not, depending on
        what you pass into the props.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Chip } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <Chip
        leftElement={(
          <Avatar 
            size='xsmall' 
            src='/static/images/avatar2_non_square.jpeg'
          />
        )}
        rightElement={(
          <Chip.Button icon={(
            <Email size='1rem'/>
          )}/>
        )}
      >
        Ola Nordmanm
      </Chip>
      <VerticalSpace/>
      <Chip
        size='small'
        leftElement={(
          <Avatar 
            size='2xsmall' 
            src='/static/images/avatar2_non_square.jpeg'
          />
        )}
        rightElement={(
          <Chip.Button icon={(
            <Email size='0.8rem'/>
          )}/>
        )}

        >
         Ola Nordmann
      </Chip>  
    </>
    )
}`}
      >
        <Chip
          leftElement={<Avatar size='xsmall' src='/static/images/avatar2_non_square.jpeg' />}
          rightElement={<Chip.Button icon={<Email size='1rem' />} />}
        >
          Ola Nordmanm
        </Chip>
        <VerticalSpace />
        <Chip
          size='small'
          leftElement={<Avatar size='2xsmall' src='/static/images/avatar2_non_square.jpeg' />}
          rightElement={<Chip.Button icon={<Email size='0.8rem' />} />}
        >
          Ola Nordmann
        </Chip>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace size='2' />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>border</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Renders chip with a border</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>button</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Renders chip as a button</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>leftElement</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>React.ReactNode</code>
              </Table.DataCell>
              <Table.DataCell>Renders an element on the left side</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>rightElement</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>React.ReactNode</code>
              </Table.DataCell>
              <Table.DataCell>Renders an element on the right side</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>selected</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets selected style</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets chip size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsChip
