import {
  BodyLong,
  BodyShort,
  Detail,
  ErrorMessage,
  Heading,
  Ingress,
  Label,
  Panel,
  VerticalSpace
} from '@cegal/ds-components'
import React from 'react'

const ResourcesTypography = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' spacing>
        Fonts
      </Heading>
      <VerticalSpace size='2' />

      <a href='#fontfamily' className='cds-sr-only'>
        Font family
      </a>
      <Heading className='toc' size='medium' level='2' id='fontfamily' spacing>
        Font family
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        The design system's Cegal theme uses Inter as header font, and Karla as body font.
      </BodyLong>

      <Panel border style={{ overflow: 'auto' }}>
        <Heading size='small'>ACBCEFGHIJKLMNOPQRSTUVWXYZÆØÅ1234567890</Heading>
        <Heading size='small'>abcdefghijklmnopqrstuvwxyz!"#$%&/()=?*@</Heading>
        <BodyLong>ACBCEFGHIJKLMNOPQRSTUVWXYZÆØÅ1234567890</BodyLong>
        <BodyLong>abcdefghijklmnopqrstuvwxyz!"#$%&/()=?*@</BodyLong>
      </Panel>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        The design system's neutral theme uses a highly compatible sans-serif system font.
      </BodyLong>

      <Panel border style={{ overflow: 'auto' }}>
        <div style={{ fontFamily: 'var(--cds-neutral-font-heading-family)' }}>
          <Heading>ACBCEFGHIJKLMNOPQRSTUVWXYZÆØÅ1234567890</Heading>
          <Heading>abcdefghijklmnopqrstuvwxyz!"#$%&/()=?*@</Heading>
        </div>
        <div style={{ fontFamily: 'var(--cds-neutral-font-body-family)' }}>
          <BodyLong>ACBCEFGHIJKLMNOPQRSTUVWXYZÆØÅ1234567890</BodyLong>
          <BodyLong>abcdefghijklmnopqrstuvwxyz!"#$%&/()=?*@</BodyLong>
        </div>
      </Panel>
      <VerticalSpace />

      <BodyLong spacing>
        These fonts are embedded in the <code>@cegal/ds-css</code> package as <code>@font-face</code>, so all
        you need to do is import the <code>@cegal/ds-css</code> CSS file to use the right fonts.
      </BodyLong>

      <BodyLong spacing>
        The design system's neutral theme uses a combination of fonts from the Primer style, that are
        comfortable to read and highly compatible with all devices.
      </BodyLong>

      <VerticalSpace />

      <Heading className='toc' size='medium' level='2' id='Heading' spacing>
        Heading
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>Headings should be used to give a proper title to the relevant section.</BodyLong>

      <BodyLong spacing>
        Note that headings are very useful for accessibility readers and for search engine optimizations, so
        use them in your application in a way that reflects its structure.
      </BodyLong>

      <BodyLong spacing>
        The Heading component decouples the heading level (h1, h2, ..., h6) from it size, so you have to set
        the size/level prop.
      </BodyLong>

      <Panel border style={{ overflow: 'auto' }}>
        <Heading size='xlarge'>XLarge heading</Heading>
        <VerticalSpace />
        <Heading size='large'>Large heading</Heading>
        <VerticalSpace />
        <Heading size='medium'>Medium heading</Heading>
        <VerticalSpace />
        <Heading size='small'>Small heading</Heading>
        <VerticalSpace />
        <Heading size='xsmall'>XSmall heading</Heading>
      </Panel>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' level='2' id='Ingress' spacing>
        Ingress
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        Ingress is used typically as a preamble between the section header and the section content. Think of
        it as a section description
      </BodyLong>

      <Panel border style={{ overflow: 'auto' }}>
        <Ingress>This is an Ingress</Ingress>
      </Panel>

      <VerticalSpace size='3' />
      <Heading className='toc' size='medium' level='2' id='BodyLong' spacing>
        Body long
      </Heading>
      <VerticalSpace />

      <BodyLong>
        BodyLong component should be used to wrap text that is expected to be long and likely to wrap, as in
        paragraphs. It differs from BodyShort for having a slightly bigger line height.
      </BodyLong>

      <VerticalSpace size='2' />
      <Panel border style={{ overflow: 'auto' }}>
        <BodyLong size='medium'>medium body long</BodyLong>
        <VerticalSpace />
        <BodyLong size='small'>small body long</BodyLong>
      </Panel>

      <VerticalSpace size='3' />
      <Heading className='toc' size='medium' level='2' id='BodyShort' spacing>
        Body short
      </Heading>
      <VerticalSpace />

      <BodyLong>
        BodyLong component should be used to wrap text that is expected to be short, as in one line.
      </BodyLong>

      <VerticalSpace size='2' />
      <Panel border style={{ overflow: 'auto' }}>
        <BodyShort size='medium'>medium body short</BodyShort>
        <VerticalSpace />
        <BodyShort size='small'>small body short</BodyShort>
      </Panel>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='Detail' spacing>
        Detail
      </Heading>
      <VerticalSpace />

      <BodyLong>
        Detail is useful for styling small detail messages that are accessory text, like footnotes.
      </BodyLong>
      <VerticalSpace size='2' />
      <Panel border style={{ overflow: 'auto' }}>
        <Detail size='medium'>medium detail</Detail>
        <VerticalSpace />
        <Detail size='small'>small detail</Detail>
      </Panel>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' level='2' id='Label' spacing>
        Label
      </Heading>
      <VerticalSpace />

      <BodyLong>
        Label are useful to tag form fields, or in a generic way, to provide a title to the element that is
        connected to this label
      </BodyLong>
      <VerticalSpace size='2' />
      <Panel border style={{ overflow: 'auto' }}>
        <Label size='medium'>medium label</Label>
        <VerticalSpace />
        <Label size='small'>small label</Label>
      </Panel>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' level='2' id='ErrorMessage' spacing>
        Error Message
      </Heading>
      <VerticalSpace />

      <BodyLong>
        Error messages wrap text that should warn the user with a notification of an invalid state
      </BodyLong>
      <VerticalSpace size='2' />
      <Panel border style={{ overflow: 'auto' }}>
        <ErrorMessage size='medium'>medium error message</ErrorMessage>
        <VerticalSpace />
        <ErrorMessage size='small'>small error message</ErrorMessage>
      </Panel>
      <VerticalSpace size='3' />
    </>
  )
}

export default ResourcesTypography
