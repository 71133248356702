import TopContainer from 'components/TopContainer/TopContainer'
import React, { Suspense, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom'
import store from 'store'
import i18n from './i18n'
import * as Pages from 'pages'
import '@cegal/ds-css'
import './index.css'

const container = document.getElementById('root')
const root = createRoot(container!)

const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    /* istanbul ignore next */
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }
    window.scrollTo(0, 0)
  }, [pathname])

  return <></>
}

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Suspense fallback={<span>...</span>}>
        <BrowserRouter>
          <TopContainer margins>
            <ScrollToTop />
            <Routes>
              <Route path='/start-here' element={<Pages.StartHere />} />
              <Route path='/changes' element={<Pages.Changes />} />
              <Route path='/tips' element={<Pages.Tips />} />
              <Route path='/accessibility' element={<Pages.Accessibility />} />
              <Route path='/resources' element={<Pages.Resources.Route />}>
                <Route index element={<Pages.Resources.Home />} />
                <Route path='illustrations' element={<Pages.Resources.Illustrations />} />
                <Route path='icons' element={<Pages.Resources.Icons />} />
                <Route path='logos' element={<Pages.Resources.Logos />} />
                <Route path='colours' element={<Pages.Resources.Colours />} />
                <Route path='typography' element={<Pages.Resources.Typography />} />
                <Route path='spacing' element={<Pages.Resources.Spacing />} />
                <Route path='utilities' element={<Pages.Resources.Utilities />} />
                <Route path='graphs' element={<Pages.Resources.Graphs />} />
              </Route>
              <Route path='/components' element={<Pages.Components.Route />}>
                <Route index element={<Pages.Components.Home />} />
                <Route path='accordion' element={<Pages.Components.Accordion />} />
                <Route path='alert' element={<Pages.Components.Alert />} />
                <Route path='avatar' element={<Pages.Components.Avatar />} />
                <Route path='box' element={<Pages.Components.Box />} />
                <Route path='button' element={<Pages.Components.Button />} />
                <Route path='Carousel' element={<Pages.Components.Carousel />} />
                <Route path='checkbox' element={<Pages.Components.Checkbox />} />
                <Route path='checkboxgroup' element={<Pages.Components.CheckboxGroup />} />
                <Route path='chip' element={<Pages.Components.Chip />} />
                <Route path='counter' element={<Pages.Components.Counter />} />
                <Route path='datepicker' element={<Pages.Components.Datepicker />} />
                <Route path='drawer' element={<Pages.Components.Drawer />} />
                <Route path='dropdown' element={<Pages.Components.Dropdown />} />
                <Route path='dropzone' element={<Pages.Components.Dropzone />} />
                <Route path='errorsummary' element={<Pages.Components.ErrorSummary />} />
                <Route path='fieldset' element={<Pages.Components.Fieldset />} />
                <Route path='helptext' element={<Pages.Components.HelpText />} />
                <Route path='link' element={<Pages.Components.Link />} />
                <Route path='linkpanel' element={<Pages.Components.LinkPanel />} />
                <Route path='loader' element={<Pages.Components.Loader />} />
                <Route path='modal' element={<Pages.Components.Modal />} />
                <Route path='navbar' element={<Pages.Components.NavBar />} />
                <Route path='pagination' element={<Pages.Components.Pagination />} />
                <Route path='panel' element={<Pages.Components.Panel />} />
                <Route path='popover' element={<Pages.Components.Popover />} />
                <Route path='progressbar' element={<Pages.Components.ProgressBar />} />
                <Route path='radiogroup' element={<Pages.Components.RadioGroup />} />
                <Route path='rangeslider' element={<Pages.Components.RangeSlider />} />
                <Route path='readmore' element={<Pages.Components.ReadMore />} />
                <Route path='search' element={<Pages.Components.Search />} />
                <Route path='select' element={<Pages.Components.Select />} />
                <Route path='selectmultiple' element={<Pages.Components.SelectMultiple />} />
                <Route path='slider' element={<Pages.Components.Slider />} />
                <Route path='stepper' element={<Pages.Components.Stepper />} />
                <Route path='switch' element={<Pages.Components.Switch />} />
                <Route path='table' element={<Pages.Components.Table />} />
                <Route path='tabs' element={<Pages.Components.Tabs />} />
                <Route path='tag' element={<Pages.Components.Tag />} />
                <Route path='textarea' element={<Pages.Components.TextArea />} />
                <Route path='textfield' element={<Pages.Components.TextField />} />
                <Route path='togglegroup' element={<Pages.Components.ToggleGroup />} />
                <Route path='tooltip' element={<Pages.Components.Tooltip />} />
                <Route path='typography' element={<Pages.Components.Typography />} />
                <Route path='containers' element={<Pages.Components.Containers />} />
                <Route path='spacing' element={<Pages.Components.Spacing />} />
                <Route path='grids' element={<Pages.Components.Grids />} />
              </Route>
              <Route path='/contribute' element={<Pages.Contribute />} />
              <Route path='/*' element={<Pages.Home />} />
            </Routes>
          </TopContainer>
        </BrowserRouter>
      </Suspense>
    </Provider>
  </I18nextProvider>
)
