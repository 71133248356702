import {
  Container,
  FlexDiv,
  FlexEndDiv,
  Header,
  Link,
  Margin,
  NavBar,
  PileDiv,
  Select
} from '@cegal/ds-components'
import '@cegal/ds-css'
import { Close, Menu } from '@cegal/ds-icons'
import { setTheme } from 'actions/app'
import { MenuButton, NavDiv, SelectDiv, SelectDivMobile } from 'components/styled'
import ToC from 'components/ToC/ToC'
import { Theme } from 'declarations/app'
import useHeadings, { HeadingElement } from 'hooks/useHeadings'
import useScrollSpy from 'hooks/useScrollSpy'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store'
import styled from 'styled-components'

const themes = [
  { label: 'Cegal light', value: 'cegal-light' },
  { label: 'Cegal dark', value: 'cegal-dark' },
  { label: 'Neutral light', value: 'neutral-light' },
  { label: 'Neutral dark', value: 'neutral-dark' }
]

export type TopContainerProps = React.PropsWithChildren & {
  margins?: boolean
  toc?: boolean
}

const DsNavBar = styled(NavBar)<{ isExpanded: boolean }>`
  .cds-navbar-link {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  @media (max-width: 1227px) {
    flex-direction: column;
    align-items: baseline;
    display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
    position: relative;
    top: 100%;
    padding-bottom: 1rem;
    width: 100%;
    z-index: 999;
  }
`

const TopContainer: React.FC<TopContainerProps> = ({ children, margins = true }: TopContainerProps) => {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false)
  const dispatch = useAppDispatch()
  const theme: Theme = useAppSelector((state) => state.app.theme)
  const navigate = useNavigate()
  const switchTheme = (theme: Theme) => dispatch(setTheme(theme))
  const contentRef = useRef<HTMLDivElement | null>(null)

  // passing window.location.pathname triggers refresh on headings when page changes
  const headings: Array<HeadingElement> = useHeadings(contentRef, window.location.pathname)
  const activeId: Array<string> = useScrollSpy(
    headings.map(({ id }) => id),
    {
      root: null, // default, use viewport
      rootMargin: '0px',
      threshold: 0.5 // half of item height
    }
  )

  const currentPage = window.location.pathname.split('/')[1]
  const navBarActive = [
    '',
    'start-here',
    'changes',
    'tips',
    'accessibility',
    'resources',
    'components',
    'contribute'
  ].indexOf(currentPage)

  const showToc = !_.isEmpty(headings) && ['resources', 'components'].indexOf(currentPage) < 0

  return (
    <Container data-theme={theme}>
      <Header
        style={{
          position: 'sticky',
          top: '0rem',
          left: '0rem',
          right: '0rem',
          zIndex: '2'
        }}
      >
        <FlexEndDiv style={{ flex: 1, justifyContent: 'space-between' }}>
          <Link style={{ textDecoration: 'none' }} href='#home' onClick={() => navigate('/')}>
            <PileDiv style={{ padding: '1rem' }}>
              <img
                alt='logo'
                src={
                  theme.match('dark') ? '/static/images/cegal-white.png' : '/static/images/cegal-black.png'
                }
                style={{ height: '2rem' }}
              />
            </PileDiv>
          </Link>

          {/* -5px to hide the roundness on bottom links */}
          <DsNavBar value={navBarActive} isExpanded={isNavBarOpen}>
            <NavBar.Link onClick={() => navigate('/')}>Home</NavBar.Link>
            <NavBar.Link onClick={() => navigate('/start-here')}>Start here</NavBar.Link>
            <NavBar.Link onClick={() => navigate('/changes')}>Changes</NavBar.Link>
            <NavBar.Link onClick={() => navigate('/tips')}>Tips</NavBar.Link>
            <NavBar.Link onClick={() => navigate('/accessibility')}>Accessibility</NavBar.Link>
            <NavBar.Link onClick={() => navigate('/resources')}>Resources</NavBar.Link>
            <NavBar.Link onClick={() => navigate('/components')}>Components</NavBar.Link>
            <NavBar.Link onClick={() => navigate('/contribute')}>Contribute</NavBar.Link>
            <SelectDivMobile>
              <Select
                label='theme'
                hideLabel
                value={theme}
                onChange={(e: any) => switchTheme(e.target.value)}
              >
                {themes.map((theme: any) => (
                  <option key={theme.value} value={theme.value}>
                    {theme.label}
                  </option>
                ))}
              </Select>
            </SelectDivMobile>
          </DsNavBar>

          <SelectDiv>
            <Select label='theme' hideLabel value={theme} onChange={(e: any) => switchTheme(e.target.value)}>
              {themes.map((theme: any) => (
                <option key={theme.value} value={theme.value}>
                  {theme.label}
                </option>
              ))}
            </Select>
          </SelectDiv>

          <MenuButton onClick={() => setIsNavBarOpen(!isNavBarOpen)}>
            {!isNavBarOpen ? <Menu size='2rem' /> : <Close size='2rem' />}
          </MenuButton>
        </FlexEndDiv>
      </Header>
      <FlexDiv>
        {margins && <Margin style={{ flex: '0.5' }} />}
        <FlexDiv flex='6' style={{ maxWidth: '1600px', width: '100%' }}>
          <div style={{ width: '100%', display: 'flex' }}>
            {showToc && (
              <NavDiv style={{ flex: '1' }}>
                <ToC headings={headings} activeId={activeId} />
              </NavDiv>
            )}
            <PileDiv style={{ flex: '4', width: '100%' }} ref={contentRef}>
              {children}
            </PileDiv>
          </div>
        </FlexDiv>
        {margins && <Margin style={{ flex: '0.5' }} />}
      </FlexDiv>
    </Container>
  )
}

export default TopContainer
