import { BodyLong, Heading, VerticalSpace } from '@cegal/ds-components'
import { Chart } from '@cegal/ds-graph'
import { faker } from '@faker-js/faker'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import React from 'react'
import reactElementToJSXString from 'react-element-to-jsx-string'
;(Chart.Bar as any).displayName = 'Bar'
;(Chart.Line as any).displayName = 'Line'
;(Chart.Pie as any).displayName = 'Pie'
;(Chart.Doughnut as any).displayName = 'Doughnut'
;(Chart.PolarArea as any).displayName = 'PolarArea'
;(Chart.Radar as any).displayName = 'Radar'
;(Chart.Scatter as any).displayName = 'Scatter'
;(Chart.Bubble as any).displayName = 'Bubble'

const SampleVerticalBar = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart'
      }
    }
  }

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  }
  return <Chart.Bar options={options} data={data} />
}

const SampleHorizontalBar = () => {
  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const
      },
      title: {
        display: true,
        text: 'Chart.js Horizontal Bar Chart'
      }
    }
  }

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  }

  return <Chart.Bar options={options} data={data} />
}

const SampleStackedBar = () => {
  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked'
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  }

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        backgroundColor: 'rgb(255, 99, 132)'
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        backgroundColor: 'rgb(75, 192, 192)'
      },
      {
        label: 'Dataset 3',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        backgroundColor: 'rgb(53, 162, 235)'
      }
    ]
  }

  return <Chart.Bar options={options} data={data} />
}

const SampleGroupedStackedBar = () => {
  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked'
      }
    },
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  }

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0'
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        backgroundColor: 'rgb(75, 192, 192)',
        stack: 'Stack 0'
      },
      {
        label: 'Dataset 3',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        backgroundColor: 'rgb(53, 162, 235)',
        stack: 'Stack 1'
      }
    ]
  }

  return <Chart.Bar options={options} data={data} />
}

const SampleArea = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart'
      }
    }
  }

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Dataset 2',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  }

  return <Chart.Line options={options} data={data} />
}

const SampleLine = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart'
      }
    }
  }

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  }

  return <Chart.Line options={options} data={data} />
}

const SampleMultiaxisLine = () => {
  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Line Chart - Multi Axis'
      }
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false
        }
      }
    }
  }

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y'
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y1'
      }
    ]
  }

  return <Chart.Line options={options} data={data} />
}

const SamplePie = () => {
  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      }
    ]
  }

  return <Chart.Pie data={data} />
}

const SampleDoughnut = () => {
  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      }
    ]
  }

  return <Chart.Doughnut data={data} />
}

const SamplePolarArea = () => {
  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(153, 102, 255, 0.5)',
          'rgba(255, 159, 64, 0.5)'
        ],
        borderWidth: 1
      }
    ]
  }

  return <Chart.PolarArea data={data} />
}

const SampleRadar = () => {
  const data = {
    labels: ['Thing 1', 'Thing 2', 'Thing 3', 'Thing 4', 'Thing 5', 'Thing 6'],
    datasets: [
      {
        label: '# of Votes',
        data: [2, 9, 3, 5, 2, 3],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
      }
    ]
  }
  return <Chart.Radar data={data} />
}

const SampleScatter = () => {
  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }

  const data = {
    datasets: [
      {
        label: 'A dataset',
        data: Array.from({ length: 100 }, () => ({
          x: faker.datatype.number({ min: -100, max: 100 }),
          y: faker.datatype.number({ min: -100, max: 100 })
        })),
        backgroundColor: 'rgba(255, 99, 132, 1)'
      }
    ]
  }

  return <Chart.Scatter options={options} data={data} />
}

const SampleBubble = () => {
  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }

  const data = {
    datasets: [
      {
        label: 'Red dataset',
        data: Array.from({ length: 50 }, () => ({
          x: faker.datatype.number({ min: -100, max: 100 }),
          y: faker.datatype.number({ min: -100, max: 100 }),
          r: faker.datatype.number({ min: 5, max: 20 })
        })),
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        label: 'Blue dataset',
        data: Array.from({ length: 50 }, () => ({
          x: faker.datatype.number({ min: -100, max: 100 }),
          y: faker.datatype.number({ min: -100, max: 100 }),
          r: faker.datatype.number({ min: 5, max: 20 })
        })),
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  }

  return <Chart.Bubble options={options} data={data} />
}

const SampleMultitype = () => {
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

  const data = {
    labels,
    datasets: [
      {
        type: 'line' as const,
        label: 'Dataset 1',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
        fill: false,
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 }))
      },
      {
        type: 'bar' as const,
        label: 'Dataset 2',
        backgroundColor: 'rgb(75, 192, 192)',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: 'white',
        borderWidth: 2
      },
      {
        type: 'bar' as const,
        label: 'Dataset 3',
        backgroundColor: 'rgb(53, 162, 235)',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 }))
      }
    ]
  }

  return <Chart.Chart type='bar' data={data} />
}

const ResourcesGraph = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' spacing>
        Graphs
      </Heading>
      <VerticalSpace size='2' />
      <div>
        <BodyLong spacing>
          The @cegal/ds-charts is just a wrapper over react-chartjs-2, which also brings chart.js into React.
          The goal of @cegal/ds-charts is to make sure the themes are properly implemented on the charts, no
          extra functionality was added.
        </BodyLong>

        <a href='#chart1' className='cds-sr-only'>
          Vertical bar chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart1' spacing>
          Vertical bar chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleVerticalBar())}
}`}
        >
          <SampleVerticalBar />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart2' className='cds-sr-only'>
          Horizontal bar chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart2' spacing>
          Horizontal bar chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleHorizontalBar())}
}`}
        >
          <SampleHorizontalBar />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart3' className='cds-sr-only'>
          Stacked bar chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart3' spacing>
          Stacked bar chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleStackedBar())}
}`}
        >
          <SampleStackedBar />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart4' className='cds-sr-only'>
          Stacked grouped bar chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart4' spacing>
          Stacked grouped bar chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleGroupedStackedBar())}
}`}
        >
          <SampleGroupedStackedBar />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart5' className='cds-sr-only'>
          Area chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart5' spacing>
          Area chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleArea())}
}`}
        >
          <SampleArea />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart6' className='cds-sr-only'>
          Line chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart6' spacing>
          Line chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleLine())}
}`}
        >
          <SampleLine />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart7' className='cds-sr-only'>
          MultiAxis Line chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart7' spacing>
          MultiAxis Line chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleMultiaxisLine())}
}`}
        >
          <SampleMultiaxisLine />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart8' className='cds-sr-only'>
          Pie chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart8' spacing>
          Pie chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SamplePie())}
}`}
        >
          <SamplePie />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart9' className='cds-sr-only'>
          Doughnut chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart9' spacing>
          Doughnut chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleDoughnut())}
}`}
        >
          <SampleDoughnut />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart10' className='cds-sr-only'>
          Polar Area chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart10' spacing>
          Polar Area chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SamplePolarArea())}
}`}
        >
          <SamplePolarArea />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart11' className='cds-sr-only'>
          Radar chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart11' spacing>
          Radar chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleRadar())}
}`}
        >
          <SampleRadar />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart12' className='cds-sr-only'>
          Scatter chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart12' spacing>
          Scatter chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleScatter())}
}`}
        >
          <SampleScatter />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart13' className='cds-sr-only'>
          Bubble chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart13' spacing>
          Bubble chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleBubble())}
}`}
        >
          <SampleBubble />
        </DemoableDiv>

        <VerticalSpace size='2' />

        <a href='#chart14' className='cds-sr-only'>
          Multitype chart
        </a>
        <Heading className='toc' size='medium' level='2' id='chart14' spacing>
          Multitype chart
        </Heading>
        <DemoableDiv
          code={`import { Box } from '@cegal/ds-graph'

const Component = () => {
  return ${reactElementToJSXString(SampleMultitype())}
}`}
        >
          <SampleMultitype />
        </DemoableDiv>

        <VerticalSpace size='3' />
      </div>
    </>
  )
}

export default ResourcesGraph
