import {
  Alert,
  BodyLong,
  Button,
  FlexBaseSpacedDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Link,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import { KeyboardArrowLeft } from '@cegal/ds-icons/dist/KeyboardArrowLeft'
import { KeyboardArrowRight } from '@cegal/ds-icons/dist/KeyboardArrowRight'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React from 'react'

const ComponentsButton = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='button' level='1' spacing>
        Button
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        Button components are best suited when the user triggers actions and wait for a response. For
        navigation actions, links are better suited.
      </BodyLong>

      <BodyLong spacing>
        For example, the navigation menu bar uses links for navigation, not buttons. To add/remove elements
        from a table, a button is better suited than a link.
      </BodyLong>

      <BodyLong spacing>
        You can still style links as buttons, if you prefer. See the{' '}
        <Link href='/components/link'>Link component page</Link> to learn how to do that.
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='button-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>Default button will have a primary variant and medium size:</BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Button } from '@cegal/ds-components'

const Component = () => {
  return (
      <Button>Download recipe</Button>
  )
}`}
      >
        <Button>Download recipe</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='button-variant' level='2' spacing>
        Variants
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>variant</code> prop can be one of the following 4 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'danger'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>ButtonVariant</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='button-variant-primary' level='3' spacing>
        Primary variant
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        This is the default variant, and should be used for actions that continue the task, such as data
        submission.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Button } from '@cegal/ds-components'

const Component = () => {
  return (
      <Button variant='primary'>Download recipe</Button>
  )
}`}
      >
        <Button variant='primary'>Download recipe</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='button-variant-secondary' level='3' spacing>
        Secondary variant
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        This is the optional variant, and should be used for actions that are collateral to the task, such as
        document preview, opening a modal for more data input, etc.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Button } from '@cegal/ds-components'

const Component = () => {
  return (
      <Button variant='secondary'>Preview recipe</Button>
  )
}`}
      >
        <Button variant='secondary'>Preview recipe</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='button-variant-tertiary' level='3' spacing>
        Tertiary variant
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        This is a borderless button variant, for situations where you need a button that works visually
        inline, such as add / remove buttons for table rows.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Button } from '@cegal/ds-components'

const Component = () => {
  return (
      <Button variant='tertiary'>Recipe options</Button>
  )
}`}
      >
        <Button variant='tertiary'>Recipe options</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='small' id='button-variant-danger' level='3' spacing>
        Danger variant
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        This is a button for actions that have the potential of doing changes that are critical or
        non-reversible.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Button } from '@cegal/ds-components'

const Component = () => {
  return (
      <Button variant='danger'>Delete recipe (cannot undo)</Button>
  )
}`}
      >
        <Button variant='danger'>Delete recipe (cannot undo)</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='button-size' level='2' spacing>
        Sizes
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>The button size prop can be one of the following 3 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type ButtonSize = 'medium' | 'small' | 'xsmall'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>ButtonSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <VerticalSpace />
      <DemoableDiv
        alignContent='center'
        code={`import { Button } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexBaseSpacedDiv>
      <Button size='medium'>Medium</Button> 
      <HorizontalSpace/>
      <Button size='small'>small</Button>
      <HorizontalSpace/>
      <Button size='xsmall'>xsmall</Button>
    </FlexBaseSpacedDiv>
  )
}`}
      >
        <FlexBaseSpacedDiv>
          <Button size='medium'>Medium</Button>
          <HorizontalSpace />
          <Button size='small'>Small</Button>
          <HorizontalSpace />
          <Button size='xsmall'>Xsmall</Button>
        </FlexBaseSpacedDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='button-disabled' level='2' spacing>
        Disabled
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        It is recommended that, for buttons that are not yet ready to be pressed, you show a disabled button
        instead of hiding or not rendering the button.
      </BodyLong>

      <BodyLong spacing>
        {' '}
        This helps a lot for accessibility, and it tells users that there is something that needs to be done
        in order to activate the button, which is preferrable than not letting the user know that the button
        exists anyway.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Button } from '@cegal/ds-components'

const Component = () => {
  return (
         <Button disabled>Submit recipe</Button>
  )
}`}
      >
        <Button disabled>Submit recipe</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='button-loading' level='2' spacing>
        Loading
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        the best way to let the user know that the page is working to satisfy his request, is through a loader
        animation inside the button that was triggered by the user in the first place.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Button } from '@cegal/ds-components'

const Component = () => {
  return (
     <>
       <Button loading>Sending... </Button>
       <Button size='small' loading>Sending...</Button>
       <Button size='xsmall' loading>Sending...</Button>
     </>
  )
}`}
      >
        <FlexBaseSpacedDiv>
          <Button loading>Sending...</Button>
          <HorizontalSpace />
          <Button size='small' loading>
            Sending...
          </Button>
          <HorizontalSpace />
          <Button size='xsmall' loading>
            Sending...
          </Button>
        </FlexBaseSpacedDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='button-icons' level='2' spacing>
        Button icons
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You set the icon with the <code>icon</code> props, and set the icon position with{' '}
        <code>iconPosition</code> prop.
      </BodyLong>

      <BodyLong spacing>The button position prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type ButtonIconPosition = 'left' | 'right'
      </CodeDiv>

      <Alert>
        It is preferable to add the icon on the left position, for the majority of situations. Situations
        where the icon should be on the right include a right arrow for 'Next' navigating buttons.
      </Alert>

      <DemoableDiv
        alignContent='center'
        code={`import { Button } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
    <FlexDiv>
      <Button 
        icon={<KeyboardArrowLeft size='1.5rem'/>} 
        iconPosition='left'
      >
        Previous
      </Button>
      <HorizontalSpace/>
      <Button 
        icon={<KeyboardArrowRight size='1.5rem'/>} 
        iconPosition='right'
      >
        Next
      </Button>
      <HorizontalSpace/>
      <Button icon={<KeyboardArrowRight size='1.5rem'/>}/>
    </FlexDiv>
    <VerticalSpace/>
    <FlexDiv>
      <Button 
        size='small' 
        icon={<KeyboardArrowLeft size='1.25rem'/>} 
        iconPosition='left'
      >
        Previous
      </Button>
      <HorizontalSpace/>
      <Button 
        size='small' 
        icon={<KeyboardArrowRight size='1.25rem'/>} 
        iconPosition='right'
      >
        Next
      </Button>
      <HorizontalSpace/>
      <Button 
        size='small' 
        icon={<KeyboardArrowRight size='1.25rem'/>}
      />
    </FlexDiv>
    <VerticalSpace/>
    <FlexDiv>
      <Button 
        size='xsmall'
        icon={<KeyboardArrowLeft size='1rem'/>} 
        iconPosition='left'
      >
        Previous
      </Button>
      <HorizontalSpace/>
      <Button 
        size='xsmall' 
        icon={<KeyboardArrowRight size='1rem'/>} 
        iconPosition='right'
      >
        Next
      </Button>
      <HorizontalSpace/>
      <Button 
        size='xsmall' 
        icon={<KeyboardArrowRight size='1rem'/>}
      />
    </FlexDiv>
    </>
  )
}`}
      >
        <FlexDiv>
          <Button icon={<KeyboardArrowLeft size='1.5rem' />} iconPosition='left'>
            Previous
          </Button>
          <HorizontalSpace />
          <Button icon={<KeyboardArrowRight size='1.5rem' />} iconPosition='right'>
            Next
          </Button>
          <HorizontalSpace />
          <Button icon={<KeyboardArrowRight size='1.5rem' />} />
        </FlexDiv>
        <VerticalSpace />
        <FlexDiv>
          <Button size='small' icon={<KeyboardArrowLeft size='1.25rem' />} iconPosition='left'>
            Previous
          </Button>
          <HorizontalSpace />
          <Button size='small' icon={<KeyboardArrowRight size='1.25rem' />} iconPosition='right'>
            Next
          </Button>
          <HorizontalSpace />
          <Button size='small' icon={<KeyboardArrowRight size='1.25rem' />} />
        </FlexDiv>
        <VerticalSpace />
        <FlexDiv>
          <Button size='xsmall' icon={<KeyboardArrowLeft size='1rem' />} iconPosition='left'>
            Previous
          </Button>
          <HorizontalSpace />
          <Button size='xsmall' icon={<KeyboardArrowRight size='1rem' />} iconPosition='right'>
            Next
          </Button>
          <HorizontalSpace />
          <Button size='xsmall' icon={<KeyboardArrowRight size='1rem' />} />
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>ButtonProps</code> extends <code>React.HTMLAttributes&lt;HTMLButtonElement&gt;</code>, as
        the top level uses a <code>button</code> tag. As such, you can pass <code>button</code> props from{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLButtonElement'>
          HTMLButtonElement
        </Link>{' '}
        interface, such as <code>type</code>,{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props
        such as <code>style</code>, as well as{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/UIEvent'>UI events</Link> such as{' '}
        <code>onClick</code>. They will all be passed to the <code>&lt;button&gt;</code> element.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>disabled</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets button to disabled</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>icon</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>ReactElement</code>
              </Table.DataCell>
              <Table.DataCell>Renders a button icon</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>iconPosition</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'left' | 'right'</code>
              </Table.DataCell>
              <Table.DataCell>Sets the button's icon position</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>left</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>loading</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets button with a loading animation</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small' | 'xsmall'</code>
              </Table.DataCell>
              <Table.DataCell>Sets button size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds a margin bottom</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'primary' | 'secondary' | 'tertiary' | 'danger'</code>
              </Table.DataCell>
              <Table.DataCell>Sets button variant</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>primary</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsButton
