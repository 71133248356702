import { Heading, VerticalSpace, BodyLong, Link, Table, ProgressBar, Button } from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useEffect, useState } from 'react'

const Progressbar = () => {
  const size = 300
  const [progress, setProgress] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setProgress((currentProgress) => {
          const advancement = Math.floor(Math.random() * 8)
          if (currentProgress + advancement < size) {
            return currentProgress + advancement
          } else {
            clearInterval(interval)
            setLoading(false)
            return size
          }
        })
      }, 50)
    }
  }, [loading])

  const running = progress > 0
  let helperText = running ? `${progress}MB of ${size}MB` : 'Starting...'
  if (progress >= size) {
    helperText = 'Done'
  }

  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='link' level='1' spacing>
        Progress bar
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>ProgressBar</code> provides insights into the duration and advancement of a process, such as
        a download, file transfer, or installation, helping users assess how long they'll have to wait.
      </BodyLong>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='popover-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>The default component uses a primary variant, medium size</BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { Progressbar } from '@cegal/ds-components'

const Component = () => {
  return (
    <Progressbar now={60}/>
  )
}`}
      >
        <ProgressBar now={60} />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-sizes' level='2' spacing>
        Sizes
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type ProgressBarSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>ProgressBarSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { ProgressBar } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <ProgressBar 
        size='medium' 
        now={30}/> 
      />
      <ProgressBar 
        size='small' 
        now={60}/> 
    />
    </>
  )
}`}
      >
        <ProgressBar size='medium' now={30} />
        <ProgressBar size='small' now={60} />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-texts' level='2' spacing>
        Labels
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>Label can have two different ways for text alignment: default or inline.</BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { ProgressBar } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <ProgressBar 
        size='small' 
        now={30}/>
        label='Default'
      />
      <ProgressBar 
        size='small' 
        now={60}/> 
        label='Inline'
        inline={true}
    />
    </>
  )
}`}
      >
        <ProgressBar size='small' now={30} label='Default label' />
        <VerticalSpace />
        <ProgressBar size='small' now={60} label='Inline label' inline />
      </DemoableDiv>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-texts' level='2' spacing>
        Helper text
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>Helper text is used to show additional information about the process.</BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { ProgressBar } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <ProgressBar 
        size='small' 
        now={30}/>
        label='Default'
        helperText='Helper text'
      />
    </>
  )
}`}
      >
        <ProgressBar size='small' now={30} label='Default label' helperText='Helper text' />
      </DemoableDiv>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-sizes' level='2' spacing>
        Variants
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>variant</code> prop can be one of the following 3 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type ProgressBarVariant = 'error' | 'default' | 'success'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>ProgressBarVariant</code> from <code>@cegal/ds-components</code>
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { ProgressBar } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <ProgressBar 
        variant='default'
        size='small' 
        now={30}
        label='Default'
      />
      <ProgressBar
        variant='success'
        size='small'
        now={60}
        label='Success'
      />
      <ProgressBar
        variant='error'
        size='small'
        now={60}
        label='Error'
      />
    </>
  )
}`}
      >
        <ProgressBar variant='default' size='small' now={30} label='Default' helperText='Helper text' />
        <VerticalSpace />
        <ProgressBar variant='success' size='small' now={60} label='Success' helperText='Helper text' />
        <VerticalSpace />
        <ProgressBar variant='error' size='small' now={60} label='Error' helperText='Helper text' />
      </DemoableDiv>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-sizes' level='2' spacing>
        Icons
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>icon</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type ProgressBarIcon = 'success' | 'error'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>ProgressBarIcon</code> from <code>@cegal/ds-components</code>
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { ProgressBar } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <ProgressBar
        variant='success'
        size='small'
        now={100}
        label='Success'
        icon='success'
        helperText='Done'
      />
      <ProgressBar
        variant='error'
        size='small'
        now={30}
        label='Error'
        icon='error'
        helperText='An error has occurred'
      />
    </>
  )
}`}
      >
        <ProgressBar
          variant='success'
          size='small'
          now={100}
          label='Success'
          icon='success'
          helperText='Done'
        />
        <VerticalSpace />
        <ProgressBar
          variant='error'
          size='small'
          now={30}
          label='Error'
          icon='error'
          helperText='An error has occurred'
        />{' '}
      </DemoableDiv>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-texts' level='2' spacing>
        Indeterminate
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>Indeterminate progressbars is used for progresses with no value to track</BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { ProgressBar } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <ProgressBar
        variant='default'
        indeterminate={true}
        label='Default indeterminate'
        size='small'
      />
    </>
  )
}`}
      >
        <ProgressBar variant='default' indeterminate label='Default indeterminate' size='small' />
      </DemoableDiv>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-texts' level='2' spacing>
        Animation
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>Add animation prop to animate the stripes right to left.</BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { ProgressBar } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <ProgressBar
        variant='default'
        now={30}
        animation={true}
        size='medium'
      />
      <ProgressBar
        variant='error'
        now={60}
        animation={true}
        size='medium'
      />
      <ProgressBar
        variant='success'
        now={80}
        animation={true}
        size='medium'
      />
    </>
  )
}`}
      >
        <ProgressBar variant='default' now={30} animation size='medium' />
        <ProgressBar variant='error' now={60} animation size='medium' />
        <ProgressBar variant='success' now={80} animation size='medium' />
      </DemoableDiv>
      <VerticalSpace size='2' />

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='pagination-texts' level='2' spacing>
        Example
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        This example contains a preview of functionality and styles available for the ProgressBar.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { ProgressBar, Button } from '@cegal/ds-components'
import { useEffect, useState } from 'react'

const Component = () => {
  const size = 300
  const [progress, setProgress] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setProgress((currentProgress) => {
          const advancement = Math.floor(Math.random() * 8)
          if (currentProgress + advancement < size) {
            return currentProgress + advancement
          } else {
            clearInterval(interval)
            setLoading(false)
            return size
          }
        })
      }, 50)
    }
  }, [loading])

  const running = progress > 0
  let helperText = running ? \`\${progress}MB of \${size}MB\` : 'Starting...'
  if (progress >= size) {
    helperText = 'Done'
  }

  return (
    <>
      <Button onClick={() => setLoading(true)} disabled={running}>
        Start Progress
      </Button>
      <ProgressBar
        variant={progress < size ? 'default' : 'success'}
        icon={progress < size ? null : 'success'}
        now={running ? progress : null}
        max={size}
        helperText={helperText}
        indeterminate={!running}
        label='Upload data'
      />
    </>
  )
}`}
      >
        <Button onClick={() => setLoading(true)} disabled={running}>
          Start Progress
        </Button>
        <ProgressBar
          variant={progress < size ? 'default' : 'success'}
          icon={progress < size ? null : 'success'}
          now={running ? progress : undefined}
          max={size}
          helperText={helperText}
          indeterminate={!running}
          label='Upload data'
        />
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>ProgressBarProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLDivElement&gt;</code>, as the top level uses a <code>div</code> tag.
        As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLDivElement'>HTMLDivElement</Link>{' '}
        props.
      </BodyLong>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>animation</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Animate stripes from right to left</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>helperText</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>
                Helper text helps provide extra information about the ongoing process.
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>icon</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'success' | 'error'</code>
              </Table.DataCell>
              <Table.DataCell>Add icon to ProgressBar</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>indeterminate</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Make the ProgressBar indeterminate</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>inline</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Shows the label inline</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>{' '}
            <Table.Row>
              <Table.DataCell>
                <code>label</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>
                The label explains the process that the progress bar represents.
              </Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets ProgressBar height</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>now</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Current value of progress</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>max</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Sets the maximum progress value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>100</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'error' | 'default' | 'success'</code>
              </Table.DataCell>
              <Table.DataCell>Sets ProgressBar variant</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>default</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default Progressbar
