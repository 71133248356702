import { Heading, VerticalSpace, BodyLong, Link, PileDiv, Table } from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React from 'react'

const ComponentsLink = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='link' level='1' spacing>
        Link
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>Link</code> component is a styled anchor element. Use it preferably for actions that take
        the user to a new page, internal or external, without data submission.
      </BodyLong>

      <BodyLong spacing>
        For external links, it is recommended that you add a <code>target='blank'</code> attribute, so that
        the linked page opens in a new tab. Add also an external link icon, so the user knows that pressing
        the link will take him to an external page.
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='link-default' level='2' spacing>
        Variant
      </Heading>

      <VerticalSpace size='2' />
      <BodyLong spacing>
        The link variant prop is just a style preference. There is no difference other than the primary
        variant is more visible with a background color change, and the secondary variant more subtle whith an
        underline color change.
      </BodyLong>

      <BodyLong spacing>
        The link variant prop defaults to <code>primary</code> and can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type LinkVariant = 'primary' | 'secondary'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>ButtonVariant</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Link } from '@cegal/ds-components'

const Component = () => {
  return ( 
    <PileDiv>
      <BodyLong>
        This is a
         <Link 
           variant='primary' 
           href='#'
         >
           link with a primary variant 
         </Link> 
         .
      </BodyLong>
      <BodyLong>
        This is a 
        <Link 
          variant='secondary' 
          href='#'
        >
          link with a secondary variant
        </Link>
         .
      </BodyLong>
    </PileDiv>
  )
}`}
      >
        <PileDiv>
          <BodyLong>
            This is a{' '}
            <Link variant='primary' href='#'>
              link with a primary variant
            </Link>
            .
          </BodyLong>
          <BodyLong>
            This is a{' '}
            <Link variant='secondary' href='#'>
              link with a secondary variant
            </Link>
            .
          </BodyLong>
        </PileDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>LinkProps</code> interface extends{' '}
        <code>React.HTMLAttributes&lt;HTMLAnchorElement&gt;</code>, as the top level uses a <code>a</code>{' '}
        tag. As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement'>
          HTMLAnchorElement
        </Link>{' '}
        props and <Link href='https://developer.mozilla.org/en-US/docs/Web/API/UIEvent'>UI events</Link> such
        as <code>onClick</code>.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds a margin bottom</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'primary' | 'secondary'</code>
              </Table.DataCell>
              <Table.DataCell>Sets link variant</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>primary</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsLink
