import {
  Heading,
  BodyLong,
  HorizontalSpace,
  FlexDiv,
  VerticalSpace,
  Table,
  Select
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsSelect = () => {
  const [value1, setValue1] = useState<string>('Chocolate cake')
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='select' level='1' spacing>
        Select
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        This is a native <code>select</code> component that lets you choose a single value out of an options
        list
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='select-default' level='2' spacing>
        Default
      </Heading>
      <BodyLong spacing>The default component comes in a medium size.</BodyLong>
      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
    <Select label="Choose your cake">
      <option value="chocolateCake">Chocolate cake</option>
      <option value="cheeseCake">Cheesecake</option>
      <option value="carrotCake">Carrot cake</option>
    </Select>
  )
}`}
      >
        <Select label='Choose your cake'>
          <option value='chocolateCake'>Chocolate cake</option>
          <option value='cheeseCake'>Cheesecake</option>
          <option value='carrotCake'>Carrot cake</option>
        </Select>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-controlled' level='2' spacing>
        Controlled
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        To control the component, set the <code>value</code> and the <code>onChange</code> props.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  const [value,  setValue] = useState<string>('Chocolate cake')  
  return (
     <Select 
       label={'Chosen cake: ' + value}
       value={value}
       onChange={(e: React.ChangeEvent<HTMLSelectElement>) => 
         setValue(e.target.value)}
     >
      <option value="Chocolate cake">Chocolate cake</option>
      <option value="Cheesecake">Cheesecake</option>
      <option value="Carrot cake">Carrot cake</option>
    </Select>
  )
}`}
      >
        <Select
          label={'Chosen cake: ' + value1}
          value={value1}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setValue1(e.target.value)}
        >
          <option value='Chocolate cake'>Chocolate cake</option>
          <option value='Cheesecake'>Cheesecake</option>
          <option value='Carrot cake'>Carrot cake</option>
        </Select>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-size' level='2' spacing>
        Size
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <FlexDiv>
       <Select label={'Medium cake'} size='medium'>
       <option value="Chocolate cake">Chocolate cake</option>
       <option value="Cheesecake">Cheesecake</option>
       <option value="Carrot cake">Carrot cake</option>
      </Select>
      <HorizontalSpace/>
      <Select label={'Small cake'} size='small'>
        <option value="Chocolate cake">Chocolate cake</option>
        <option value="Cheesecake">Cheesecake</option>
        <option value="Carrot cake">Carrot cake</option>
      </Select>
    </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <Select label='Medium cake' size='medium'>
            <option value='Chocolate cake'>Chocolate cake</option>
            <option value='Cheesecake'>Cheesecake</option>
            <option value='Carrot cake'>Carrot cake</option>
          </Select>
          <HorizontalSpace />
          <Select label='Small cake' size='small'>
            <option value='Chocolate cake'>Chocolate cake</option>
            <option value='Cheesecake'>Cheesecake</option>
            <option value='Carrot cake'>Carrot cake</option>
          </Select>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-disable' level='2' spacing>
        Disable
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>disabled</code> prop deactivates the component.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <Select label='No more cakes' disabled>
      <option value="Chocolate cake">Chocolate cake</option>
      <option value="Cheesecake">Cheesecake</option>
      <option value="Carrot cake">Carrot cake</option>
    </Select>
  )
}`}
      >
        <Select label='No more cakes' disabled>
          <option value='Chocolate cake'>Chocolate cake</option>
          <option value='Cheesecake'>Cheesecake</option>
          <option value='Carrot cake'>Carrot cake</option>
        </Select>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-error' level='2' spacing>
        Error
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>error</code> prop sets an error border.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <Select label='You must eat a cake today' error='Choose a cake'>
       <option value="Chocolate cake">Chocolate cake</option>
       <option value="Cheesecake">Cheesecake</option>
       <option value="Carrot cake">Carrot cake</option>
    </Select>
  )
}`}
      >
        <Select label='You must eat a cake today' error='Choose a cake'>
          <option value='Chocolate cake'>Chocolate cake</option>
          <option value='Cheesecake'>Cheesecake</option>
          <option value='Carrot cake'>Carrot cake</option>
        </Select>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-loading' level='2' spacing>
        Loading
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>loading</code> prop sets a loading animation, useful when you need to load the options list
        asynchronously.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <Select label='Loading cakes' loading>
        <option value="Chocolate cake">Chocolate cake</option>
        <option value="Cheesecake">Cheesecake</option>
        <option value="Carrot cake">Carrot cake</option>
    </Select>
  )
}`}
      >
        <Select label='Loading cakes' loading>
          <option value='Chocolate cake'>Chocolate cake</option>
          <option value='Cheesecake'>Cheesecake</option>
          <option value='Carrot cake'>Carrot cake</option>
        </Select>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-hide-label' level='2' spacing>
        Hide label
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>hideLabel</code> prop hides the label, but still lets screen readers access the label text.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
    <Select label='Hidden label' hideLabel>
      <option value="Chocolate cake">Chocolate cake</option>
      <option value="Cheesecake">Cheesecake</option>
      <option value="Carrot cake">Carrot cake</option>
    </Select>
  )
}`}
      >
        <Select label='Hidden label' hideLabel>
          <option value='Chocolate cake'>Chocolate cake</option>
          <option value='Cheesecake'>Cheesecake</option>
          <option value='Carrot cake'>Carrot cake</option>
        </Select>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-hide-label' level='2' spacing>
        Description
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>description</code> prop gives a longer caption to describe the choice being made.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <Select label='Choose a cake' description='Only your favourite cakes are listed'>
      <option value="Chocolate cake">Chocolate cake</option>
      <option value="Cheesecake">Cheesecake</option>
      <option value="Carrot cake">Carrot cake</option>
    </Select>
  )
}`}
      >
        <Select label='Choose a cake' description='Only your favourite cakes are listed'>
          <option value='Chocolate cake'>Chocolate cake</option>
          <option value='Cheesecake'>Cheesecake</option>
          <option value='Carrot cake'>Carrot cake</option>
        </Select>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets error styles</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides select label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Disables the select</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets select size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets select value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>loading</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets select loading animation</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>description</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Set select description</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsSelect
