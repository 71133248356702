import {
  BodyLong,
  FlexEndDiv,
  Heading,
  HorizontalSpace,
  Link,
  Loader,
  PileCenterDiv,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React from 'react'

const ComponentsLoader = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='loader' level='1' spacing>
        Loader
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>Loader</code> component renders a spinning circular image, using SVG elements.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='loader-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>The default component uses a primary variant, medium size.</BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Loader } from '@cegal/ds-components'

const Component = () => {
  return (
    <Loader/>
  )
}`}
      >
        <Loader />
      </DemoableDiv>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='loader-size' level='2' spacing>
        Size
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 7 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type LoaderSize = '2xlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | '2xsmall';
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>LoaderSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Size</Table.ColumnHeader>
              <Table.ColumnHeader>CSS Variable</Table.ColumnHeader>
              <Table.ColumnHeader>rem/px size in Cegal theme</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>2xlarge</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>var(--cds-size-24)</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>6rem (96px)</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>xlarge</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>var(--cds-size-16)</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>4rem (64px)</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>large</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>var(--cds-size-12)</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>3rem (48px)</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>var(--cds-size-8)</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>2rem (32px)</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>small</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>var(--cds-size-6)</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>1.5rem (24px)</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>xsmall</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>var(--cds-size-5)</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>1.5rem (20px)</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>2xsmall</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>var(--cds-size-4)</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>1rem (16px)</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>

      <DemoableDiv
        alignContent='center'
        code={`import { Loader } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexEndDiv>
      <PileCenterDiv>
        <Loader size='2xlarge' />
        <span style={{ whiteSpace: 'nowrap' }}>2x large</span>
      </PileCenterDiv>
      <HorizontalSpace />
      <PileCenterDiv>
        <Loader size='xlarge' />
        <span style={{ whiteSpace: 'nowrap' }}>x large</span>
      </PileCenterDiv>
      <HorizontalSpace />
      <PileCenterDiv>
        <Loader size='large' />
        <span style={{ whiteSpace: 'nowrap' }}>large</span>
      </PileCenterDiv>
      <HorizontalSpace />
      <PileCenterDiv>
        <Loader size='medium' />
        <span style={{ whiteSpace: 'nowrap' }}>medium</span>
      </PileCenterDiv>
      <HorizontalSpace />
      <PileCenterDiv>
        <Loader size='small' />
        <span style={{ whiteSpace: 'nowrap' }}>small</span>
      </PileCenterDiv>
      <HorizontalSpace />
      <PileCenterDiv>
        <Loader size='xsmall' />
        <span style={{ whiteSpace: 'nowrap' }}>x small</span>
      </PileCenterDiv>
      <HorizontalSpace />
      <PileCenterDiv>
        <Loader size='2xsmall' />
        <span style={{ whiteSpace: 'nowrap' }}>2x small</span>
      </PileCenterDiv>
    </FlexEndDiv>
  )
}`}
      >
        <FlexEndDiv>
          <PileCenterDiv>
            <Loader size='2xlarge' />
            <span style={{ whiteSpace: 'nowrap' }}>2x large</span>
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Loader size='xlarge' />
            <span style={{ whiteSpace: 'nowrap' }}>x large</span>
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Loader size='large' />
            <span style={{ whiteSpace: 'nowrap' }}>large</span>
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Loader size='medium' />
            <span style={{ whiteSpace: 'nowrap' }}>medium</span>
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Loader size='small' />
            <span style={{ whiteSpace: 'nowrap' }}>small</span>
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Loader size='xsmall' />
            <span style={{ whiteSpace: 'nowrap' }}>x small</span>
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Loader size='2xsmall' />
            <span style={{ whiteSpace: 'nowrap' }}>2x small</span>
          </PileCenterDiv>
        </FlexEndDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='loader-transparent' level='2' spacing>
        Transparency
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can use <code>transparent</code> prop to set the second color as transparent.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Loader } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexEndDiv>
      <PileCenterDiv>
        <Loader size='2xlarge'/>
        <span>Default</span>
      </PileCenterDiv>
      <HorizontalSpace />
      <PileCenterDiv>
        <Loader size='2xlarge' transparent/>
        <span>Transparent</span>
      </PileCenterDiv>
    </FlexEndDiv>
  )
}`}
      >
        <FlexEndDiv>
          <PileCenterDiv>
            <Loader size='2xlarge' />
            <span>Default</span>
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Loader size='2xlarge' transparent />
            <span>Transparent</span>
          </PileCenterDiv>
        </FlexEndDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='loader-transparent' level='2' spacing>
        Variant
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>variant</code> prop can be one of the following 3 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type LoaderVariant = 'primary' | 'secondary' | 'inverted';
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>LoaderVariant</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Loader } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexEndDiv>
      <PileCenterDiv>
        <Loader size='2xlarge' variant='primary'/>
        <span>Primary</span>
      </PileCenterDiv>
      <HorizontalSpace />
      <PileCenterDiv>
        <Loader size='2xlarge' variant='secondary'/>
        <span>Secondary</span>
      </PileCenterDiv>
        <HorizontalSpace />
      <PileCenterDiv>
        <div style={{backgroundColor: 'var(--cds-background-color-inverted)'}}>
          <Loader size='2xlarge' variant='inverted'/>
        </div>
        <span>Inverted</span>
      </PileCenterDiv>
    </FlexEndDiv>
  )
}`}
      >
        <FlexEndDiv>
          <PileCenterDiv>
            <Loader size='2xlarge' variant='primary' />
            <span>Primary</span>
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <Loader size='2xlarge' variant='secondary' />
            <span>Secondary</span>
          </PileCenterDiv>
          <HorizontalSpace />
          <PileCenterDiv>
            <div style={{ backgroundColor: 'var(--cds-background-color-inverted)' }}>
              <Loader size='2xlarge' variant='inverted' />
            </div>
            <span>Inverted</span>
          </PileCenterDiv>
        </FlexEndDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>LoaderProps</code> interface extends <code>React.SVGProps&lt;SVGSVGElement&gt;</code>, as
        the top level uses a <code>svg</code> tag. As such, you can pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/SVGSVGElement'>SVGSVGElement</Link>{' '}
        props.
      </BodyLong>

      <VerticalSpace />

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeader>Name</Table.ColumnHeader>
            <Table.ColumnHeader>Type</Table.ColumnHeader>
            <Table.ColumnHeader>Description</Table.ColumnHeader>
            <Table.ColumnHeader>Required</Table.ColumnHeader>
            <Table.ColumnHeader>Default</Table.ColumnHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.DataCell>
              <code>size</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>'2xlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | '2xsmall'</code>
            </Table.DataCell>
            <Table.DataCell>Sets loader size</Table.DataCell>
            <Table.DataCell>No</Table.DataCell>
            <Table.DataCell>
              <code>medium</code>
            </Table.DataCell>
          </Table.Row>
          <Table.Row>
            <Table.DataCell>
              <code>title</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>React.ReactNode</code>
            </Table.DataCell>
            <Table.DataCell>Sets loader title</Table.DataCell>
            <Table.DataCell>No</Table.DataCell>
            <Table.DataCell>
              <code>-</code>
            </Table.DataCell>
          </Table.Row>
          <Table.Row>
            <Table.DataCell>
              <code>transparent</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>boolean</code>
            </Table.DataCell>
            <Table.DataCell>Sets loader background transparency</Table.DataCell>
            <Table.DataCell>No</Table.DataCell>
            <Table.DataCell>
              <code>false</code>
            </Table.DataCell>
          </Table.Row>
          <Table.Row>
            <Table.DataCell>
              <code>variant</code>
            </Table.DataCell>
            <Table.DataCell>
              <code>'primary' | 'secondary' | 'inverted'</code>
            </Table.DataCell>
            <Table.DataCell>Sets loader variant</Table.DataCell>
            <Table.DataCell>No</Table.DataCell>
            <Table.DataCell>
              <code>primary</code>
            </Table.DataCell>
          </Table.Row>
        </Table.Body>
      </Table>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsLoader
