import { Accordion, VerticalSpace, Button, Content, FlexDiv, Label, PaddedDiv } from '@cegal/ds-components'
import { ContentDiv, NavDiv2, SideBarDiv } from 'components/styled'
import ToC from 'components/ToC/ToC'
import { KeyboardArrowLeft } from '@cegal/ds-icons/dist/KeyboardArrowLeft'
import useHeadings from 'hooks/useHeadings'
import useScrollSpy from 'hooks/useScrollSpy'
import React, { useRef } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const Route = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const contentRef = useRef(null)
  const headings = useHeadings(contentRef, location.pathname)
  const activeId = useScrollSpy(
    headings.map(({ id }) => id),
    {
      root: null, // default, use viewport
      rootMargin: '0px',
      threshold: 0.5 // half of item height
    }
  )

  const paths = location.pathname.split('/')
  const components = [
    { label: 'Accordion', value: 'accordion' },
    { label: 'Alert', value: 'alert' },
    { label: 'Avatar', value: 'avatar' },
    { label: 'Box', value: 'box' },
    { label: 'Button', value: 'button' },
    { label: 'Carousel', value: 'carousel' },
    { label: 'Checkbox', value: 'checkbox' },
    { label: 'Checkbox group', value: 'checkboxgroup' },
    { label: 'Chip', value: 'chip' },
    { label: 'Counter', value: 'counter' },
    { label: 'Datepicker', value: 'datepicker' },
    { label: 'Drawer', value: 'drawer' },
    { label: 'Dropdown', value: 'dropdown' },
    { label: 'Dropzone', value: 'dropzone' },
    { label: 'Error summary', value: 'errorsummary' },
    { label: 'Fieldset', value: 'fieldset' },
    { label: 'Help text', value: 'helptext' },
    { label: 'Link', value: 'link' },
    { label: 'Link panel', value: 'linkpanel' },
    { label: 'Loader', value: 'loader' },
    { label: 'Modal', value: 'modal' },
    { label: 'Nav bar', value: 'navbar' },
    { label: 'Pagination', value: 'pagination' },
    { label: 'Panel', value: 'panel' },
    { label: 'Popover', value: 'popover' },
    { label: 'Progress bar', value: 'progressbar' },
    { label: 'Radio group', value: 'radiogroup' },
    { label: 'Range slider', value: 'rangeslider' },
    { label: 'Read more', value: 'readmore' },
    { label: 'Search', value: 'search' },
    { label: 'Select', value: 'select' },
    { label: 'Select multiple', value: 'selectmultiple' },
    { label: 'Slider', value: 'slider' },
    { label: 'Stepper', value: 'stepper' },
    { label: 'Switch', value: 'switch' },
    { label: 'Table', value: 'table' },
    { label: 'Tabs', value: 'tabs' },
    { label: 'Tag', value: 'tag' },
    { label: 'Text area', value: 'textarea' },
    { label: 'Text field', value: 'textfield' },
    { label: 'Toggle group', value: 'togglegroup' },
    { label: 'Tooltip', value: 'tooltip' },
    { label: 'Typography', value: 'typography' }
  ]
  const layouts = [
    { label: 'Containers', value: 'containers' },
    { label: 'Spacing', value: 'spacing' },
    { label: 'Grids', value: 'grids' }
  ]

  return (
    <Content>
      <FlexDiv>
        <NavDiv2>
          <VerticalSpace size='5' />
          {paths.length > 2 && (
            <PaddedDiv>
              <Button
                variant='secondary'
                icon={<KeyboardArrowLeft size='1.5rem' />}
                iconPosition='left'
                onClick={() => navigate('/components')}
              >
                Back
              </Button>
            </PaddedDiv>
          )}
          <div style={{ padding: '0.5rem' }}>
            <Label>Components</Label>
          </div>
          <hr />
          <Accordion size='small' arrow={false}>
            {components.map((el) => (
              <Accordion.Item key={el.value} open={el.value === paths[2]}>
                <Accordion.Header
                  onClick={() => {
                    navigate('/components/' + el.value)
                  }}
                >
                  {el.label}
                </Accordion.Header>
                <Accordion.Content style={{ padding: '0' }}>
                  <ToC headings={headings} activeId={activeId} />
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion>
          <VerticalSpace />
          <div style={{ padding: '0.5rem' }}>
            <Label>Layouts</Label>
          </div>
          <hr />
          <Accordion size='small' arrow={false}>
            {layouts.map((el) => (
              <Accordion.Item key={el.value} open={el.value === paths[2]}>
                <Accordion.Header
                  disabled={el.value === paths[2]}
                  onClick={() => {
                    navigate('/components/' + el.value)
                  }}
                >
                  {el.label}
                </Accordion.Header>
                <Accordion.Content style={{ padding: '0' }}>
                  <ToC headings={headings} activeId={activeId} />
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion>
        </NavDiv2>
        <SideBarDiv />
        <ContentDiv ref={contentRef}>
          <Outlet />
        </ContentDiv>
      </FlexDiv>
    </Content>
  )
}

export default Route
