import {
  Heading,
  BodyLong,
  Select,
  Table,
  Tag,
  FlexDiv,
  HorizontalSpace,
  VerticalSpace,
  Button,
  Tooltip,
  FlexEndDiv
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import React, { useState } from 'react'

import { Print } from '@cegal/ds-icons'
import { TableWrapperDiv } from 'components/styled'

const ComponentsTooltip = () => {
  const [placement, setPlacement] = useState<string>('top')
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='Tooltip' level='1' spacing>
        Tooltip
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>Tooltip component shows a ballon with contextual text.</BodyLong>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Tooltip-default' level='2' spacing>
        Default
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        You must provide an HTML element to attach the Tooltip elements, so it flows along with the elements
        that are being pointed to. If you
      </BodyLong>
      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Tooltip } from '@cegal/ds-components'

const Component = () => {
  return (
    <Tooltip content="Print this page">
      <Button size='small' icon={<Print title="demo knapp" />} />
    </Tooltip>
  )
}`}
      >
        <Tooltip content='Print this page'>
          <Button size='small' icon={<Print size='1.5rem' title='demo knapp' />} />
        </Tooltip>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Tooltip-variant' level='2' spacing>
        Variant
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        Tooltip has two variants, <code>primary</code> (default) and secondary
      </BodyLong>
      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Tooltip } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexDiv>
      <Tooltip variant='primary' content="Primary variant">
        <Tag>Primary</Tag>
      </Tooltip>
      <HorizontalSpace/>
      <Tooltip variant='secondary' content="Secondary variant">
        <Tag>Secondary</Tag>
      </Tooltip>
    </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <Tooltip variant='primary' content='Primary variant'>
            <Tag>Primary</Tag>
          </Tooltip>
          <HorizontalSpace />
          <Tooltip variant='secondary' content='Secondary variant'>
            <Tag>Secondary</Tag>
          </Tooltip>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Tooltip-placement' level='2' spacing>
        Placement
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>The placement prop can ber one of these values:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type TooltipPlacement = "top" | "left" | "bottom" | "right"
      </CodeDiv>
      <BodyLong spacing>
        you can import TooltipPlacement from <code>@cegal/ds-components</code>.
      </BodyLong>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Tooltip } from '@cegal/ds-components'

const Component = () => {
  return (
   <FlexEndDiv>
      <Select onChange={(e: any) => setPlacement(e.target.value)}>
        <option>top</option>
        <option>left</option>
        <option>bottom</option>
        <option>right</option>
      </Select>
      <HorizontalSpace/>
      <Tooltip placement={placement} content={'Tooltip is placed on ' + placement}>
        <BodyLong>Hover me for tooltip</BodyLong>
      </Tooltip>
    </FlexEndDiv>
  )
}`}
      >
        <FlexEndDiv>
          <Select label='placement' hideLabel onChange={(e: any) => setPlacement(e.target.value)}>
            <option>top</option>
            <option>left</option>
            <option>bottom</option>
            <option>right</option>
          </Select>
          <HorizontalSpace />
          <Tooltip placement={placement as any} content={'Tooltip is placed on ' + placement}>
            <BodyLong>Hover me for tooltip</BodyLong>
          </Tooltip>
        </FlexEndDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Tooltip-keys' level='2' spacing>
        Keys
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        You can render shortcut keys with the <code>keys</code> prop
      </BodyLong>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Tooltip } from '@cegal/ds-components'

const Component = () => {
  return (
      <Tooltip keys={['cmd', 'p']}  content="Print this page">
        <Button icon={<Print title="demo knapp" />} />
      </Tooltip>
    </FlexEndDiv>
  )
}`}
      >
        <Tooltip keys={['cmd', 'p']} content='Print this page'>
          <Button icon={<Print title='demo knapp' />} />
        </Tooltip>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Tooltip-arrow' level='2' spacing>
        Arrow and offset
      </Heading>
      <VerticalSpace size='2' />
      <BodyLong spacing>
        You can remove the Tooltip arrow by setting prop <code>arrow</code> to <code>false</code>. You can
        also define the offset with a numeric value in the <code>offset</code> prop.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Tooltip } from '@cegal/ds-components'

const Component = () => {
  return (
    <Tooltip 
      arrow={false} 
      offset='50'
      content='This Tooltip has no arrow and has a 50px offset'
    >
       <BodyLong>Hover me for tooltip</BodyLong>
    </Tooltip>
  )
}`}
      >
        <Tooltip arrow={false} offset={100} content='This Tooltip has no arrow and has a 100px offset'>
          <BodyLong>Hover me for tooltip</BodyLong>
        </Tooltip>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace size='2' />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>open</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Set Tooltip open</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>defaultOpen</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Set Tooltip default open</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>placement</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>"top" | "left" | "bottom" | "right"</code>
              </Table.DataCell>
              <Table.DataCell>Set Tooltip placement</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>auto</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>arrow</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Set Tooltip arrow</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>offset</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Set Tooltip offset to anchor element</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>10</code> with arrow, <code>2</code> without arrow
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>content</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Tooltip content</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>maxChar</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Tooltip maximum characters</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>80</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>delay</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Tooltip delay in milliseconds</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>150</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'primary' | 'secondary'</code>
              </Table.DataCell>
              <Table.DataCell>Tooltip variant</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>primary</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsTooltip
