import { Heading, Stepper, VerticalSpace, BodyLong, Table } from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsStepper = () => {
  const [activeStep1, setActiveStep1] = useState<number>(1)
  const [activeStep2, setActiveStep2] = useState<number>(1)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='Stepper' level='1' spacing>
        Stepper
      </Heading>

      <VerticalSpace />
      <BodyLong spacing>
        {' '}
        <code>Steppers</code> are great to reduce the complexity of a big form into smaller guided steps
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Stepper-default' level='2' spacing>
        Default component
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The component requires at least <code>activeStep</code> prop. The <code>onStepChange</code> prop is a
        callback for a step change, that you want to set, so you can have control of the stepper.
      </BodyLong>

      <BodyLong spacing>The first step starts at number 1.</BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Stepper } from '@cegal/ds-components'

const Component = () => {
  const [activeStep, setActiveStep] = useState<number>(1)
  return (
     <Stepper
        activeStep={activeStep}
        onStepChange={setActiveStep}
      >
        <Stepper.Step>Make cake</Stepper.Step>
        <Stepper.Step>Eat cake</Stepper.Step>
      </Stepper>
  )
}`}
      >
        <Stepper aria-labelledby='stepper-heading' activeStep={activeStep1} onStepChange={setActiveStep1}>
          <Stepper.Step>Make cake</Stepper.Step>
          <Stepper.Step>Eat cake</Stepper.Step>
        </Stepper>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Stepper-orientation' level='2' spacing>
        Orientation
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>orientation</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type StepperOrientation = 'horizontal' | 'vertical'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>StepperOrientation</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Stepper } from '@cegal/ds-components'

const Component = () => {
 const [activeStep, setActiveStep] = useState<number>(1)
  return (
     <Stepper
        activeStep={activeStep}
        onStepChange={setActiveStep}
        orientation="horizontal"
     >
        <Stepper.Step>Choose cake recipe</Stepper.Step>
        <Stepper.Step>Get ingredients</Stepper.Step>
        <Stepper.Step>Bake cake</Stepper.Step>
        <Stepper.Step>Cut cake</Stepper.Step>
        <Stepper.Step>Eat cake</Stepper.Step>
     </Stepper>
  )
}`}
      >
        <Stepper activeStep={activeStep2} onStepChange={setActiveStep2} orientation='horizontal'>
          <Stepper.Step>Choose cake recipe</Stepper.Step>
          <Stepper.Step>Get ingredients</Stepper.Step>
          <Stepper.Step>Bake cake</Stepper.Step>
          <Stepper.Step>Cut cake</Stepper.Step>
          <Stepper.Step>Eat cake</Stepper.Step>
        </Stepper>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Stepper-disable' level='2' spacing>
        Non-interactible
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can set <code>interactive</code> to false, so the stepper is not interactable.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Stepper } from '@cegal/ds-components'

const Component = () => {
  return (
    <Stepper
        activeStep={2}
        interactive={false}
     >
      <Stepper.Step>Choose cake recipe</Stepper.Step>
          <Stepper.Step>Get ingredients</Stepper.Step>
          <Stepper.Step>Bake cake</Stepper.Step>
          <Stepper.Step>Cut cake</Stepper.Step>
          <Stepper.Step>Eat cake</Stepper.Step>
     </Stepper>
  )
}`}
      >
        <Stepper activeStep={2} interactive={false}>
          <Stepper.Step>Choose cake recipe</Stepper.Step>
          <Stepper.Step>Get ingredients</Stepper.Step>
          <Stepper.Step>Bake cake</Stepper.Step>
          <Stepper.Step>Cut cake</Stepper.Step>
          <Stepper.Step>Eat cake</Stepper.Step>
        </Stepper>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Stepper-hide-label' level='2' spacing>
        Completed
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can set <code>complete</code> props to the <code>Step</code> elements, to mark them as completed.
        You can also set the <code>interactive</code> props to the <code>Step</code> elements, to mark the
        inaccessible steps, if you want to prevent the user to jump steps.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Stepper } from '@cegal/ds-components'

const Component = () => {
  return (
    <Stepper
      activeStep={3}
    >
      <Stepper.Step completed>
        Choose cake recipe
      </Stepper.Step>
      <Stepper.Step completed>
        Get ingredientst
      </Stepper.Step>
      <Stepper.Step>
        Bake cake
      </Stepper.Step>
      <Stepper.Step interactive={false}>
        Cut cake
      </Stepper.Step>
      <Stepper.Step interactive={false}>
        Eat cake
      </Stepper.Step>
    </Stepper>
  )
}`}
      >
        <Stepper activeStep={3}>
          <Stepper.Step completed>Choose cake recipe</Stepper.Step>
          <Stepper.Step completed>Get ingredientst</Stepper.Step>
          <Stepper.Step>Bake cake</Stepper.Step>
          <Stepper.Step interactive={false}>Cut cake</Stepper.Step>
          <Stepper.Step interactive={false}>Eat cake</Stepper.Step>
        </Stepper>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='Stepper-props' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Stepper-Stepper-props' level='3' spacing>
        Stepper table
      </Heading>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>activeStep</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Sets Stepper value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>interactive</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets if stepper is interactive</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onStepChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(newStep: number) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback function when a step is clicked</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>orientation</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'horizontal' | 'vertical'</code>
              </Table.DataCell>
              <Table.DataCell>Set Stepper orientation</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>vertical</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Stepper-Step-props' level='3' spacing>
        Stepper.Step table
      </Heading>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>completed</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets Step as completed</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets Step as with an error</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>interactive</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets Step as interactive</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsStepper
