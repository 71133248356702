import { Heading, BodyLong, Content, VerticalSpace } from '@cegal/ds-components'
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import changesMd from 'CHANGES.md'

const Changes = () => {
  const [changes, setChanges] = useState<string | undefined>(undefined)
  fetch(changesMd)
    .then((response) => response.text())
    .then((text) => setChanges(text))

  return (
    <Content>
      <VerticalSpace />

      <Heading size='large'>News / Changes</Heading>

      <VerticalSpace size='2' />

      <ReactMarkdown
        children={changes ?? ''} // eslint-disable-line
        components={{
          p({ node, className, children, ...props }) {
            return (
              <BodyLong spacing {...props} className={className}>
                {children}
              </BodyLong>
            )
          }
        }}
        remarkPlugins={[remarkGfm]}
      />
    </Content>
  )
}

export default Changes
