import {
  BodyLong,
  Radio,
  RadioGroup,
  FlexEndDiv,
  Heading,
  Link,
  HorizontalSpace,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsRadiogroup = () => {
  const [shoppingList1, setShoppingList1] = useState<string>('Milk')
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='Radiogroup' level='1' spacing>
        Radio group
      </Heading>

      <VerticalSpace size='2' />
      <BodyLong spacing>
        The <code>RadioGroup</code> component groups and handles a list of radio elements.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Radio-default' level='2' spacing>
        Default component
      </Heading>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Radio, RadioGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <RadioGroup legend='Shopping list'>
      <Radio value='eggs'>Eggs</Radio>
      <Radio value='milk'>Milk</Radio>
      <Radio value='coffee'>Coffee</Radio>
    </RadioGroup>
  )
}`}
      >
        <RadioGroup legend='Shopping list'>
          <Radio value='eggs'>Eggs</Radio>
          <Radio value='milk'>Milk</Radio>
          <Radio value='coffee'>Coffee</Radio>
        </RadioGroup>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkboc-controlled' level='2' spacing>
        Controlling
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You set the <code>onChange</code> and <code>value</code> props to control a radio group. In the
        example below, we also set the <code>defaultValue</code> prop to pass an initial value.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Radio, RadioGroup } from '@cegal/ds-components'

const Component = () => { 
  const [
    shoppingList,
    setShoppingList
  ] = useState<string | undefined>(undefined)
  return (
    <>
       <RadioGroup 
         legend='Shopping list' 
         value={shoppingList} 
         defaultValue='Milk'
         onChange={setShoppingList}
       >
          <Radio value='Eggs'>Eggs</Radio>
          <Radio value='Milk'>Milk</Radio>
          <Radio value='Coffee'>Coffee</Radio>
        </RadioGroup>
        <BodyLong>
          Chosen element is {shoppingList}
        </BodyLong>
      </>  
  )
}`}
      >
        <RadioGroup
          legend='Shopping list'
          value={shoppingList1}
          defaultValue='Milk'
          onChange={setShoppingList1}
        >
          <Radio value='Eggs'>Eggs</Radio>
          <Radio value='Milk'>Milk</Radio>
          <Radio value='Coffee'>Coffee</Radio>
        </RadioGroup>
        <BodyLong>Chosen element is {shoppingList1}</BodyLong>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Radio-size' level='2' spacing>
        Size
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>The Radio group size prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <BodyLong spacing>
        You can set/override the Radio sizes individually, as shown with the <code>Milk</code> radio label
        below.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Radio, RadioGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexEndDiv>
      <RadioGroup 
        size='medium'
        legend='Medium size list'
      >
          <Radio value='Eggs'>Eggs</Radio>
          <Radio value='Milk' size='small'>Milk</Radio>
          <Radio value='Coffee'>Coffee</Radio>
        </RadioGroup>
        <HorizontalSpace size='2'/>
         <RadioGroup 
        size='small'
        legend='Small size list'
      >
          <Radio value='Eggs'>Eggs</Radio>
          <Radio value='Milk' size='medium'>Milk</Radio>
          <Radio value='Coffee'>Coffee</Radio>
        </RadioGroup>
       </FlexEndDiv>
  )
}`}
      >
        <FlexEndDiv>
          <RadioGroup size='medium' legend='Medium size list'>
            <Radio value='Eggs'>Eggs</Radio>
            <Radio value='Milk' size='small'>
              Milk
            </Radio>
            <Radio value='Coffee'>Coffee</Radio>
          </RadioGroup>
          <HorizontalSpace size='2' />
          <RadioGroup size='small' legend='Small size list'>
            <Radio value='Eggs'>Eggs</Radio>
            <Radio value='Milk' size='medium'>
              Milk
            </Radio>
            <Radio value='Coffee'>Coffee</Radio>
          </RadioGroup>
        </FlexEndDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Radio-disable' level='2' spacing>
        Disable
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>disabled</code> prop to deactivate the radio group, or individual radio buttons.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Radio, RadioGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexEndDiv>
      <RadioGroup 
        disabled
        legend='Shopping list'
      >
        <Radio value='Eggs'>Eggs</Radio>
        <Radio value='Milk'>Milk</Radio>
        <Radio value='Coffee'>Coffee</Radio>
      </RadioGroup>
      <HorizontalSpace size='2'/>
      <RadioGroup 
        legend='Shopping list'
      >
        <Radio value='Eggs'>Eggs</Radio>
        <Radio value='Milk' disabled>No more milk</Radio>
        <Radio value='Coffee'>Coffee</Radio>
      </RadioGroup>
    </FlexEndDiv>
  )
}`}
      >
        <FlexEndDiv>
          <RadioGroup disabled legend='Shopping list'>
            <Radio value='Eggs'>Eggs</Radio>
            <Radio value='Milk'>Milk</Radio>
            <Radio value='Coffee'>Coffee</Radio>
          </RadioGroup>
          <HorizontalSpace size='2' />
          <RadioGroup legend='Shopping list'>
            <Radio value='Eggs'>Eggs</Radio>
            <Radio value='Milk' disabled>
              No more milk
            </Radio>
            <Radio value='Coffee'>Coffee</Radio>
          </RadioGroup>
        </FlexEndDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Radio-error' level='2' spacing>
        Error
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>error</code> prop to display the radio group in an error state.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Radio, RadioGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <RadioGroup 
      error='You have to select one item'
      legend='Shopping list'
    >
      <Radio value='Eggs'>Eggs</Radio>
      <Radio value='Milk'>Milk</Radio>
      <Radio value='Coffee'>Coffee</Radio>
    </RadioGroup>
  )
}`}
      >
        <RadioGroup error='You have to select one item' legend='Shopping list'>
          <Radio value='Eggs'>Eggs</Radio>
          <Radio value='Milk'>Milk</Radio>
          <Radio value='Coffee'>Coffee</Radio>
        </RadioGroup>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Radio-hide-label' level='2' spacing>
        Hiding the legend
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>hideLegend</code> prop to hide visually the radio group legend. The label will
        still be accessible to screen readers.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Radio, RadioGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <RadioGroup 
      hideLegend
      legend='Shopping list'
    >
      <Radio value='Eggs'>Eggs</Radio>
      <Radio value='Milk'>Milk</Radio>
      <Radio value='Coffee'>Coffee</Radio>
    </RadioGroup>
  )
}`}
      >
        <RadioGroup hideLegend legend='Shopping list'>
          <Radio value='Eggs'>Eggs</Radio>
          <Radio value='Milk'>Milk</Radio>
          <Radio value='Coffee'>Coffee</Radio>
        </RadioGroup>
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Radio-description' level='2' spacing>
        Description
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>description</code> prop to give a better contextual description of what the
        radio group is about.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Radio, RadioGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <RadioGroup 
      description='Buy it before 20:00'
      legend='Shopping list'
    >
     <Radio 
       value='Eggs' 
       description='At least 12'
     >
       Eggs
     </Radio>
     <Radio 
       value='Milk'
       description='Only fat milk'
     >
       Milk
     </Radio>
     <Radio 
       value='Coffee' 
       description='Grinded, no beans'
     >
       Coffee
     </Radio>
    </RadioGroup>
  )
}`}
      >
        <RadioGroup description='Buy it before 20:00' legend='Shopping list'>
          <Radio value='Eggs' description='At least 12'>
            Eggs
          </Radio>
          <Radio value='Milk' description='Only fat milk'>
            Milk
          </Radio>
          <Radio value='Coffee' description='Grinded, no beans'>
            Coffee
          </Radio>
        </RadioGroup>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />
      <BodyLong>
        The <code>RadioGroupProps</code> extends <code>React.HTMLAttributes&lt;HTMLFieldSetElement&gt;</code>,
        as the checkbox element uses a <code>fieldset</code> tag. As such, you can pass <code>input</code>{' '}
        props from{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLFieldSetElement'>
          HTMLFieldSetElement
        </Link>{' '}
        interface,
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props
        such as <code>style</code>.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Radiogroup-props' level='3' spacing>
        RadioGroup props
      </Heading>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>defaultValue</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>any</code>
              </Table.DataCell>
              <Table.DataCell>Set radio group's initial value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>description</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Set radio group description</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Disables the radio group</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets error message</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLegend</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides Radio group legend</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>name</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets internal radio group name</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(value: any[]) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback function for radio group changes</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>required</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Set radio group as required</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets radio group size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets radio group value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Radio-props' level='3' spacing>
        Radio props
      </Heading>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>description</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Set radio description</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Disables the radio</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets radio size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets radio value</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsRadiogroup
