import {
  BodyLong,
  Column,
  Fieldset,
  FlexStartDiv,
  Heading,
  HorizontalSpace,
  Row,
  Link,
  Select,
  Table,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'

import React, { useState } from 'react'

const ComponentsFieldset = () => {
  const [size, setSize] = useState<string>('small')
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='fieldset' level='1' spacing>
        Fieldset
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>Fieldset</code> component is useful to gather form fields into a single logic group, passing
        some props to the children elements, suzh as <code>size</code> or a global <code>error</code> message
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='fieldset-size' level='2' spacing>
        Size
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can set a <code>error</code> prop to the <code>Fieldset</code> to show a global error that
        concerns all the children form elements
      </BodyLong>

      <BodyLong spacing>The size prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Fleidset, TextField } from '@cegal/ds-components'

const Component = () => {
  const [size, setSize] = useState<string>('small')
  return (
    <FlexStartDiv>
      <Select 
        label='Set size'
        value={size} 
        onChange={(e: any) => setSize(e.target.value)}
      >
        <option>medium</option>
        <option>small</option>
      </Select>
      <HorizontalSpace/>
      <Fieldset legend='Address' size={size}>
        <Row>
          <Column flex='2'>
          <TextField label='Street name'/>
          </Column>
          <Column>
            <TextField label='Street number'/>
          </Column>
        </Row>
        <Row>
          <Column>
            <TextField label='Postal code'/>
          </Column>
          <Column>
            <TextField label='City'/>
          </Column>
          <Column>
            <TextField label='Country'/>
          </Column>
        </Row>
      </Fieldset>
    </FlexStartDiv>
  )
}`}
      >
        <FlexStartDiv>
          <Select label='Set size' value={size} onChange={(e: any) => setSize(e.target.value)}>
            <option>medium</option>
            <option>small</option>
          </Select>
          <HorizontalSpace />
          <Fieldset legend='Address' size={size as any}>
            <Row>
              <Column flex='2'>
                <TextField label='Street name' />
              </Column>
              <Column>
                <TextField label='Street number' />
              </Column>
            </Row>
            <Row>
              <Column>
                <TextField label='Postal code' />
              </Column>
              <Column>
                <TextField label='City' />
              </Column>
              <Column>
                <TextField label='Country' />
              </Column>
            </Row>
          </Fieldset>
        </FlexStartDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='fieldset-error' level='2' spacing>
        Error message
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can set a <code>error</code> prop to the <code>Fieldset</code> to show a global error that
        concerns all the children form elements
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Fieldset, TextField } from '@cegal/ds-components'

const Component = () => {
  return (
    <Fieldset 
      legend='Address' 
      error='Fill all address fields'
    >
      <Row>
        <Column flex='2'>
        <TextField label='Street name'/>
        </Column>
        <Column>
          <TextField label='Street number'/>
        </Column>
      </Row>
      <Row>
        <Column>
          <TextField label='Postal code'/>
        </Column>
        <Column>
          <TextField label='City'/>
        </Column>
        <Column>
          <TextField label='Country'/>
        </Column>
      </Row>
    </Fieldset>
  )
}`}
      >
        <Fieldset legend='Address' error='Fill all address fields'>
          <Row>
            <Column flex='2'>
              <TextField label='Street name' />
            </Column>
            <Column>
              <TextField label='Street number' />
            </Column>
          </Row>
          <Row>
            <Column>
              <TextField label='Postal code' />
            </Column>
            <Column>
              <TextField label='City' />
            </Column>
            <Column>
              <TextField label='Country' />
            </Column>
          </Row>
        </Fieldset>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='fieldset-errorpropagation' level='2' spacing>
        Error propagation
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can set the error message and refrain from propagating the error style by setting the{' '}
        <code>errorPropagation</code> property to <code>false</code>, so you can individually set error
        messages on each form element
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Fieldset, TextField } from '@cegal/ds-components'

const Component = () => {
  return (
    <Fieldset 
      legend='Address' 
      error='Fill all address fields' 
      errorPropagation={false}
    >
      <Row>
        <Column flex='2'>
        <TextField 
          error='Street name cannot be empty' 
          label='Street name'
        />
        </Column>
        <Column>
          <TextField label='Street number'/>
        </Column>
      </Row>
      <Row>
        <Column>
          <TextField label='Postal code'/>
        </Column>
        <Column>
          <TextField label='City'/>
        </Column>
        <Column>
          <TextField 
            error='Country cannot be empty' 
            label='Country'
          />
        </Column>
      </Row>
    </Fieldset>
  )
}`}
      >
        <Fieldset legend='Address' error='Fill all address fields' errorPropagation={false}>
          <Row>
            <Column flex='2'>
              <TextField error='Street name cannot be empty' label='Street name' />
            </Column>
            <Column>
              <TextField label='Street number' />
            </Column>
          </Row>
          <Row>
            <Column>
              <TextField label='Postal code' />
            </Column>
            <Column>
              <TextField label='City' />
            </Column>
            <Column>
              <TextField error='Country cannot be empty' label='Country' />
            </Column>
          </Row>
        </Fieldset>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>FieldsetProps</code> extends <code>React.HTMLAttributes&lt;HTMLFieldSetElement&gt;</code>,
        as the checkbox element uses a <code>fieldset</code> tag. As such, you can pass <code>input</code>{' '}
        props from{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLFieldSetElement'>
          HTMLFieldSetElement
        </Link>{' '}
        interface,{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props
        such as <code>style</code>.
      </BodyLong>
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>disabled</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Disables children form elements</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>false</Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets a fieldset error message</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>errorPropagation</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Allows error message propagation to sub components</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLegend</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides fieldset legend</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>false</Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>legend</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>React.ReactNode</code>
              </Table.DataCell>
              <Table.DataCell>Fieldset legend component</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>-</Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets size of fieldset and children</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsFieldset
