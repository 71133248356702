import { BodyLong, BodyShort, Panel, Slider, Heading, Table, VerticalSpace } from '@cegal/ds-components'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsSlider = () => {
  const [value, setValue] = useState<number>(0)
  const spiceEmoji = '🌶'
  const labels = [
    'No spice',
    '️ Just a hint',
    'A little',
    'I can handle',
    'Bring it on',
    'I will not survive this'
  ]
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='slider' level='1' spacing>
        Slider
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        The <code>Slider</code> component is a <code>input</code> element with <code>type='range'</code>.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='slider-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { Slider } from '@cegal/ds-components'

const Component = () => {
  return (
    <Slider label='Spicy'/>
  )
}`}
      >
        <Slider label='Spicy' />
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='slider-limiting' level='2' spacing>
        Controlling
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can control the element by setting the <code>value</code> and <code>onChange</code> props.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Slider } from '@cegal/ds-components'

const Component = () => {
  const spiceEmoji = '🌶'
  const [value, setValue] = useState<number>(0)
  const labels = [
    'No spice', '️ Just a hint', 
    'A little', 'I can handle',  'Bring it on', 
    'I will not survive this'
  ]
    return (
    <PileDiv>
    <Slider 
      value={value}
      label={'Choose spicyness'}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
       setValue(parseInt(e.target.value))}
      min={0}
      max={5}
    />
    <BodyShort>
      {'You chose ' + spiceEmoji.repeat(value) + ' - ' + labels[value]}
    </BodyShort>
   
    </PileDiv>
  )
}`}
      >
        <Slider
          value={value}
          label='Choose spicyness'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(parseInt(e.target.value))}
          min={0}
          max={5}
        />
        <BodyShort>{'You chose ' + spiceEmoji.repeat(value) + ' - ' + labels[value]}</BodyShort>
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='slider-sidelabels' level='2' spacing>
        Side labels
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>leftLabel</code> and <code>rightLabel</code> props sets side labels
      </BodyLong>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Slider } from '@cegal/ds-components'

const Component = () => {
  return (
    <Slider
      label='Salt'
      description='select amount'
      min={0}
      max={100}
      step={10}
      leftLabel='None'
      rightLabel='Too much'
    />
  )
}`}
      >
        <Slider
          label='Salt'
          description='select amount'
          min={0}
          max={100}
          step={10}
          leftLabel='None'
          rightLabel='Too much'
        />
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='slider-active' level='2' spacing>
        Active
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>active</code> prop sets which side of the slider thumb should be seen as active
      </BodyLong>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Slider } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <Slider active='both' label='active both'/>
      <Slider active='left' label='active left'/>
      <Slider active='right' label='active right'/>
    </>
  )
}`}
      >
        <>
          <Slider active='both' label='active both' />
          <Slider active='left' label='active left' />
          <Slider active='right' label='active right' />
        </>
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='slider-fullWidth' level='2' spacing>
        Full width
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>fullWidth</code> prop sets width at 100%
      </BodyLong>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Slider } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
       <Panel border style={{width:'100%'}}>
            <Slider label='no full width'/>
          </Panel>
          <VerticalSpace/>
          <Panel border style={{width:'100%'}}>
            <Slider fullWidth label='full width'/>
          </Panel>
    </>
  )
}`}
      >
        <>
          <Panel border style={{ width: '100%' }}>
            <Slider label='no full width' />
          </Panel>
          <VerticalSpace />
          <Panel border style={{ width: '100%' }}>
            <Slider fullWidth label='full width' />
          </Panel>
        </>
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace />
      <BodyLong>
        The <code>SliderProps</code> component inherits all the properties from <code>TextField</code> such as{' '}
        <code>hideLabel</code>, <code>error</code> or <code>disabled</code>, native{' '}
        <code>HTMLInputElement</code> interface properties such as <code>value</code>, and{' '}
        <code>UIEvents</code> such as <code>onClick</code>.
      </BodyLong>

      <VerticalSpace size='2' />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>active</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'left' | 'right' | 'both'</code>
              </Table.DataCell>
              <Table.DataCell>Sets active colors on slider track</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>both</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>description</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>React.ReactNode</code>
              </Table.DataCell>
              <Table.DataCell>Sets slider description</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>React.ReactNode</code>
              </Table.DataCell>
              <Table.DataCell>Sets slider error</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>fullWidth</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets slider to span full width</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides slider label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>label</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets slider label</Table.DataCell>
              <Table.DataCell>Yes</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>leftLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>React.ReactNode</code>
              </Table.DataCell>
              <Table.DataCell>Sets slider's left label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>max</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets slider maximum value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>min</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets slider minimum value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>{' '}
            <Table.Row>
              <Table.DataCell>
                <code>rightLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>React.ReactNode</code>
              </Table.DataCell>
              <Table.DataCell>Sets slider's right label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>number</code>
              </Table.DataCell>
              <Table.DataCell>Sets slider current value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>

      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsSlider
