import {
  AlignEndColumn,
  BodyLong,
  Box,
  Button,
  FlexDiv,
  Heading,
  Label,
  Link,
  PileCenterDiv,
  Row,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React from 'react'
import reactElementToJSXString from 'react-element-to-jsx-string'

// { maxHeight: '100px', overflowY: 'scroll' }

const SampleBox = ({ border, background, subbackground, loading, size, variant, bodyStyle }: any) => (
  <Box
    border={border}
    variant={variant}
    background={background}
    loading={loading}
    size={size}
    style={{ maxWidth: '300px' }}
  >
    <Box.Header background={subbackground}>
      <Heading size='xsmall'>Recipe ingredients</Heading>
    </Box.Header>
    <Box.Body style={bodyStyle}>
      <ul>
        <li>Flour</li>
        <li>Milk</li>
        <li>Eggs</li>
        <li>Sugar</li>
        <li>Butter</li>
      </ul>
    </Box.Body>
    <Box.Footer background={subbackground}>
      <Row>
        <AlignEndColumn>
          <Button size='xsmall'>Preparation &gt;</Button>
        </AlignEndColumn>
      </Row>
    </Box.Footer>
  </Box>
)

const ComponentsBox = () => {
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='box' level='1' spacing>
        Box
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        <code>Box</code> is a panel component with optional headers and footers. Boxes are ideal to present
        contained information to the user, with a proper header for the title, and a footer for action buttons
        or secondary info.
      </BodyLong>
      <BodyLong spacing>
        The <code>Box</code> component structure is typically:
      </BodyLong>
      <ul>
        <li>
          <BodyLong>
            <code>Box.Header</code>, for a meaningful title for the box
          </BodyLong>
        </li>
        <li>
          <BodyLong>
            <code>Box.Body</code>, for the content (with an optional scroll)
          </BodyLong>
        </li>
        <li>
          <BodyLong>
            <code>Box.Footer</code>, for action buttons
          </BodyLong>
        </li>
      </ul>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='box-default' level='2' spacing>
        Default component
      </Heading>
      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { Box } from '@cegal/ds-components'

const Component = () => {
  return ${reactElementToJSXString(SampleBox({}), {
    showDefaultProps: false,
    filterProps: ['background', 'border', 'loading', 'style', 'size', 'variant']
  })}
}`}
      >
        <SampleBox />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='box-background' level='2' spacing>
        Background
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can set a subtle transparent background to the box, or to each sub-component
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { Box } from '@cegal/ds-components'

const Component = () => {
  return ${reactElementToJSXString(SampleBox({ background: true }), {
    showDefaultProps: false,
    filterProps: ['border', 'loading', 'style', 'size', 'variant']
  })}
}`}
      >
        <SampleBox background />
      </DemoableDiv>

      <DemoableDiv
        alignContent='center'
        code={`import { Box } from '@cegal/ds-components'

const Component = () => {
  return ${reactElementToJSXString(SampleBox({ subbackground: true }), {
    showDefaultProps: false,
    filterProps: ['border', 'loading', 'style', 'size', 'variant']
  })}
}`}
      >
        <SampleBox subbackground />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='box-border' level='2' spacing>
        Border
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        You can add a border with the <code>border</code> prop.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Box } from '@cegal/ds-components'

const Component = () => {
  return ${reactElementToJSXString(SampleBox({ border: true }), {
    showDefaultProps: false,
    filterProps: ['background', 'loading', 'style', 'size', 'variant']
  })}
}`}
      >
        <SampleBox border />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='box-loading' level='2' spacing>
        Loading
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        You can add a loading spinner to the box body with the <code>loading</code> prop.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { Box } from '@cegal/ds-components'

const Component = () => {
  return ${reactElementToJSXString(SampleBox({ loading: true }), {
    showDefaultProps: false,
    filterProps: ['background', 'border', 'style', 'size', 'variant']
  })}
}`}
      >
        <SampleBox loading />
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='box-sizes' level='2' spacing>
        Sizes
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 2 values:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type BoxSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>BoxSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Box } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexDiv style={{ justifyContent: 'space-evenly', width: '100%' }}>
      <PileCenterDiv>
        <Label>Medium</Label>
    ${reactElementToJSXString(SampleBox({ size: 'medium' }), {
      showDefaultProps: false,
      filterProps: ['background', 'border', 'loading', 'style', 'variant']
    })}
      </PileCenterDiv>
      <PileCenterDiv>
        <Label>Small</Label>
    ${reactElementToJSXString(SampleBox({ size: 'small' }), {
      showDefaultProps: false,
      filterProps: ['background', 'border', 'loading', 'style', 'variant']
    })}
     </PileCenterDiv>
   </FlexDiv>
  )
}`}
      >
        <FlexDiv style={{ justifyContent: 'space-evenly', width: '100%' }}>
          <PileCenterDiv>
            <Label>Medium</Label>
            <SampleBox size='medium' />
          </PileCenterDiv>
          <PileCenterDiv>
            <Label>Small</Label>
            <SampleBox size='small' />
          </PileCenterDiv>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='box-variant' level='2' spacing>
        Variant
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>variant</code> prop can be one of the following 2 values:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type BoxVariant = 'normal' | 'stripe'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>BoxVariant</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Box } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexDiv style={{ justifyContent: 'space-evenly', width: '100%' }}>
      <PileCenterDiv>
        <Label>Medium</Label>
    ${reactElementToJSXString(SampleBox({ variant: 'normal' }), {
      showDefaultProps: false,
      filterProps: ['background', 'border', 'loading', 'style', 'size']
    })}
      </PileCenterDiv>
      <PileCenterDiv>
        <Label>Small</Label>
    ${reactElementToJSXString(SampleBox({ variant: 'stripe' }), {
      showDefaultProps: false,
      filterProps: ['background', 'border', 'loading', 'style', 'size']
    })}
     </PileCenterDiv>
   </FlexDiv>
  )
}`}
      >
        <FlexDiv style={{ justifyContent: 'space-evenly', width: '100%' }}>
          <PileCenterDiv>
            <Label>Normal</Label>
            <SampleBox variant='normal' />
          </PileCenterDiv>
          <PileCenterDiv>
            <Label>Stripe</Label>
            <SampleBox variant='stripe' />
          </PileCenterDiv>
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props tables
      </Heading>
      <VerticalSpace />
      <Heading className='toc' size='medium' id='properties-box' level='3' spacing>
        Box prop table
      </Heading>
      <VerticalSpace />

      <BodyLong spacing>
        The <code>BoxProps</code> interface extends <code>React.HTMLAttributes&lt;HTMLDivElement&gt;</code>,
        as the top level component is a <code>Panel</code> component as a <code>div</code>. As such, you can
        pass{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLDivElement'>HTMLDivElement</Link>{' '}
        props, as well as <code>PanelProps</code> properties.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>background</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds a transparent box background</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>border</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds a box border</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>flex</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets box body with flex display</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>true</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>loading</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds a loading overlay</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets box size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>spacing</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds a margin bottom</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>variant</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'normal' | 'stripe'</code>
              </Table.DataCell>
              <Table.DataCell>Sets box variant</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>normal</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='properties-other' level='3'>
        Box.Header, Box.Body, Box.Footer prop table
      </Heading>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>background</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds a transparent box background</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsBox
