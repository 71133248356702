import {
  Heading,
  FlexDiv,
  HorizontalSpace,
  TextField,
  VerticalSpace,
  Row,
  Column,
  Table,
  BodyLong
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsTextField = () => {
  const [value, setValue] = useState<string>('')
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='textfield' level='1' spacing>
        Text field
      </Heading>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='textfield-default' level='2' spacing>
        Default
      </Heading>
      <VerticalSpace size='2' />
      <DemoableDiv
        alignContent='center'
        code={`import { TextField } from '@cegal/ds-components'

const Component = () => {
  return (
   <TextField label='Feedback'/>
  )
}`}
      >
        <TextField label='Feedback' />
      </DemoableDiv>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='textfield-controlled' level='2' spacing>
        Controlled
      </Heading>
      <VerticalSpace size='2' />
      <DemoableDiv
        alignContent='center'
        code={`import { TextField } from '@cegal/ds-components'

const Component = () => {
  const [value, setValue] = useState<string>('')
  return (
  <TextField
    label={'Feedback (' + value.length + 
    ' character' + (value.length === 1 ? '' : 's') + ')'}
    value={value}
    onChange={(e: any) => setValue(e.target.value)}
  />
  )
}`}
      >
        <TextField
          label={'Feedback (' + value.length + ' character' + (value.length === 1 ? '' : 's') + ')'}
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
        />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='textfield-controlled' level='2' spacing>
        Type
      </Heading>
      <VerticalSpace size='2' />
      <DemoableDiv
        alignContent='center'
        code={`import { TextField } from '@cegal/ds-components'

const Component = () => {
  return (
    <Row>
      <Column>
        <TextField type='date' label='Date'/>
        <TextField type='email' label='Email'/>
        <TextField type='number' label='Number'/>
        <TextField type='password' label='Password'/>
      </Column>
      <Column>
        <TextField type='tel' label='Telephone'/>
        <TextField type='text' label='Text'/>
        <TextField type='url' label='URL'/>
      </Column>
    </Row> 
 )
}`}
      >
        <Row>
          <Column>
            <TextField type='date' label='Date' />
            <TextField type='email' label='Email' />
            <TextField type='number' label='Number' />
            <TextField type='password' label='Password' />
          </Column>
          <Column>
            <TextField type='tel' label='Telephone' />
            <TextField type='text' label='Text' />
            <TextField type='url' label='URL' />
          </Column>
        </Row>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-size' level='2' spacing>
        Size
      </Heading>

      <VerticalSpace size='2' />

      <BodyLong spacing>The select size prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <FlexDiv>
       <TextField label={'Medium'} size='medium'/>
       <HorizontalSpace/>
       <TextField label={'Small'} size='small'/>
    </FlexDiv>
  )
}`}
      >
        <FlexDiv>
          <TextField label='Medium' size='medium' />
          <HorizontalSpace />
          <TextField label='Small' size='small' />
        </FlexDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-disable' level='2' spacing>
        Disable
      </Heading>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <TextField label='Disabled' disabled/>
  )
}`}
      >
        <TextField label='Disabled' disabled />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-error' level='2' spacing>
        Error
      </Heading>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <TextField label='Error' error='You need to write something'/>
  )
}`}
      >
        <TextField label='Error' error='You need to write something' />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-hide-label' level='2' spacing>
        Hide label
      </Heading>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
     <TextField label='Hidden label' hideLabel/>
  )
}`}
      >
        <TextField label='Hidden label' hideLabel />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='select-hide-label' level='2' spacing>
        Description
      </Heading>

      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Select } from '@cegal/ds-components'

const Component = () => {
  return (
    <TextField label='Feedback' description='Your feedback is always welcome'/>
  )
}`}
      >
        <TextField label='Feedback' description='Your feedback is always welcome' />
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace size='2' />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets TextField error message</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides TextField label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Disables the TextField</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets TextField size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets TextField value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>description</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Set TextField description</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>

            <Table.Row>
              <Table.DataCell>
                <code>type</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'date' | 'email' | 'number' | 'password' | 'tel' | 'text' | 'url'</code>
              </Table.DataCell>
              <Table.DataCell>Set TextField type, for better text input</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>text</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsTextField
