import {
  Alert,
  BodyLong,
  Button,
  Content,
  Datepicker,
  ErrorSummary,
  Fieldset,
  Heading,
  Link,
  PileCenterDiv,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import _ from 'lodash'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Tips = () => {
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [date, setDate] = useState('')
  const [status, setStatus] = useState('')
  const [validation, setValidation] = useState<any>({})
  const validate = ({ name, date }: any) => {
    const newValidation: any = {}
    if (_.isEmpty(name)) {
      newValidation.name = 'You must fill a name'
    }
    if (_.isEmpty(date)) {
      newValidation.date = 'You must pick a date'
    }
    setValidation(newValidation)
    return _.isEmpty(newValidation)
  }
  const resetValidation = (key: string) => {
    if (validation[key]) {
      validate({ name, date })
    }
  }
  const onSubmit = () => {
    const valid = validate({ name, date })
    setStatus(valid ? 'ok' : 'error')
  }

  return (
    <Content>
      <VerticalSpace />
      <Heading size='large' spacing>
        Tips
      </Heading>

      <VerticalSpace size='2' />

      <BodyLong spacing>
        We will list here tips and recommendations to help you with the design system and your project.
      </BodyLong>

      <Heading className='toc' size='medium' id='form-validation' level='2'>
        Form validation
      </Heading>

      <VerticalSpace size='2' />

      <BodyLong spacing>
        Make sure that the validation is performed when a button is clicked for the purpose of posting data.
        Don't validate immediately when the user blurs the form element, it is unnecessary. For instance, if
        the user misspells an email address, it is annoying to get a validation error immediately.
      </BodyLong>

      <BodyLong spacing>
        By validating when the form is submitted, the user can collect all errors at once and fix them in one
        go, instead of doing it while filling the form. Additionally, in more complex validations where the
        status of a text field depends on the content of another text field, such as start date has to be
        before end date, it is cumbersome to do these validations before having the form fully filled.
      </BodyLong>

      <BodyLong spacing>
        Use a Error Summary component to show a list of errors that need to be corrected by the user, next to
        the button. This ensures the user gets the message of what it needs to be done to fix the form,
        instead of expecting the user to see that nothing happened after submitting the form, and finding the
        errors on form elements that are likely to be hidden because of page scrolling.
      </BodyLong>

      <PileCenterDiv style={{ width: '100%' }}>
        <div style={{ maxWidth: '400px' }}>
          <Fieldset id='fieldset' legend='Leave form fields empty to see validation errors'>
            <TextField
              label='Name *'
              id='name'
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              error={validation?.name}
              onBlur={() => resetValidation('name')}
            />
            <Datepicker
              label='Birthdate *'
              id='date'
              value={date}
              onChange={(e: any) => setDate(e.target.value)}
              error={validation?.date}
              onBlur={() => resetValidation('date')}
            />
          </Fieldset>
          <VerticalSpace />
          <Button onClick={onSubmit}>Form submit</Button>
          <VerticalSpace />
          {!_.isEmpty(validation) && (
            <ErrorSummary heading='You need to fix the form:'>
              {Object.keys(validation).map((key) => (
                <ErrorSummary.Item key={key} onClick={() => document.getElementById(key)?.focus()}>
                  {validation[key]}
                </ErrorSummary.Item>
              ))}
            </ErrorSummary>
          )}
          {status === 'ok' && <Alert variant='success'>Form submitted</Alert>}
        </div>
      </PileCenterDiv>

      <VerticalSpace size='2' />
      <BodyLong spacing>
        Make sure the form fields also have a proper error message on how to properly fix the form values.
      </BodyLong>

      <BodyLong>
        Do label the mandatory form fields accordingly. Preferably, move the mandatory fields to the top, as
        they will be perceived as more important if they show up first.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='theme' level='2' spacing>
        Design system themes
      </Heading>

      <VerticalSpace size='2' />

      <BodyLong spacing>
        The design system contains two themes, <strong>Cegal</strong> and <strong>Neutral</strong>, which have
        light and dark modes. The default theme is the <strong>Cegal light</strong> theme.
      </BodyLong>

      <BodyLong spacing>
        To make a component/page render in a certain theme, just add{' '}
        <code>
          data-theme={'{'}theme{'}'}
        </code>{' '}
        to the component's DOM element, or one of the parent's element.
      </BodyLong>

      <BodyLong spacing>The available themes are:</BodyLong>

      <ul>
        <li>cegal-light (default)</li>
        <li>cegal-dark</li>
        <li>neutral-light</li>
        <li>neutral-dark</li>
      </ul>

      <VerticalSpace />
      <BodyLong spacing>
        More information about themes can be found on the{' '}
        <Link href='#' onClick={() => navigate('/resources/colours')}>
          Resources &gt; Colors/Themes page
        </Link>
        .
      </BodyLong>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='i18n' level='2' spacing>
        Use i18n to hold text content
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        The i18next dependency is very handy to support different languages in a website. Nonetheless, if you
        are planning to develop a site with only one language as a target, it is still very useful and highly
        recommended to import it.
      </BodyLong>

      <BodyLong spacing>
        This way, you can make sure that all text content is stored in JSON files, which can be easily
        namespaced for label roles (messages, validation, form, etc). Also, they can be easily reviewed and
        even changed by collaborators who do not have experience with code.
      </BodyLong>

      <BodyLong spacing>It is tempting to assign labels a kebabcase version of the text, as in:</BodyLong>

      <CodeDiv spacing expand={false}>
        'are-you-sure': 'Are you sure?'
      </CodeDiv>

      <BodyLong spacing>
        but a better approach is to name the label as the role, which give a better context of what the label
        is doing, as in:
      </BodyLong>

      <CodeDiv spacing expand={false}>
        'form-unsaved-prompt-message': 'Are you sure?'
      </CodeDiv>

      <BodyLong spacing>
        This namespaced key makes this label easier to search, and arguably easier to improve since its role
        is clearer to the developer.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='redux' level='2' spacing>
        Implementing a redux store
      </Heading>

      <VerticalSpace size='2' />

      <BodyLong spacing>
        For web applications that can expect to grow and scale a lot in its lifetime, it is often advisable to
        have a store manager, like Redux or React's own Context, to manage the applicaiton's state.
      </BodyLong>

      <BodyLong spacing>
        The <Link href='https://redux-toolkit.js.org/'>Redux toolkit</Link> is a great dependency and
        recommended for store management. Here below is a good example on how to initialize a store in
        Typescript in a way that the dispatch and selector functions can infer state's paramenter types.
      </BodyLong>

      <BodyLong spacing>
        Save this code into <code>store.ts</code>:
      </BodyLong>

      <CodeDiv spacing expand={false}>
        {`
  import { combineReducers, configureStore } from '@reduxjs/toolkit'
  import * as reducers from './reducers'
  import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

  // Infer the 'RootState' and 'AppDispatch' types from the store itself
  export type RootState = ReturnType<typeof store.getState>
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  export type AppDispatch = typeof store.dispatch

  // Use throughout your app instead of plain 'useDispatch' and 'useSelector'
  export const useAppDispatch = () => useDispatch<AppDispatch>()
  export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

    const store = configureStore({
      reducer: combineReducers(reducers),
      devTools: true
    })

    export default store
`}
      </CodeDiv>

      <BodyLong spacing>
        On the <code>index.ts</code> file, do import:
      </BodyLong>
      <CodeDiv spacing expand={false}>
        {`
              import store from './store'
              import { Provider } from 'react-redux'
              
              (...)
              <Provider store={store}>
                <YourApp/>
              </Provider>
            `}
      </CodeDiv>
      <VerticalSpace size='3' />
    </Content>
  )
}

export default Tips
