import { BodyLong, BodyShort, Checkbox, Heading, Link, Table, VerticalSpace } from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsCheckbox = () => {
  const [baked, setBaked] = useState<boolean>(false)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='checkbox' level='1' spacing>
        Checkbox
      </Heading>
      <VerticalSpace />
      <BodyLong spacing>
        Checkboxes are best suited when it is clear that the user is opting in / opting out of a setting with
        a clear default value. <Link href='/components/switch'>Switches</Link> are better suited for settings
        that are not so clear default value, as toggles (for example, switching between light/dark theme).
      </BodyLong>

      <BodyLong spacing>
        Checkboxes can be grouped with a <Link href='/components/checkboxgroup'>CheckboxGroup</Link>{' '}
        component, for more options.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkbox-default' level='2' spacing>
        Default component
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>This how the default component renders without props.</BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox } from '@cegal/ds-components'

const Component = () => {
  return (
    <Checkbox>
      Bake a cake today
    </Checkbox>
  )
}`}
      >
        <Checkbox>Bake a cake today</Checkbox>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkbox-controlled' level='2' spacing>
        Controlled checkbox
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>This how you control checkbox so you can get/set its values. </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox } from '@cegal/ds-components'

const Component = () => {
  const [baked, setBaked]
   = useState<boolean>(false)  
  return (
    <>
      <Checkbox
       checked={baked}
       onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
         setBaked(e.target.checked)}
      >
        Bake a cake today
      </Checkbox>
      <BodyShort>
        You {!baked && 'do not'} have a cake
      </BodyShort>
    </>
  )
}`}
      >
        <Checkbox
          checked={baked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBaked(e.target.checked)}
        >
          Bake a cake today
        </Checkbox>
        <BodyShort>You {!baked && 'do not'} have a cake</BodyShort>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkbox-size' level='2' spacing>
        Size
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <Checkbox size='medium'>
         Medium cake
      </Checkbox>
      <Checkbox size='small'>
         Small cake
      </Checkbox>
    </>
  )
}`}
      >
        <Checkbox size='medium'>Medium cake</Checkbox>
        <Checkbox size='small'>Small cake</Checkbox>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkbox-disable' level='2' spacing>
        Disable
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>disabled</code> prop to deactivate the checkbox.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox } from '@cegal/ds-components'

const Component = () => {
  return (
    <Checkbox disabled>
      You can't check me
    </Checkbox>
  )
}`}
      >
        <Checkbox disabled>You can't check me</Checkbox>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkbox-error' level='2' spacing>
        Error
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>error</code> prop to display the checkbox in an error state.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox } from '@cegal/ds-components'

const Component = () => {
  return (
    <Checkbox error>
      Bake a cake today
    </Checkbox>
  )
}`}
      >
        <Checkbox error>Bake a cake today</Checkbox>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkbox-hide-label' level='2' spacing>
        Hiding the label
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>hideLabel</code> prop to hide visually the checkbox label. The label will still
        be accessible to screen readers.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox } from '@cegal/ds-components'

const Component = () => {
  return (
    <Checkbox hideLabel>
      Bake a cake today
    </Checkbox>
  )
}`}
      >
        <Checkbox hideLabel>Bake a cake today</Checkbox>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkbox-description' level='2' spacing>
        Description
      </Heading>

      <VerticalSpace size='2' />

      <BodyLong spacing>
        You can use the <code>description</code> prop to give a better contextual description of what the
        checkbox is about.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox } from '@cegal/ds-components'

const Component = () => {
  return (
    <Checkbox 
      description='You deserve a sweet treat now'
    >
      Bake a cake today
    </Checkbox>
  )
}`}
      >
        <Checkbox description='You deserve a sweet treat now'>Bake a cake today</Checkbox>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='checkbox-props' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>CheckboxProps</code> extends <code>React.HTMLAttributes&lt;HTMLInputElement&gt;</code>, as
        the checkbox element uses a <code>input</code> tag. As such, you can pass <code>input</code> props
        from{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement'>HTMLInputElement</Link>{' '}
        interface, such as <code>defaultChecked</code>,{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props
        such as <code>style</code>, as well as{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/UIEvent'>UI events</Link> such as{' '}
        <code>onChange</code>. They will all be passed to the <code>&lt;input type="checkbox"&gt;</code>{' '}
        element.
      </BodyLong>

      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>checked</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets checkbox value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>description</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Set checkbox description</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Disables the checkbox</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets error styles</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides checkbox label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>indeterminate</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Specify whether the Checkbox is in an indeterminate state</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets checkbox size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets checkbox value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsCheckbox
