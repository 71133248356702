import {
  BodyLong,
  Button,
  Checkbox,
  CheckboxGroup,
  FlexEndDiv,
  Link,
  Heading,
  HorizontalSpace,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsCheckboxgroup = () => {
  const [shoppingList1, setShoppingList1] = useState<Array<string>>(['Milk'])
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='checkboxgroup' level='1' spacing>
        Checkbox group
      </Heading>

      <VerticalSpace size='2' />
      <BodyLong spacing>
        The <code>CheckboxGroup</code> component lets you handle checkbox values as arrays. This is handy when
        you want to show a single validation error message, for example.
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkboxgroup-default' level='2' spacing>
        Default component
      </Heading>

      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox, CheckboxGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <CheckboxGroup legend='Shopping list'>
        <Checkbox>Eggs</Checkbox>
        <Checkbox>Milk</Checkbox>
        <Checkbox>Coffee</Checkbox>
    </CheckboxGroup>
  )
}`}
      >
        <CheckboxGroup legend='Shopping list'>
          <Checkbox>Eggs</Checkbox>
          <Checkbox>Milk</Checkbox>
          <Checkbox>Coffee</Checkbox>
        </CheckboxGroup>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkboxgroup-controlled' level='2' spacing>
        Controlling the group
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You set the <code>onChange</code> and <code>value</code> props to control a checkbox group. In the
        example below, we also show how to set the initial values.{' '}
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox, CheckboxGroup } from '@cegal/ds-components'

const Component = () => {
  const [
      shoppingList, 
      setShoppingList
  ] = useState<Array<string>>(['Milk'])  
  return (
    <>
       <CheckboxGroup 
         legend='Shopping list' 
         value={shoppingList} 
         onChange={setShoppingList}
       >
          <Checkbox value='Eggs'>Eggs</Checkbox>
          <Checkbox value='Milk'>Milk</Checkbox>
          <Checkbox value='Coffee'>Coffee</Checkbox>
        </CheckboxGroup>
        <BodyLong>
          You need {shoppingList.join(', ')}
        </BodyLong>
        <VerticalSpace/>
        <Button onClick={() => 
          setShoppingList(['Eggs', 'Coffee'])}
        >
          Reset to Eggs + Coffee
        </Button>
    </>
  )
}`}
      >
        <CheckboxGroup legend='Shopping list' value={shoppingList1} onChange={setShoppingList1}>
          <Checkbox value='Eggs'>Eggs</Checkbox>
          <Checkbox value='Milk'>Milk</Checkbox>
          <Checkbox value='Coffee'>Coffee</Checkbox>
        </CheckboxGroup>
        <BodyLong>You need {shoppingList1.join(', ')}</BodyLong>
        <VerticalSpace />
        <Button onClick={() => setShoppingList1(['Eggs', 'Coffee'])}>Reset to Eggs + Coffee</Button>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkboxgroup-size' level='2' spacing>
        Size
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>The checkbox group size prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <BodyLong spacing>You can set/override the checkbox sizes individually</BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox, CheckboxGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexEndDiv>
      <CheckboxGroup 
        size='medium'
        legend='Shopping list'
      >
          <Checkbox value='Eggs'>Eggs</Checkbox>
          <Checkbox value='Milk'>Milk</Checkbox>
          <Checkbox value='Coffee'>Coffee</Checkbox>
        </CheckboxGroup>
        <HorizontalSpace size='2'/>
         <CheckboxGroup 
        size='small'
        legend='Shopping list'
      >
          <Checkbox value='Eggs'>Eggs</Checkbox>
          <Checkbox value='Milk'>Milk</Checkbox>
          <Checkbox value='Coffee'>Coffee</Checkbox>
        </CheckboxGroup>
       </FlexEndDiv>
  )
}`}
      >
        <FlexEndDiv>
          <CheckboxGroup size='medium' legend='Shopping list'>
            <Checkbox value='Eggs'>Eggs</Checkbox>
            <Checkbox value='Milk'>Milk</Checkbox>
            <Checkbox value='Coffee'>Coffee</Checkbox>
          </CheckboxGroup>
          <HorizontalSpace size='2' />
          <CheckboxGroup size='small' legend='Shopping list'>
            <Checkbox value='Eggs'>Eggs</Checkbox>
            <Checkbox value='Milk'>Milk</Checkbox>
            <Checkbox value='Coffee'>Coffee</Checkbox>
          </CheckboxGroup>
        </FlexEndDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkboxgroup-disable' level='2' spacing>
        Disable
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>disabled</code> prop to deactivate the checkbox group, or individual checkbox
        components.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox, CheckboxGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <FlexEndDiv>
      <CheckboxGroup 
        disabled
        legend='Shopping list'
      >
        <Checkbox value='Eggs'>Eggs</Checkbox>
        <Checkbox value='Milk'>Milk</Checkbox>
        <Checkbox value='Coffee'>Coffee</Checkbox>
      </CheckboxGroup>
      <HorizontalSpace size='2'/>
      <CheckboxGroup 
        legend='Shopping list'
      >
        <Checkbox value='Eggs'>Eggs</Checkbox>
        <Checkbox value='Milk' disabled>No more milk</Checkbox>
        <Checkbox value='Coffee'>Coffee</Checkbox>
      </CheckboxGroup>
    </FlexEndDiv>
  )
}`}
      >
        <FlexEndDiv>
          <CheckboxGroup disabled legend='Shopping list'>
            <Checkbox value='Eggs'>Eggs</Checkbox>
            <Checkbox value='Milk'>Milk</Checkbox>
            <Checkbox value='Coffee'>Coffee</Checkbox>
          </CheckboxGroup>
          <HorizontalSpace size='2' />
          <CheckboxGroup legend='Shopping list'>
            <Checkbox value='Eggs'>Eggs</Checkbox>
            <Checkbox value='Milk' disabled>
              No more milk
            </Checkbox>
            <Checkbox value='Coffee'>Coffee</Checkbox>
          </CheckboxGroup>
        </FlexEndDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkbox-error' level='2' spacing>
        Error
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>error</code> prop to display the checkbox group in an error state.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox, CheckboxGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <CheckboxGroup 
      error='You have to check at least one item'
      legend='Shopping list'
    >
      <Checkbox value='Eggs'>Eggs</Checkbox>
      <Checkbox value='Milk'>Milk</Checkbox>
      <Checkbox value='Coffee'>Coffee</Checkbox>
    </CheckboxGroup>
  )
}`}
      >
        <CheckboxGroup error='You have to check at least one item' legend='Shopping list'>
          <Checkbox value='Eggs'>Eggs</Checkbox>
          <Checkbox value='Milk'>Milk</Checkbox>
          <Checkbox value='Coffee'>Coffee</Checkbox>
        </CheckboxGroup>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkbox-hide-label' level='2' spacing>
        Hiding the legend
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>hideLegend</code> prop to hide visually the checkbox group legend. The label
        will still be accessible to screen readers.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox, CheckboxGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <CheckboxGroup 
      hideLegend
      legend='Shopping list'
    >
      <Checkbox value='Eggs'>Eggs</Checkbox>
      <Checkbox value='Milk'>Milk</Checkbox>
      <Checkbox value='Coffee'>Coffee</Checkbox>
    </CheckboxGroup>
  )
}`}
      >
        <CheckboxGroup hideLegend legend='Shopping list'>
          <Checkbox value='Eggs'>Eggs</Checkbox>
          <Checkbox value='Milk'>Milk</Checkbox>
          <Checkbox value='Coffee'>Coffee</Checkbox>
        </CheckboxGroup>
      </DemoableDiv>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='checkbox-description' level='2' spacing>
        Description
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use the <code>description</code> prop to give a better contextual description of what the
        checkbox group is about.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Checkbox, CheckboxGroup } from '@cegal/ds-components'

const Component = () => {
  return (
    <CheckboxGroup 
      description='Buy it before 20:00'
      legend='Shopping list'
    >
      <Checkbox 
        value='Eggs' 
        description='At least 12'
      >
        Eggs
      </Checkbox>
      <Checkbox 
        value='Milk'
        description='Only fat milk'
      >
        Milk
      </Checkbox>
      <Checkbox 
        value='Coffee' 
        description='Grinded, no beans'
      >
        Coffee
      </Checkbox>
    </CheckboxGroup>
  )
}`}
      >
        <CheckboxGroup description='Buy it before 20:00' legend='Shopping list'>
          <Checkbox value='Eggs' description='At least 12'>
            Eggs
          </Checkbox>
          <Checkbox value='Milk' description='Only fat milk'>
            Milk
          </Checkbox>
          <Checkbox value='Coffee' description='Grinded, no beans'>
            Coffee
          </Checkbox>
        </CheckboxGroup>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='checkbox-props' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />
      <BodyLong spacing>
        The <code>CheckboxGroupProps</code> extends{' '}
        <code>React.HTMLAttributes&lt;HTMLFieldSetElement&gt;</code>, as the checkbox element uses a{' '}
        <code>fieldset</code> tag. As such, you can pass <code>input</code> props from{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLFieldSetElement'>
          HTMLFieldSetElement
        </Link>{' '}
        interface, such as <code>defaultChecked</code>,{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props
        such as <code>style</code>.
      </BodyLong>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>description</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Set checkbox group description</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>defaultValue</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>array</code>
              </Table.DataCell>
              <Table.DataCell>Sets checkbox group default values</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Disables the checkbox group</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>error</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets error message</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLegend</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides checkbox group legend</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(value: any[]) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback function for checkbox changes</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets checkbox group size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>array</code>
              </Table.DataCell>
              <Table.DataCell>Sets checkbox group value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsCheckboxgroup
