import {
  Heading,
  PileDiv,
  BodyShort,
  Switch,
  Link,
  VerticalSpace,
  BodyLong,
  Table
} from '@cegal/ds-components'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsSwitch = () => {
  const [spicy, setSpicy] = useState<boolean>(false)
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='Switch' level='1' spacing>
        Switch
      </Heading>

      <VerticalSpace />
      <BodyLong spacing>
        <Link href='/components/switch'>Switches</Link> are better suited for settings that are not so clear
        default value, as toggles (for example, switching between light/dark theme).
      </BodyLong>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Switch-default' level='2' spacing>
        Default component
      </Heading>

      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { Switch } from '@cegal/ds-components'

const Component = () => {
  return (
    <Switch>
      Add spicy sauce
    </Switch>
  )
}`}
      >
        <Switch>Add spicy sauce</Switch>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Switch-controlled' level='2' spacing>
        Controlled
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You set the <code>checked</code> and the <code>onChange</code> props to control the switch element.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Switch } from '@cegal/ds-components'

const Component = () => {
  const [
      spicy, 
      setSpicy
  ] = useState<boolean>(false)  
  return (
   <PileDiv>
    <Switch
     checked={spicy}
     onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
       setSpicy(e.target.checked)}
    >
      Add spicy sauce
    </Switch>
    <BodyShort>
      Your taco will have {spicy ? 'spicy' : 'mild' } sauce
    </BodyShort>
    </PileDiv>
  )
}`}
      >
        <PileDiv>
          <Switch
            checked={spicy}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSpicy(e.target.checked)}
          >
            Add spicy sauce
          </Switch>
          <BodyShort>Your taco will have {spicy ? 'spicy' : 'mild'} sauce</BodyShort>
        </PileDiv>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Switch-size' level='2' spacing>
        Size
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>size</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Switch } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <Switch size='medium'>Medium spice</Switch>
      <Switch size='small'>Small spice</Switch>
    </>
  )
}`}
      >
        <Switch size='medium'>Medium spice</Switch>
        <Switch size='small'>Small spice</Switch>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Switch-position' level='2' spacing>
        Position
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        The <code>position</code> prop can be one of the following 2 strings:
      </BodyLong>

      <CodeDiv expand={false} spacing>
        export type SwitchPosition = 'left' | 'right'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>SwitchPosition</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Switch } from '@cegal/ds-components'

const Component = () => {
  return (
    <>
      <Switch position='left'>Add spicy sauce</Switch>
      <Switch position='right'>Add spicy sauce</Switch>
    </>
  )
}`}
      >
        <Switch position='left'>Add spicy sauce</Switch>
        <Switch position='right'>Add spicy sauce</Switch>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Switch-disable' level='2' spacing>
        Disable
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use <code>disable</code> prop to deactivate the switch.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Switch } from '@cegal/ds-components'

const Component = () => {
  return (
    <Switch disabled>No spicy sauce for you</Switch>
  )
}`}
      >
        <Switch disabled>No spicy sauce for you</Switch>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Switch-hide-label' level='2' spacing>
        Hide label
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use <code>hideLabel</code> prop to hide the switch label.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Switch } from '@cegal/ds-components'

const Component = () => {
  return (
    <Switch hideLabel>Hidden</Switch>
  )
}`}
      >
        <Switch hideLabel>Hidden</Switch>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Switch-loading' level='2' spacing>
        Error
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use <code>error</code> prop to show an error.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Switch } from '@cegal/ds-components'

const Component = () => {
  return (
    <Switch error>No sauce found</Switch>
  )
}`}
      >
        <Switch error>No sauce found</Switch>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Switch-loading' level='2' spacing>
        Loading
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You can use <code>loading</code> prop to show a loading animation.
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Switch } from '@cegal/ds-components'

const Component = () => {
  return (
    <Switch loading>Adding sauce...</Switch>
  )
}`}
      >
        <Switch loading>Adding sauce...</Switch>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Switch-description' level='2' spacing>
        Description
      </Heading>

      <VerticalSpace />

      <DemoableDiv
        alignContent='center'
        code={`import { Switch } from '@cegal/ds-components'

const Component = () => {
  return (
    <Switch description='Your taco will be tastier'>
      Add spicy sauce
    </Switch>
  )
}`}
      >
        <Switch description='Your taco will be tastier'>Add spicy sauce</Switch>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='Switch-props' level='2' spacing>
        Props table
      </Heading>

      <VerticalSpace />

      <BodyLong>
        You can still pass <code>input</code> props from{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement'>HTMLInputElement</Link>{' '}
        interface, such as <code>defaultValue</code>,{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement'>HTMLElement</Link> props
        such as <code>style</code>, as well as{' '}
        <Link href='https://developer.mozilla.org/en-US/docs/Web/API/UIEvent'>UI events</Link> such as{' '}
        <code>onChange</code>. They will all be passed to the <code>&lt;input type="Switch"&gt;</code>{' '}
        element.
      </BodyLong>
      <VerticalSpace />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>description</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Set Switch description</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>disable</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Disables the Switch</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>hideLabel</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Hides Switch label</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>loading</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Set loading state</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>position</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'left' | 'right'</code>
              </Table.DataCell>
              <Table.DataCell>Set switch position</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>left</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets Switch size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Sets Switch value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsSwitch
