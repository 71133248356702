import {
  Alert,
  BodyLong,
  Container,
  FlexCenterDiv,
  Heading,
  HorizontalSpace,
  Link,
  Panel,
  Table,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import tokens from '@cegal/ds-tokens/dist/tokens.json'
import CopyClipboardButton from 'components/CopyClipboardButton/CopyClipboardButton'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const MyDataCell = ({ children }: any) => {
  const [hover, setHover] = useState<boolean>(false)
  return (
    <Table.DataCell onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <FlexCenterDiv>
        {children}
        <div style={{ marginLeft: '0.5rem', visibility: !hover ? 'hidden' : 'visible' }}>
          <CopyClipboardButton label='Copy' size='small' iconPosition='right' text={children}>
            Copy
          </CopyClipboardButton>
        </div>
      </FlexCenterDiv>
    </Table.DataCell>
  )
}

const ResourcesColours = () => {
  const renderTable = (tokens: any, needle: string, showPreview: boolean = true) => {
    return (
      <TableWrapperDiv>
        <Table size='small'>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>CSS variable</Table.ColumnHeader>
              <Table.ColumnHeader>CSS value</Table.ColumnHeader>
              {showPreview && <Table.ColumnHeader>Preview</Table.ColumnHeader>}
            </Table.Row>
          </Table.Header>
          <Table.Body>{renderTableBody(tokens, needle, showPreview)}</Table.Body>
        </Table>
      </TableWrapperDiv>
    )
  }

  const renderTableBody = (tokens: any, needle: string, showPreview: boolean) => {
    return Object.keys(tokens)
      .filter((tokenKey) => tokenKey.startsWith(needle))
      .map((tokenKey: string) => {
        const tokenValue: string | Array<any> = (tokens as any)[tokenKey]
        // split string with color patterns with lookahead / lookbehind patterns
        const theme = tokenKey.startsWith('cds-cegal') ? 'cegal' : 'neutral'
        const bits = (('' + tokenValue) as string)
          .split(/(?<=rgba?\([^)]+\))/g)
          .map((x) => x.split(/(?=rgba?\([^)]+\))/g))
          .flat()
        const preview: any = []
        bits.forEach((bit) => {
          if (bit.startsWith('rgb')) {
            preview.push(
              <div
                style={{ backgroundColor: bit, width: '32px', height: '32px', border: '1px solid grey' }}
              />
            )
          }
          if (tokenKey.match('border-radius')) {
            preview.push(
              <div style={{ borderRadius: bit, width: '32px', height: '32px', border: '1px solid grey' }} />
            )
          }
          if (tokenKey.match('-size-')) {
            if (tokenKey.match('font-(heading|body)-size-')) {
              preview.push(
                <span
                  style={{
                    fontSize: bit,
                    fontFamily: `var(--cds-${theme}-font-body-family)`
                  }}
                >
                  Sample
                </span>
              )
            } else if (tokenKey.match('(cegal|neutral)-size-')) {
              preview.push(
                <div
                  style={{
                    backgroundColor: 'grey',
                    width: bit,
                    height: bit,
                    fontFamily: `var(--cds-${theme}-font-body-family)`
                  }}
                />
              )
            } else {
              preview.push(
                <FlexCenterDiv>
                  <div style={{ backgroundColor: 'grey', width: '6px', height: '32px' }} />
                  <HorizontalSpace size={bit.replace('rem', '')} />
                  <div style={{ backgroundColor: 'grey', width: '6px', height: '32px' }} />
                </FlexCenterDiv>
              )
            }
          }

          if (tokenKey.match('font-(heading|body)-weight-')) {
            preview.push(<span style={{ fontWeight: bit }}>Sample</span>)
          }
          if (tokenKey.match('font-(heading|body)-line-height-')) {
            preview.push(<span style={{ lineHeight: bit, backgroundColor: 'grey' }}>Sample</span>)
          }
          if (tokenKey.match('font-(heading|body)-family')) {
            preview.push(<span style={{ fontFamily: bit }}>Sample</span>)
          }
        })
        return (
          <Table.Row key={tokenKey}>
            <MyDataCell>{tokenKey}</MyDataCell>
            <MyDataCell>
              <FlexCenterDiv>{bits.join(' ')}</FlexCenterDiv>
            </MyDataCell>
            {showPreview && (
              <Table.DataCell>
                <FlexCenterDiv>{preview}</FlexCenterDiv>
              </Table.DataCell>
            )}
          </Table.Row>
        )
      })
  }

  return (
    <>
      <VerticalSpace />
      <Heading size='large'>Colours and Themes</Heading>
      <VerticalSpace size='2' />

      <a href='#themes' className='cds-sr-only'>
        Usage
      </a>
      <Heading className='toc' size='medium' level='2' id='themes' spacing>
        Themes
      </Heading>
      <BodyLong spacing>
        Cegal design system supports two themes, <strong>Cegal</strong> and <strong>Neutral</strong>, each
        which two modes, <strong>light</strong> and <strong>dark</strong>.
      </BodyLong>

      <ul>
        <li>
          <BodyLong spacing>
            <strong>Cegal</strong> theme implements Cegal identity brand colors, fonts and styles. This theme
            is meant to be used on internal Cegal applications.
          </BodyLong>
        </li>
        <li>
          <BodyLong spacing>
            <strong>Neutral</strong> theme is inspired on the{' '}
            <Link href='http://primer.style'>Primer style</Link>. It is a generic theme intended to be used on
            client applications that you don't want to decorate with Cegal styles. The neutral theme is highly
            compatible and readable in desktop and mobile devices.
          </BodyLong>
        </li>
        <li>
          <BodyLong spacing>
            <strong>Light</strong> modes are meant to be used by default. The <strong>Cegal light</strong>{' '}
            theme is the default theme of the design system.
          </BodyLong>
        </li>
        <li>
          <BodyLong spacing>
            <strong>Dark</strong> modes are meant to be a user preference, for more comfortable read in low
            ambient light environments.
          </BodyLong>
        </li>
      </ul>

      <Alert variant='info'>
        Dark themes are not a high contrast alternative theme to address accessibility issues. Accessibility
        concerns should be addressed in all themes and modes.
      </Alert>

      <VerticalSpace size='2' />
      <a href='#setting' className='cds-sr-only'>
        Setting up a theme
      </a>
      <Heading className='toc' size='medium' level='2' id='setting' spacing>
        Setting up a theme
      </Heading>

      <VerticalSpace />

      <BodyLong spacing>
        You add a theme by setting a <code>data-theme</code> attribute to your application's DOM element that
        will wrap the content you want to be themed.
      </BodyLong>

      <BodyLong spacing>
        You can add as many <code>data-theme</code> in other DOM elements as you wish. That sets different{' '}
        <strong>theme scopes</strong> and overrides parent node's theme scope, as follows:
      </BodyLong>

      <DemoableDiv
        code={`<Container>
    <Panel>
      This container has no <code>data-theme</code> attribute, so it
      inherits closest parent's <code>data-theme</code> theme
      <VerticalSpace />
      <TextField label='I inherit parent theme' />
    </Panel>
  </Container>
  <VerticalSpace />
  <Container data-theme='cegal-light'>
  <Panel>
    This container has <code>data-theme='cegal-light'</code>.
    It renders itself plus its childrens 
    with <code>cegal-light</code> theme
    <VerticalSpace />
    <TextField label='I render always with a cegal-light theme' />
  </Panel>
  </Container>
  <Container data-theme='cegal-dark'>
    <Panel>
      This container has <code>data-theme='cegal-dark'</code>.
      It renders itself plus its childrens 
      with <code>cegal-dark</code> theme
    <VerticalSpace />
    <TextField label='I render always with a cegal-dark theme' />
    </Panel>
  </Container>`}
      >
        <Container>
          <Panel>
            This container has no <code>data-theme</code> attribute, so it inherits closest parent's{' '}
            <code>data-theme</code> theme
            <VerticalSpace />
            <TextField label='I inherit parent theme' />
          </Panel>
        </Container>
        <VerticalSpace />
        <Container data-theme='cegal-light'>
          <Panel>
            This container has <code>data-theme='cegal-light'</code>. It renders itself plus its childrens
            with <code>cegal-light</code> theme
            <VerticalSpace />
            <TextField label='I render always with a cegal-light theme' />
          </Panel>
        </Container>
        <Container data-theme='cegal-dark'>
          <Panel>
            This container has <code>data-theme='cegal-dark'</code>. It renders itself plus its childrens with{' '}
            <code>cegal-dark</code> theme
            <VerticalSpace />
            <TextField label='I render always with a cegal-dark theme' />
          </Panel>
        </Container>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <a href='#colours' className='cds-sr-only'>
        Colours
      </a>
      <Heading className='toc' size='medium' level='2' id='colours' spacing>
        Colours
      </Heading>

      <BodyLong>These are the tokens, taken from the @cegal/ds-css distribution files.</BodyLong>

      <VerticalSpace size='2' />
      <a href='#border' className='cds-sr-only'>
        Border radius
      </a>
      <Heading className='toc' size='xsmall' id='border' level='3' spacing>
        Border radius
      </Heading>
      {renderTable(tokens, 'cds-border-radius')}

      <VerticalSpace size='2' />
      <a href='#zindex' className='cds-sr-only'>
        Z-index
      </a>
      <Heading className='toc' size='xsmall' id='zindex' level='3' spacing>
        Z-index
      </Heading>
      {renderTable(tokens, 'cds-z-index', false)}

      <VerticalSpace size='2' />
      <a href='#size' className='cds-sr-only'>
        Size
      </a>
      <Heading className='toc' size='xsmall' id='size' level='3' spacing>
        Size
      </Heading>
      {renderTable(tokens, 'cds-size')}

      <VerticalSpace size='2' />
      <a href='#colors2' className='cds-sr-only'>
        Colors
      </a>
      <Heading className='toc' size='xsmall' id='colors2' level='3' spacing>
        Colors
      </Heading>
      {renderTable(tokens, 'cds-color')}

      <VerticalSpace size='3' />
      <a href='#cegaltheme' className='cds-sr-only'>
        cegal theme
      </a>
      <Heading className='toc' size='medium' id='cegaltheme' level='2' spacing>
        Cegal theme
      </Heading>

      <VerticalSpace size='2' />
      <a href='#cegalthemefont' className='cds-sr-only'>
        font
      </a>
      <Heading className='toc' size='xsmall' id='cegalthemefont' level='3' spacing>
        Font
      </Heading>
      {renderTable(tokens, 'cds-cegal-font')}

      <VerticalSpace size='2' />
      <a href='#cegallight' className='cds-sr-only'>
        Colors, dark mode
      </a>
      <Heading className='toc' size='small' id='cegallight' level='3' spacing>
        Colors, light mode
      </Heading>
      {renderTable(tokens, 'cds-cegal-light-')}

      <VerticalSpace size='2' />
      <a href='#cegaldark' className='cds-sr-only'>
        Colors, dark mode
      </a>
      <Heading className='toc' size='small' id='cegaldark' level='3' spacing>
        Colors, dark mode
      </Heading>
      {renderTable(tokens, 'cds-cegal-dark')}

      <VerticalSpace size='3' />
      <a href='#neutraltheme' className='cds-sr-only'>
        Neutral theme
      </a>
      <Heading className='toc' size='medium' id='neutraltheme' level='2' spacing>
        Neutral theme
      </Heading>

      <VerticalSpace size='2' />
      <a href='#neutralthemeFont' className='cds-sr-only'>
        Font
      </a>
      <Heading className='toc' size='small' id='neutralthemeFont' level='3' spacing>
        Font
      </Heading>
      {renderTable(tokens, 'cds-neutral-font')}

      <VerticalSpace size='2' />
      <a href='#neutralthemelight' className='cds-sr-only'>
        Neutral theme
      </a>
      <Heading className='toc' size='small' id='neutralthemelight' level='3' spacing>
        Colors, light mode
      </Heading>
      {renderTable(tokens, 'cds-neutral-light')}

      <VerticalSpace size='2' />
      <a href='#neutralthemedark' className='cds-sr-only'>
        Neutral theme
      </a>
      <Heading className='toc' size='small' id='neutralthemedark' level='3' spacing>
        Colors, dark mode
      </Heading>
      {renderTable(tokens, 'cds-neutral-dark')}
    </>
  )
}

export default ResourcesColours
