import React from 'react'
import { Link } from '@cegal/ds-components'
import styled from 'styled-components'

const Ul = styled.ul`
  list-style-type: none;
  padding: 0.5rem;
  @media (max-width: 768px) {
    display: none;
  }
`
const Li = styled.li`
  margin: 0;
`

const ToC = ({ headings, activeId }: any) => {
  const scroll = (id: string) => {
    const e = document.getElementById(id)
    if (e) {
      window.scrollTo({
        top: e.offsetTop - 160, // offset from header
        left: e.offsetLeft,
        behavior: 'smooth'
      })
    }
  }

  if (headings?.length === 0) {
    return null
  }

  return (
    <Ul>
      {headings.map((heading: any) => (
        <Li key={heading.id} style={{ marginLeft: `${heading.level - 1}em` }}>
          <Link
            href='#'
            onClick={(e: any) => {
              e.preventDefault()
              e.stopPropagation()
              scroll(heading.id)
            }}
            //            href={`#${heading.id}`}
            style={{ textDecoration: 'none' }}
          >
            {heading.text}
          </Link>
        </Li>
      ))}
    </Ul>
  )
}

export default ToC
