import { Heading, VerticalSpace, Table, BodyLong, Tabs } from '@cegal/ds-components'
import { Email, MarkEmailRead, MarkEmailUnread } from '@cegal/ds-icons'
import CodeDiv from 'components/CodeDiv/CodeDiv'
import DemoableDiv from 'components/DemoableDiv/DemoableDiv'
import { TableWrapperDiv } from 'components/styled'
import React, { useState } from 'react'

const ComponentsTabs = () => {
  const [tabValue, setTabValue] = useState<string>('email')
  return (
    <>
      <VerticalSpace />
      <Heading size='large' id='Tabs' level='1' spacing>
        Tabs
      </Heading>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Tabs-default' level='2' spacing>
        Default
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        If you are not controlling the tabs, at least pass a <code>defaultValue</code> prop, otherwise no
        panel will be rendered.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { Tabs } from '@cegal/ds-components'

const Component = () => {
  return (
    <Tabs defaultValue='email'>
      <Tabs.List>
        <Tabs.Tab value='email' label='Emails' />
        <Tabs.Tab value='read' label='Read' />
        <Tabs.Tab value='unread' label='Unread' />
      </Tabs.List>
      <Tabs.Panel value='email'>
        Panel for Emails
      </Tabs.Panel>
      <Tabs.Panel value='read'>
        Panel for Read emails
      </Tabs.Panel>
      <Tabs.Panel value='unread'>
        Panel for Unread emails
      </Tabs.Panel>
    </Tabs>
  )
}`}
      >
        <Tabs defaultValue='email'>
          <Tabs.List>
            <Tabs.Tab value='email' label='Emails' />
            <Tabs.Tab value='read' label='Read' />
            <Tabs.Tab value='unread' label='Unread' />
          </Tabs.List>
          <Tabs.Panel value='email'>Panel for Emails</Tabs.Panel>
          <Tabs.Panel value='read'>Panel for Read emails</Tabs.Panel>
          <Tabs.Panel value='unread'>Panel for Unread emails</Tabs.Panel>
        </Tabs>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Tabs-controlled' level='2' spacing>
        Controlled
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        Control the tab values with <code>onChange</code> and <code>value</code> props
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Tabs } from '@cegal/ds-components'

const [tabValue, setTabValue] = useState<string>('email') 
const Component = () => {
  return (
    <Tabs 
      value={tabValue}
      onChange={setValue} 
    >
      <Tabs.List>
        <Tabs.Tab value='email' label='Emails' />
        <Tabs.Tab value='read' label='Read' />
        <Tabs.Tab value='unread' label='Unread' />
      </Tabs.List>
      <Tabs.Panel value='email'>
          Panel for Emails
      </Tabs.Panel>
      <Tabs.Panel value='read'>
          Panel for Read emails
      </Tabs.Panel>
      <Tabs.Panel value='unread'>
          Panel for Unread emails
      </Tabs.Panel>
    </Tabs>
  )
}`}
      >
        <Tabs value={tabValue} onChange={setTabValue}>
          <Tabs.List>
            <Tabs.Tab value='email' label='Emails' />
            <Tabs.Tab value='read' label='Read' />
            <Tabs.Tab value='unread' label='Unread' />
          </Tabs.List>
          <Tabs.Panel value='email'>Panel for Emails</Tabs.Panel>
          <Tabs.Panel value='read'>Panel for Read emails</Tabs.Panel>
          <Tabs.Panel value='unread'>Panel for Unread emails</Tabs.Panel>
        </Tabs>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Tabs-size' level='2' spacing>
        Size
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>The size prop can be one of the following 2 strings:</BodyLong>

      <CodeDiv expand={false} spacing>
        export type FormFieldSize = 'medium' | 'small'
      </CodeDiv>

      <BodyLong spacing>
        You can use the exported type <code>FormFieldSize</code> from <code>@cegal/ds-components</code>
      </BodyLong>

      <DemoableDiv
        alignContent='center'
        code={`import { Tabs } from '@cegal/ds-components'

const [size, setSize] = useState<string>('email') 
const Component = () => {
  return (
    <>
    <Tabs>
       <Tabs.List>
        <Tabs.Tab value='email' label='Emails'/>
        <Tabs.Tab value='read' label='Read'/>
        <Tabs.Tab value='unread' label='Unread'/>
      </Tabs.List>
      <Tabs.Panel value='email'>
          Panel for Emails
      </Tabs.Panel>
      <Tabs.Panel value='read'>
          Panel for Read emails
      </Tabs.Panel>
      <Tabs.Panel value='unread'>
          Panel for Unread emails
      </Tabs.Panel>
    </Tabs>
    <Tabs size='small'>
       <Tabs.List>
        <Tabs.Tab value='email' label='Emails'/>
        <Tabs.Tab value='read' label='Read'/>
        <Tabs.Tab value='unread' label='Unread'/>
      </Tabs.List>
      <Tabs.Panel value='email'>
          Panel for Emails
      </Tabs.Panel>
      <Tabs.Panel value='read'>
          Panel for Read emails
      </Tabs.Panel>
      <Tabs.Panel value='unread'>
          Panel for Unread emails
      </Tabs.Panel>
    </Tabs>
    </>
  )
}`}
      >
        <Tabs>
          <Tabs.List>
            <Tabs.Tab value='email' label='Emails' />
            <Tabs.Tab value='read' label='Read' />
            <Tabs.Tab value='unread' label='Unread' />
          </Tabs.List>
          <Tabs.Panel value='email'>Panel for Emails</Tabs.Panel>
          <Tabs.Panel value='read'>Panel for Read emails</Tabs.Panel>
          <Tabs.Panel value='unread'>Panel for Unread emails</Tabs.Panel>
        </Tabs>
        <Tabs size='small'>
          <Tabs.List>
            <Tabs.Tab value='email' label='Emails' />
            <Tabs.Tab value='read' label='Read' />
            <Tabs.Tab value='unread' label='Unread' />
          </Tabs.List>
          <Tabs.Panel value='email'>Panel for Emails</Tabs.Panel>
          <Tabs.Panel value='read'>Panel for Read emails</Tabs.Panel>
          <Tabs.Panel value='unread'>Panel for Unread emails</Tabs.Panel>
        </Tabs>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Tabs-icons' level='2' spacing>
        Using icons
      </Heading>
      <VerticalSpace size='2' />

      <DemoableDiv
        alignContent='center'
        code={`import { Tabs } from '@cegal/ds-components'

const Component = () => {
  return (
    <Tabs defaultValue='email'>
      <Tabs.List>
        <Tabs.Tab
          value='email'
          iconPosition='left'
          label='Emails'
          icon={<Email size='1.5rem' title='Emails' />}
        />
        <Tabs.Tab
          value='read'
          iconPosition='left'
          label='Read'
          icon={<MarkEmailRead size='1.5rem' title='Read emails' />}
        />
        <Tabs.Tab
          value='unread'
          iconPosition='left'
          label='Unread'
          icon={<MarkEmailUnread size='1.5rem' title='Unread emails' />}
        />
      </Tabs.List>
      <Tabs.Panel value='email'>
          Panel for Emails
      </Tabs.Panel>
      <Tabs.Panel value='read'>
          Panel for Read emails
      </Tabs.Panel>
      <Tabs.Panel value='unread'>
          Panel for Unread emails
      </Tabs.Panel>
    </Tabs>
  )
}`}
      >
        <Tabs defaultValue='email'>
          <Tabs.List>
            <Tabs.Tab
              value='email'
              iconPosition='left'
              label='Emails'
              icon={<Email size='1.5rem' title='Emails' />}
            />
            <Tabs.Tab
              value='read'
              iconPosition='left'
              label='Read'
              icon={<MarkEmailRead size='1.5rem' title='Read emails' />}
            />
            <Tabs.Tab
              value='unread'
              iconPosition='left'
              label='Unread'
              icon={<MarkEmailUnread size='1.5rem' title='Unread emails' />}
            />
          </Tabs.List>
          <Tabs.Panel value='email'>Panel for Emails</Tabs.Panel>
          <Tabs.Panel value='read'>Panel for Read emails</Tabs.Panel>
          <Tabs.Panel value='unread'>Panel for Unread emails</Tabs.Panel>
        </Tabs>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Tabs-selectionFollowsFocus' level='2' spacing>
        Selection follows focus
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        Set <code>defaultValue</code> prop if you want the selection to follow focus, or in other words, if
        you change active tab with keyboard arrow keys, the panels also change accordingly.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { Tabs } from '@cegal/ds-components'

const Component = () => {
  return (
    <Tabs defaultValue='email' selectionFollowsFocus>
      <Tabs.List>
        <Tabs.Tab value='email' label='Emails' />
        <Tabs.Tab value='read' label='Read' />
        <Tabs.Tab value='unread' label='Unread' />
      </Tabs.List>
      <Tabs.Panel value='email'>
        Panel for Emails
      </Tabs.Panel>
      <Tabs.Panel value='read'>
        Panel for Read emails
      </Tabs.Panel>
      <Tabs.Panel value='unread'>
        Panel for Unread emails
      </Tabs.Panel>
    </Tabs>
  )
}`}
      >
        <Tabs defaultValue='email' selectionFollowsFocus>
          <Tabs.List>
            <Tabs.Tab value='email' label='Emails' />
            <Tabs.Tab value='read' label='Read' />
            <Tabs.Tab value='unread' label='Unread' />
          </Tabs.List>
          <Tabs.Panel value='email'>Panel for Emails</Tabs.Panel>
          <Tabs.Panel value='read'>Panel for Read emails</Tabs.Panel>
          <Tabs.Panel value='unread'>Panel for Unread emails</Tabs.Panel>
        </Tabs>
      </DemoableDiv>

      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='Tabs-loop' level='2' spacing>
        Loop
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        Set <code>loop</code> prop to false if you want the tabs to not loop around.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { Tabs } from '@cegal/ds-components'

const Component = () => {
  return (
    <Tabs defaultValue='email'>
      <Tabs.List loop={false}>
        <Tabs.Tab value='email' label='Emails' />
        <Tabs.Tab value='read' label='Read' />
        <Tabs.Tab value='unread' label='Unread' />
      </Tabs.List>
      <Tabs.Panel value='email'>
        Panel for Emails
      </Tabs.Panel>
      <Tabs.Panel value='read'>
        Panel for Read emails
      </Tabs.Panel>
      <Tabs.Panel value='unread'>
        Panel for Unread emails
      </Tabs.Panel>
    </Tabs>
  )
}`}
      >
        <Tabs defaultValue='email'>
          <Tabs.List loop={false}>
            <Tabs.Tab value='email' label='Emails' />
            <Tabs.Tab value='read' label='Read' />
            <Tabs.Tab value='unread' label='Unread' />
          </Tabs.List>
          <Tabs.Panel value='email'>Panel for Emails</Tabs.Panel>
          <Tabs.Panel value='read'>Panel for Read emails</Tabs.Panel>
          <Tabs.Panel value='unread'>Panel for Unread emails</Tabs.Panel>
        </Tabs>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='Tabs-border' level='2' spacing>
        Border
      </Heading>
      <VerticalSpace size='2' />

      <BodyLong spacing>
        Set <code>border</code> prop to true if you want borders to wrap the active tab and panel.
      </BodyLong>
      <DemoableDiv
        alignContent='center'
        code={`import { Tabs } from '@cegal/ds-components'

const Component = () => {
  return (
    <Tabs border defaultValue='email'>
      <Tabs.List loop={false}>
        <Tabs.Tab value='email' label='Emails' />
        <Tabs.Tab value='read' label='Read' />
        <Tabs.Tab value='unread' label='Unread' />
      </Tabs.List>
      <Tabs.Panel value='email'>
        Panel for Emails
      </Tabs.Panel>
      <Tabs.Panel value='read'>
        Panel for Read emails
      </Tabs.Panel>
      <Tabs.Panel value='unread'>
        Panel for Unread emails
      </Tabs.Panel>
    </Tabs>
  )
}`}
      >
        <Tabs border defaultValue='email'>
          <Tabs.List loop={false}>
            <Tabs.Tab value='email' label='Emails' />
            <Tabs.Tab value='read' label='Read' />
            <Tabs.Tab value='unread' label='Unread' />
          </Tabs.List>
          <Tabs.Panel value='email'>Panel for Emails</Tabs.Panel>
          <Tabs.Panel value='read'>Panel for Read emails</Tabs.Panel>
          <Tabs.Panel value='unread'>Panel for Unread emails</Tabs.Panel>
        </Tabs>
      </DemoableDiv>

      <VerticalSpace size='2' />

      <Heading className='toc' size='large' id='properties' level='2' spacing>
        Props table
      </Heading>
      <VerticalSpace size='2' />

      <Heading className='toc' size='medium' id='properties-tabs' level='2' spacing>
        Tabs
      </Heading>
      <VerticalSpace size='2' />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>border</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Adds tab border</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>size</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>'medium' | 'small'</code>
              </Table.DataCell>
              <Table.DataCell>Sets tabs size</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>medium</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets tab value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>defaultValue</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets default tab value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>onChange</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>(value: string) =&gt; void</code>
              </Table.DataCell>
              <Table.DataCell>Callback when tabs change</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>selectionFollowsFocus</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Set if changing tab focus also selection</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='2' />
      <Heading className='toc' size='medium' id='properties-tabs-list' level='2' spacing>
        Tabs.List
      </Heading>
      <VerticalSpace size='2' />
      <TableWrapperDiv>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>loop</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Loops back to start when navigating past last item</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>

        <VerticalSpace size='2' />
        <Heading className='toc' size='medium' id='properties-tabs-panel' level='2' spacing>
          Tabs.Panel
        </Heading>
        <VerticalSpace size='2' />

        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Type</Table.ColumnHeader>
              <Table.ColumnHeader>Description</Table.ColumnHeader>
              <Table.ColumnHeader>Required</Table.ColumnHeader>
              <Table.ColumnHeader>Default</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.DataCell>
                <code>value</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>string</code>
              </Table.DataCell>
              <Table.DataCell>Sets tab panel value</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>-</code>
              </Table.DataCell>
            </Table.Row>
            <Table.Row>
              <Table.DataCell>
                <code>noPadding</code>
              </Table.DataCell>
              <Table.DataCell>
                <code>boolean</code>
              </Table.DataCell>
              <Table.DataCell>Removes padding from tab panel</Table.DataCell>
              <Table.DataCell>No</Table.DataCell>
              <Table.DataCell>
                <code>false</code>
              </Table.DataCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </TableWrapperDiv>
      <VerticalSpace size='3' />
    </>
  )
}

export default ComponentsTabs
